import styled from 'styled-components';

export const Box = styled.div`  
   div{
    color: #004c94;
    font-size: 14px;   
    margin: 20px;
    font-weight: bold;
    i{      
      font-size: 17px;
      margin-right: 10px;
      vertical-align: bottom;
      cursor: pointer;
    }
  }
`;

export const Share = styled.div`

`;
