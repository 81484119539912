import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { Col } from 'reactstrap';

export const DeleteButton = styled.div`
  text-align: end;
  cursor: pointer;
  color: #2d2c2c;
  font-size: 1.25em;
`;

export const Post = styled.article`  
  padding: 25px 25px 0 25px; 
  border-top-left-radius: 5px;
  border-top-right-radius: 5px; 
  background: #fff;
`;

export const PostFooter = styled.article`   
  margin-bottom: 30px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background: #fff;
  border-top: 1px solid #bfbfbf;
`;

export const Line = styled.div`
  border-top: 1px solid #bfbfbf;
`;

export const CommentBox = styled.div`
  padding-top: 25px;
  color: #004c94;
  font-size: 15px;
  display: inline-block;
  font-weight: bold;

  i{
    font-size: 24px;
    margin-right: 10px;
    vertical-align: bottom;
    cursor: pointer;
  }
`;

export const LikeBox = styled.div`
  padding-top: 25px;
`;

export const CommentsBox = styled.div`
  padding: 25px;
  border-top: 1px solid #bfbfbf;
`;

export const Column = styled(Col)`
  cursor: pointer;
  &:last-child{
    border-left:1px solid #bfbfbf;
  }
`;

export const IconClose = styled.i`  
  color: #004c94;
  cursor: pointer;
`;

export const AuthorInfo = styled.header`
  display: grid;
  grid-template-columns: 0.8fr 6fr 3.2fr 2fr;
  grid-template-areas: "AuthorAvatar AuthorInfo empty AuthorFollow";
  grid-gap: 10px;
  align-items: center;
  padding-bottom: 10px;    
  border-bottom: 1px solid #bfbfbf;
  margin-bottom: 30px;
`;

export const AuthorInfoShared = styled.header`
  display: grid;
  grid-template-columns: 0.8fr 6fr 3.2fr 2fr;
  grid-template-areas: "AuthorAvatar AuthorInfo empty AuthorFollow";
  grid-gap: 10px;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #bfbfbf;
  margin-bottom: 30px;
`;

export const AvatarLink = styled(Link)`grid-area: AuthorAvatar;`;

export const Avatar = styled.img`
  width: 50px;
  border-radius: 50%;
  height: 50px;
  object-fit: cover;
`;

export const Info = styled.div`
  grid-area: AuthorInfo; 
`;

export const AuthorTitle = styled(Link)`
  &,
  &:hover {
    color: #2d2c2c;
  }
  font-size: 15px;
  font-weight: bold;
`;

export const AuthorOffice = styled.div`
  color: #777676;
  font-size: 12px;
`;

export const Date = styled.time`
  font-size: 12px;
  color: #bab8b8;
`;

export const ButtonFollowContainer = styled.div`
  width: 76%;
  margin-left: 92%;
`;

// export const Tags = styled.div`margin-bottom: 30px;`;

// export const Tag = styled(Link)`
//   display: inline-block;
//   line-height: 25px;
//   background-color: #f19800;
//   color: #ffffff;
//   font-size: 13px;
//   padding: 0 15px;
//   margin-right: 20px;
//   border-radius: 5px;
//   transition: all 300ms linear;

//   &:hover {
//     text-decoration: none;
//     color: #313131;
//     background-color: #ffef00;
//   }
//   &:last-child {
//     margin-right: 0;
//   }
// `;

export const Comment = styled.div`
  a{   
    &,
    &:hover{
      color: #2d2c2c;
      font-weight: bold;
    }    
  } 
`;

export const ImageAdjust = styled.div`
  position: relative;
  margin: 0 -26px;
  overflow: hidden;   
`;

export const Image = styled.div`
  text-align: center;
  
  max-width: 100%;
  width: 100%;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: auto max-content;

`;

const pulse = keyframes`
  0%{
    transform: scale(1);
  }
  25%{
    transform: scale(1);
  }
  30%{
    transform: scale(1.4);
  }
  50%{
    transform: scale(1.2);
  }
  70%{
    transform: scale(1.4);
  }
  90%{
    transform: scale(1);
  }
  100%{
    transform: scale(1);
  }
`;

export const Like = styled.div`
  &.active i {
    animation: 1s ${pulse} linear;
  }
`;

export const Tag = styled.div`
background: #f19800;
width: fit-content;
padding: 5px;
color: #fff;
font-size: 13px;
border-radius: 5px;
margin-bottom: 30px;
`;
