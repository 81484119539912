/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  Modal,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { store } from 'react-notifications-component';
import {
  Placeholder,
  Buttons,
  Image,
  Section,
  TagPeople,
  TextArea,
  ButtonMedia,
  Icon,
} from './styles';
import {
  getCurrentUser, loadAvatar,
} from '../../../services/endpoints';
import NoAvatar from '../../../assets/images/no-avatar.png';
import settings from '../../../services/settings';
import WritePost from '../WritePost';


const WritePostToggle = (props) => {
  settings.notifications.dismiss.duration = 5000;
  const { t } = useTranslation('timeline');
  const user = getCurrentUser();
  const [avatar, setAvatar] = useState([]);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);


  function handleAvatar() {
    loadAvatar(user.id).then((response) => {
      setAvatar(response.data);
    }).catch((error) => {
      store.addNotification({
        ...settings.notifications,
        message: t('notifications.danger.insert_image', error),
        type: 'danger',
      });
    });
  }

  useEffect(() => {
    handleAvatar();
  }, []);

  return (
    <Section>
      {getCurrentUser() && (
        <>
          <div>
            <Modal
              isOpen={modal}
              fade={false}
              toggle={toggle}
              style={{
                margin: '5% 0 0 29%',
                maxWidth: '840px',
              }}
            >
              <Icon className="far fa-times-circle" onClick={toggle} />
              <WritePost handleRefreshPosts={props.handleRefreshPosts} toggle={toggle} />
            </Modal>
          </div>

            {avatar[0] ? (
              <Image src={avatar[0].file.urlPublica} />
            ) : (
              <Image src={NoAvatar} />
            )}

          <TextArea onClick={toggle}>

            <Placeholder>
              <i className="far fa-edit" />
              {t('write_comments.create_a_post_now')}
            </Placeholder>

          </TextArea>

          <Buttons>
            <ButtonMedia>
              <i className="far fa-images" />
              {t('write_comments.media')}
            </ButtonMedia>

            <TagPeople>
              <i className="far fa-user-plus" />
              {t('write_comments.tag_peoples')}
            </TagPeople>
          </Buttons>
        </>
      )}
    </Section>
  );
};

WritePostToggle.propTypes = {
  handleRefreshPosts: PropTypes.func.isRequired,
};

export default WritePostToggle;
