const settings = {
  notifications: {
    title: '',
    insert: 'top',
    container: 'top-right',
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    dismiss: {
      duration: (1000 * 60),
      onScreen: true,
    },
  },
};
export default settings;
