import React from 'react';
import PropTypes from 'prop-types';
import {
  Arrow, LinkStyled, Url, Breadcrumb as BreadcrumbStyled, CardBreadcrumb,
} from './styles';

const Breadcrumb = ({ root, className, ...props }) => {
  const renderBreadcrumb = () => {
    const breadcrumb = [];

    root.forEach((item, index) => {
      if (item.url) {
        breadcrumb.push(
          <BreadcrumbStyled key={index}>
            <LinkStyled to={item.url}>{item.title }</LinkStyled>
            <Arrow className="far fa-angle-right" />
          </BreadcrumbStyled>,
        );
      } else {
        breadcrumb.push(
          <BreadcrumbStyled key={index}>
            <Url key={index}>{item.title}</Url>
          </BreadcrumbStyled>
          ,
        );
      }
    });

    return breadcrumb;
  };

  return (<CardBreadcrumb className="card-breadcrumb">{renderBreadcrumb()}</CardBreadcrumb>);
};

Breadcrumb.propTypes = {
  root: PropTypes.array.isRequired,
  className: PropTypes.string,
};

export default Breadcrumb;
