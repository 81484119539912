import styled from 'styled-components';
import { Link as LinkRouter } from 'react-router-dom';

export const Course = styled.section`
  position: relative;                         
  margin-bottom: 10px;
  margin-right: 10px;
  padding: 10px 0px 0 0;
  border-top: 1px solid #d3d3d3;
  &:last-child{
    border-bottom: 1px solid #d3d3d3;
  }
`;

export const Grid = styled.div`  
  display: grid;
  grid-template-columns: 1.5fr 2fr;
  grid-column-gap: 10px;
  grid-template-areas: "image""title";  
  margin-bottom: 10px;                    
 `;

export const Image = styled.img`
  max-width: 100%;
  width: 100%;
  height: 60px;
  object-fit: cover;
  border-radius: 5px;
  grid-area: "image"
`;

export const MetaInfo = styled.div`
  border: 1px solid #fff;
  background: #fff;
  border-top: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;

export const Icon = styled.span`
  color: #004c94;
  font-size: 13px;
  margin-right: 15px;
  display: inline-block;
  font-family: 'Roboto', sans-serif !important;
  font-weight: normal !important;
  &.fas:before{
    font-weight: bold !important;
  }
  &:before{
    font-family: "Font Awesome 5 Pro";
    margin-right: 5px;
    font-size: 15px;
  }  
`;

export const Title = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 1.4;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  margin-bottom: 15px;  
  grid-area: "title";
`;

export const Link = styled(LinkRouter)`
  display: block;
`;
