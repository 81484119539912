/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { IconSearch, InputSearch as Input } from './styles';

const Search = ({
  name = 'search',
  placeholder = '',
  handleGetSearch,
  handleKeyUp,
  onClick,
  search,
  ...props
}) => {
  const handleChange = (event) => {
    handleGetSearch(event.target.value);
  };

  return (
    <div className="form-search">
      <Input
        className="input-search"
        name={name}
        {...props}
        placeholder={placeholder}
        onKeyUp={handleKeyUp}
        value={search}
        onChange={handleChange}
      />

      <IconSearch type="submit" onClick={onClick} className="far fa-search" />

    </div>
  );
};


Search.propTypes = {
  name: PropTypes.string,
  handleKeyUp: PropTypes.func,
  handleGetSearch: PropTypes.func,
  placeholder: PropTypes.string,
  search: PropTypes.string,
  onClick: PropTypes.func,
};

export default Search;
