import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const CardBreadcrumb = styled.div` 
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
`;

export const Breadcrumb = styled.span`  
  font-style: italic;
  color: #a8abb0;
  font-size: 14px;
`;

export const Arrow = styled.i`  
  margin-left: 5px;   
`;

export const Url = styled.span`   
  margin-left: 5px;  
`;

export const LinkStyled = styled(Link)`   
color: #a8abb0;
  &:hover{
    color: #a8abb0;
    text-decoration: none;
  }
`;
