import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Input } from 'reactstrap';


export const Header = styled.header`
  background: #004c94;
  color: #ffffff;
  margin-bottom: 45px;  
`;

export const Image = styled.img`
  width: 30px;
  height: 30px;
  object-fit: cover;
  margin-top: 5px;
  border-radius: 50%;
  margin-bottom: -15px;
`;

export const Text = styled.div`
  color: #eeeeee;
  font-size: 15px;
  cursor: pointer;
`;

export const LiveText = styled.span`
  color: #eeeeee;
  font-size: 15px;
  cursor: pointer;
  text-transform: uppercase;
  margin-left: 5px;
`;

export const Logo = styled.img`
  max-width: 140%;
  height: 35px;
`;

export const InputSearch = styled(Input)`
  cursor: pointer;  
`;

export const Nav = styled.nav`
  // display: flex;
  // justify-content: space-between;
  display: table;
  width: 100%;
`;

export const NavItem = styled.li`
  display: table-cell;
  width: 16.666%;
  white-space: nowrap;
  text-align: center;
  line-height: 35px;
  &:not(:last-child) {
    border-right: 1px solid rgb(12, 89, 159);
    border-left: 1px solid rgb(12, 89, 159);
  }
`;


export const LiveIcon = styled.i`
  font-size: 16px;
  vertical-align: bottom;
  cursor: pointer;
  color: #f00701;
  padding: 4px;
`;

export const LiveActive = styled.span`

`;

export const LiveLink = styled(NavLink)`
  text-decoration: none;  
   &.active{
    text-decoration: none;
    font-weight: bold;
    color: #eeeeee; 
    background-color: #f00701;
    border-radius: 3px;
    white-space: nowrap;
    padding: 5px; 
    ${LiveIcon} {
      color: #eeeeee;
    }     
  }
`;

export const MenuTitle = styled(NavLink)`
  color: #eeeeee;
  font-size: 15px;
  position: relative;
  display: block;
  &:before {
    content: attr(title);
    font-weight: bold;
    opacity: 0;
    display: block;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }  
  &:after {
    content: "";
    position: absolute;
    bottom: 0px;
    height: 3px;
    background: #fbe924;
    width: 0;
    left: 0;
    transition: width 300ms linear;
  }
  &.active,
  &:hover {
    text-decoration: none;
    font-weight: bold;
    color: #eeeeee;
    &:after {
      width: 100%;
    }
  }
`;

export const LiveStatus = styled.div`
  flex: 0 0 23%;
  max-width: 23%;
  font-size: 15px;
  line-height: 35px;
  font-weight: bold;
  position: relative;
  background-color: #ff0000;
  text-transform: uppercase;
  color: #ffffff;
  &:before {
    content: "";
    width: 12px;
    height: 12px;
    display: inline-block;
    background: #fff;
    margin: 0 7px;
    border-radius: 50%;
  }
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    top: 0;
    right: -10px;
    border-right: 10px solid transparent;
    border-top: 35px solid #ff0000;
  }
`;

export const Icon = styled.i`
  font-size: 20px;
  vertical-align: bottom;
  cursor: pointer;
`;

export const ImageIcon = styled.img`
  width: 23px;
  vertical-align: bottom;
`;
