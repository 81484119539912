import styled from 'styled-components';
import { NavLink, Link } from 'react-router-dom';


const widthButtonLive = 257.5;
const widthButtonLiveOffline = 70;

export const MenuLink = styled(NavLink)`
  color: #2d2c2c;
  font-size: 15px;
  text-transform: uppercase;
  line-height: 35px;
  position: relative;
  display: block;
  &:before {
    content: attr(title);
    font-weight: bold;
    opacity: 0;
    display: block;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }
  &:after {
    content: "";
    position: absolute;
    bottom: -11px;
    height: 2px;
    background: #004c94;
    width: 0;
    left: 0;
    transition: width 300ms linear;
  }
  &.active,
  &:hover{
    text-decoration: none;
    color: #004c94;
    font-weight: bold;
    &:after{
      width: 100%;
    }
  }
`;

export const LiveStatus = styled.div`
  flex: 0 0 23%;
  max-width: 23%;
  font-size: 15px;
  line-height: 35px;
  font-weight: bold;
  position: relative;
  background-color: #ff0000;
  text-transform: uppercase;
  color: #ffffff;   
  &:before {
    content: "";
    width: 12px;
    height: 12px;
    display: inline-block;
    background: #fff;
    margin: 0 7px;
    border-radius: 50%;
    
  }
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    top: 0;
    right: -10px;
    border-right: 10px solid transparent;
    border-top: 35px solid #ff0000;    
  }
`;

export const LiveTitle = styled.div`
  flex: 0 0 77%;
  max-width: 77%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 8px;
  font-style: italic;
  color: #ffffff;
  text-decoration: none;
  font-size: 14px;
`;

export const LiveMessage = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f00;
  line-height: 35px;
  padding: 0 15px;
  text-align: center;
  font-weight: bold;
  font-size: 15px;
  opacity: 0;
  transition: all 300ms linear;
  cursor: pointer;
  color: #ffffff;
  text-decoration: none;
`;

export const LiveButton = styled(Link)`
  width: ${widthButtonLive}px;
  white-space: nowrap;
  background-color: #004c94;
  color: #ffffff;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  overflow: hidden;
  text-decoration: none;
  border-radius: 5px; 
  &:hover ${LiveMessage}{
    opacity: 1;
    color: #ffffff;           
  }
  &:hover{
    text-decoration: none;
  }

 
`;

export const LiveButtonOffline = styled.div`
  color: #d4d4d4;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  overflow: hidden;
  border-radius: 5px;
  border: 1px solid #d4d4d4;
  margin-left: 35%;
  width: ${widthButtonLiveOffline}px;
`;

export const LiveStatusOffline = styled.div`
  text-transform: uppercase;
  font-size: 15px;
  line-height: 35px;
  font-weight: bold;
  position: relative;
  &:before {
    content: "";
    width: 12px;
    height: 12px;
    display: inline-block;
    background: #d4d4d4;
    margin: 0 7px;
    border-radius: 50%;
  }
`;
