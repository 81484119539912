import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Courses, Title, Card, LinkCustom, Button,
} from './styles';
import CourseItem from '../CourseItem';
import { loadCourses } from '../../../../services/endpoints';

const CoursesList = () => {
  const { t } = useTranslation('common');
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    loadCourses({
      active: true,
      'order[id]': 'desc',
    })
      .then((response) => {
        setCourses(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <Courses>
      <Title>{t('tabs.courses_for_you')}</Title>
      <Card>
        {courses.map((course, index) => (
          <CourseItem course={course} key={index} />
        ))}
      </Card>
      <LinkCustom to="/cursos">
        <Button>{t('buttons.see_all_courses')}</Button>
      </LinkCustom>
    </Courses>
  );
};

export default CoursesList;
