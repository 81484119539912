/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Follow = styled.button.attrs({
  className: 'btn btn-default',
})`
  height: fit-content !important;
  line-height: normal !important;
  margin-top: 15px !important;
  margin-left: 3px;
  margin-right: -9px;
`;
