import React from 'react';
import CardItem from '../CardItem';
import { Cards as CardsStyle } from './styles.js';
import api from '../../../services/api';

const Cards = ({ cards, getNotifications }) => {
  function handleViewed(id) {
    api.put(`/general/notifications/${id}`, { viewed: true }).then((response) => {
      getNotifications();
    });
  }


  return (
    <>
      {cards && (
        <CardsStyle>
          {cards.map((card) => (
            <div key={card.id} onClick={() => handleViewed(card.id)}>
              <CardItem card={card} />
            </div>
          ))}
        </CardsStyle>
      )}
    </>
  );
};

export default Cards;
