import styled from 'styled-components';

export const Footer = styled.footer`
  background: #004c94;
  padding: 20px 0;
  margin-top: 90px;
`;

export const Image = styled.img`
  width: 150px;
`;

export const Text = styled.div`
  text-align: right;
  color: #ffffff;
  font-size: 11px;
`;
