import styled from 'styled-components';

export const SearchBox = styled.div`
  flex: 0 0 50%;
  max-width: 50%;
  margin-left: 50%;
  margin-bottom: 50px;
`;

export const Filter = styled.div;
