import styled from 'styled-components';
import { Input } from 'reactstrap';

export const Select = styled(Input).attrs({ type: 'select', className: 'input-select' })`

`;

export const Button = styled.button.attrs({className: 'btn btn-default' })`

`;

export const Content = styled.div`
  /*max-height: 100vh;
  overflow-y: scroll;
  padding-right: 15px;
  
  &::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 10px;
  }

  &::-webkit-scrollbar {
    border-radius: 10px;
    width: 12px;
    background-color: #eee;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #004c94;
  }*/
`;
