/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import PropType from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Progress } from 'reactstrap';
import { loadSpecificCourse } from '../../../services/endpoints';
import {
  ProgressBar,
  ProgressText,
  ProgressGrid,
  Course,
  Icon,
  Image,
  Link,
  MetaInfo,
  Title,
  Button,
} from './styles';

const CoursesFilterItem = ({ course, breadcrumb }) => {
  const [courseItem, setCourseItem] = useState([]);
  // const courseItem = course.course;
  // const [_courseItem, _setCourseItem] = useState([]);
  const { t } = useTranslation('courses');
  const url = `/cursos/${courseItem.slug}`;
  const [materials, setMaterials] = useState([]);
  const sum = (accumulator, currentValue) => accumulator + currentValue;

  const handleCourses = () => {
    loadSpecificCourse(course.course.slug).then((response) => {
      setCourseItem(response.data[0]);
      handleSupportMaterials(response.data[0]);
    }).catch((error) => {
      console.error(error);
    });
  };

  const handleSupportMaterials = (c) => {
    if (c.lessons && c.lessons.length) {
      const m = [];
      c.lessons.map((item) => {
        if (item.lesson_support_materials.length > 0) {
          item.lesson_support_materials.map((materialsItem) => m.push(
            materialsItem.support_material.support_material_contents.length,
          ));
        } else {
          m.push(item.lesson_support_materials.length);
        }
        return null;
      });
      setMaterials(m.reduce(sum));
    }
  };

  useEffect(() => {
    handleCourses();

  }, [course]);

  return (
    <Course>
      {courseItem.image && (
        <Link to={url}>
          <Image src={courseItem.image.urlPublica} />
        </Link>
      )}
      <MetaInfo>
        {courseItem.lessons && (
          <>
            <Icon className="fas fa-play-circle">{courseItem.lessons.length }</Icon>
            <Icon className="far fa-file-pdf">
              {courseItem.lessons.length ? <>{materials}</> : 0}
            </Icon>
          </>
        )}

        <Title>{courseItem.title}</Title>

        {breadcrumb === 'concluídos' ? (
          <Button target="_blank" href={courseItem.certificate_template ? courseItem.certificate_template.certificate_image.urlPublica : ''}>
            <i className="fas fa-download" />
            {t('Download Certified')}
          </Button>
        ) : (
          <ProgressGrid>
            <ProgressText>{`${course.progress}%`}</ProgressText>
            <ProgressBar>
              <Progress value={course.progress} />
            </ProgressBar>
          </ProgressGrid>
        )}
      </MetaInfo>
    </Course>
  );
};

CoursesFilterItem.propType = {
  course: PropType.object.isRequired,
  breadcrumb: PropType.string.isRequired,
};

export default CoursesFilterItem;
