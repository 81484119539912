/* eslint-disable no-underscore-dangle */
/* eslint-disable linebreak-style */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Follow as FollowStyled } from './styles';
import {
  fetchSegments,
  fetchConnections,
  followSegment,
  followConnection,
  unfollowSegment,
  unfollowConnection,
  loadStateByName,
} from '../../services/endpoints';

const FollowSegment = ({
  allSegments,
  setAllSegments,
  allUsers,
  setAllUsers,
  idUsers,
  idAllSegments,
  user,
  users,
  segments,
}) => {
  const { t } = useTranslation('common');
  const [idSegments, setIdSegments] = useState(undefined);

  useEffect(() => {
    if (user) {
      const stateValue = user.name
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
      loadStateByName(stateValue).then((r) => {
        setIdSegments(r.data[0].id);
      }).catch((error) => {
        console.error(error);
      });
    }
  }, []);


  const handleFollowSegments = () => {
    if (segments) {
      if (allSegments.includes(idSegments || idAllSegments)) {
        fetchSegments(idSegments || idAllSegments).then((response) => {
          const data = response.data || [];
          if (data.length) {
            unfollowSegment(data[0].id).then(() => {
              setAllSegments((old) => old.filter((item) => item !== idAllSegments && item !== idSegments));
            });
          }
        });
      } else {
        followSegment(idSegments || idAllSegments).then(() => {
          setAllSegments((old) => [...old, idSegments || idAllSegments]);
        });
      }
    }
    if (users) {
      if (allUsers.includes(idUsers)) {
        fetchConnections(idUsers)
          .then((response) => {
            const data = response.data || [];
            if (data.length) {
              unfollowConnection(data[0].id).then(() => {
                setAllUsers((old) => old.filter((item) => item !== idUsers));
              });
            }
          });
      } else {
        followConnection(idUsers).then(() => {
          setAllUsers((old) => [...old, idUsers]);
        });
      }
    }
  };
  return (
    <FollowStyled
      className={allSegments.includes(idSegments || idAllSegments) ? 'active' : ''}
      onClick={handleFollowSegments}
    >
      {!allSegments.includes(idSegments || idAllSegments)
        ? t('buttons.follow_directory')
        : t('buttons.following')}
    </FollowStyled>
  );
};

FollowSegment.propTypes = {
  allSegments: PropTypes.array,
  allUsers: PropTypes.array,
  setAllSegments: PropTypes.func,
  setAllUsers: PropTypes.func,
  idUsers: PropTypes.number,
  idAllSegments: PropTypes.number,
  user: PropTypes.object,
  users: PropTypes.bool,
  segments: PropTypes.bool,
};

export default FollowSegment;
