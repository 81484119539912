import React from 'react';
import PropTypes from 'prop-types';
import Comment from '../Comment';

const CommentsChildren = ({ comments, mentionedComment }) => (
  <>
    {comments.children.map((comment, index) => (
      <Comment comment={comment} mentionedComment={mentionedComment} key={index} />
    ))}
  </>
);

CommentsChildren.propTypes = {
  comments: PropTypes.object.isRequired,
  mentionedComment: PropTypes.string,
};

export default CommentsChildren;
