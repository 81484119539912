/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Posts as PostsStyled } from './styled';
import PostItem from '../PostItem';

const Posts = ({
  posts,
  allUsers,
  setAllUsers,
  allSegments,
  setAllSegments,
}) => {
  const { t } = useTranslation(['common']);

  return (
    <PostsStyled>
      {posts.length === 0 ? (
        <div className="text-center">{t('no_records')}</div>
      ) : !posts.length ? (
        <>
          <PostItem
            key={posts.id}
            post={posts}
            allUsers={allUsers}
            setAllUsers={setAllUsers}
            allSegments={allSegments}
            setAllSegments={setAllSegments}
          />
        </>
      ) : (
        <>
          {posts.map((post, index) => (
            <PostItem
              key={post.id}
              post={post}
              allUsers={allUsers}
              setAllUsers={setAllUsers}
              allSegments={allSegments}
              setAllSegments={setAllSegments}
            />
          ))}
        </>
      )}
    </PostsStyled>
  );
};

Posts.propTypes = {
  posts: PropTypes.array.isRequired,
  allUsers: PropTypes.array,
  setAllUsers: PropTypes.func,
  allSegments: PropTypes.array,
  setAllSegments: PropTypes.func,
};

export default Posts;
