/* eslint-disable no-underscore-dangle */
/* eslint-disable linebreak-style */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Follow as FollowStyled } from './styles';
import {
  fetchSegments,
  fetchConnections,
  followSegment,
  followConnection,
  unfollowSegment,
  unfollowConnection,
  loadStateByName,
} from '../../services/endpoints';
import api from '../../services/api';

const Follow = ({
  allSegments,
  setAllSegments,
  allUsers,
  setAllUsers,
  idUsers,
  user,
  slug,
}) => {
  const { t } = useTranslation('common');
  const [idSegments, setIdSegments] = useState(undefined);

  const handleSegment = (u) => {
    const stateValue = u.name
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
    loadStateByName(stateValue)
      .then((r) => {
        setIdSegments(r.data.length > 0 ? r.data[0].id : undefined);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    if (slug) {
      api.get(`/users/${slug}`).then((response) => {
        handleSegment(response.data);
        console.log('response', response.data);
      });
    } else {
      handleSegment(user);
    }
  }, []);

  const _handleFollow = () => {
    if (idSegments !== undefined) {
      if (allSegments.includes(idSegments)) {
        fetchSegments(idSegments).then((response) => {
          const data = response.data || [];
          if (data.length) {
            unfollowSegment(data[0].id).then(() => {
              setAllSegments((old) => old.filter(
                (item) => item !== idSegments,
              ));
            });
          }
        });
      } else {
        followSegment(idSegments).then(() => {
          setAllSegments((old) => [...old, idSegments]);
        });
      }
    }
    if (allUsers) {
      if (allUsers.includes(idUsers)) {
        fetchConnections(idUsers).then((response) => {
          const data = response.data || [];
          if (data.length) {
            unfollowConnection(data[0].id).then(() => {
              setAllUsers((old) => old.filter((item) => item !== idUsers));
            });
          }
        });
      } else {
        followConnection(idUsers).then(() => {
          setAllUsers((old) => [...old, idUsers]);
        });
      }
    }
  };

  return (
    <FollowStyled
      className={allUsers.includes(idUsers) ? 'active' : ''}
      onClick={_handleFollow}
    >
      {!allUsers.includes(idUsers)
        ? t('buttons.follow')
        : t('buttons.following')}
    </FollowStyled>
  );
};

Follow.propTypes = {
  allSegments: PropTypes.array,
  allUsers: PropTypes.array,
  setAllSegments: PropTypes.func,
  setAllUsers: PropTypes.func,
  idUsers: PropTypes.number,
  slug: PropTypes.number,
  user: PropTypes.object,
};

export default Follow;
