import Axios from 'axios';
import api from '../services/api';
import { getCurrentUser } from '../services/endpoints';

async function fetchUserByName(name, extraParams) {
  return api.get(`users?enabled=true&name=${name}`, {
    ...extraParams,
  });
}

const { CancelToken } = Axios;
let cancel;

export function autocompleteSearchTest(value) {
  if (cancel !== undefined) {
    cancel();
  }

  return fetchUserByName(value, {
    cancelToken: new CancelToken(((c) => {
      cancel = c;
    })),
  }).then((response) => response.data)
    .catch((error) => Promise.reject(error.response));
}

export const mapToMentionsLib = (values) => {
  const mentions = [];

  values.forEach((value) => {
    mentions.push({
      id: value.id,
      display: value.name,
    });
  });

  return mentions;
};

export const getMentions = async (value, callback) => {
  let mentions = [];

  try {
    if (value.length > 2) {
      const users = await autocompleteSearchTest(value) || [];
      mentions = await mapToMentionsLib(users).filter((user) => user.id !== getCurrentUser().id);
    }
    return callback(mentions);
  } catch (e) {
    return callback([]);
  }
};
