import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const LinkStyled = styled(Link)`
text-decoration: none;
 &:hover{
   text-decoration: none;
   color: #2d2c2c;
 }
`;

export const Cover = styled.img`
  width: 100%;
  object-fit: cover;
  height: 80px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

export const NoCover = styled.div`
  width: 257.5px;
  height: 80px;  
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background: #004c94;
`;

export const Avatar = styled.img`
width: 60px;
height: 60px;
border-radius: 50%;
object-fit: cover;
position: absolute;
top: -15%;
left: 6%;
border: 3px solid #fff;
`;

export const Card = styled.div`
  background: #fff;
  padding: 50px 0 20px 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  position: relative;
  height: 172px;
`;

export const Greetings = styled.div`
  color: #004e93;
  font-weight: bold;
  padding: 0 20px;
  font-size: 14px;
`;

export const Username = styled.div`
  font-weight: bold;
  padding: 0 20px;
  margin-bottom: 10px;
  color: #2d2c2c;
`;

export const Line = styled.div`
  border-bottom: 1px solid #d3d3d3;
`;

export const Text = styled.div`
  color: #707070
  font-size: 12px;
  padding: 0 20px;
  margin-top: 10px;
`;
