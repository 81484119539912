import styled from 'styled-components';


export const StatsLength = styled.div`
  color: #2d2c2c;
  font-weight: bold;
  font-size: 20px;
  border-top: 1px solid #a8abb0;
  padding: 20px 0;
  margin: 20px 0;

  span{
    color: #004e93;
    margin-left: 5px;
  }

`;
