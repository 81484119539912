import styled from 'styled-components';

export const LessonStyled = styled.div`
  margin: 30px 0;
  padding-bottom: 30px; 
`;

export const Card = styled.div`  
  border: 1px solid #77a0c6; 
  color: #2d2c2c;
  cursor: pointer;  
  margin-bottom: 15px;
  padding: 15px;
  border-radius: 5px
`;

export const Lesson = styled.div`
  font-size: 0.875em;
  font-weight: 600;  
`;
