/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { Progress as ProgressStyle } from 'reactstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Mention } from 'react-mentions';
import { store } from 'react-notifications-component';
import {
  AvatarLink,
  ButtonPrimary,
  Buttons,
  Image,
  Preview,
  PreviewImage,
  PreviewDescription,
  PreviewImageRemoveIcon,
  PreviewProgressBar,
  MentionsInputStyled,
  Section,
  TagPeople,
  TextAreaBody,
  ButtonMedia,
} from './styles';
import video from '../../../assets/images/video.svg';
import defaultMentionStyle from '../utils/defaultMentionStyle';
import {
  getCurrentUser, loadAvatar,
} from '../../../services/endpoints';
import api from '../../../services/api';
import NoAvatar from '../../../assets/images/no-avatar.png';
import settings from '../../../services/settings';
import { submitMentionAndNotification } from '../../../utils/submitMentionsAndNotifications';
import { getToken } from '../../../services/auth';
import { Percentage } from '../../../pages/CoursesShow/Playlist/Progress/styles';
import { processVideo, sendImage } from '../../../utils/media';
import { getMentions } from '../../../utils/getMentions';

const WritePost = ({ handleRefreshPosts, toggle }) => {
  settings.notifications.dismiss.duration = 5000;
  const { t } = useTranslation('timeline');
  const [post, setPost] = useState('');
  const [values, setValues] = useState({});
  const [thumbnail, setThumbnail] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [file, setFile] = useState(null);
  const [fileUploadPercentage, setFileUploadPercentage] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [type, setType] = useState('post');
  const [UF, setUF] = useState('');
  const user = getCurrentUser();
  const userUF = user.profile.uf ? user.profile.uf.value.toLowerCase()
    .normalize('NFD').replace(/[\u0300-\u036f]/g, '') : '';
  const [avatar, setAvatar] = useState([]);
  const inputRef = useRef(null);

  const loadUserUF = () => {
    api.get(`/general/field_domain_values?match[value]=${userUF}`).then((response) => {
      setUF(response.data[0]);
    });
  };

  useEffect(() => {
    loadUserUF();
  }, []);

  function handleMedia(event) {
    if (disabled && file) return;
    if (!event.target.files.length) return;
    const fileType = event.target.files[0].type;
    if (fileType.startsWith('image')) {
      setType('imagem');
      setThumbnail(URL.createObjectURL(event.target.files[0]));
    } else if (fileType.startsWith('video')) {
      setType('video');
      setThumbnail(video);
    } else {
      store.addNotification({
        ...settings.notifications,
        message: t('notifications.danger.invalid_file_type'),
        type: 'danger',
      });
    }
    setFile(event.target.files[0]);
    setDisabled(false);
  }

  function handleMediaRemove() {
    if (disabled && file) return;
    document.getElementById('uploadFile').value = '';
    setType('post');
    setPost('');
    setFile(null);
    setDisabled(false);
    setThumbnail(null);
    setUploading(false);
  }

  const handlePostChange = (e) => {
    const { value } = e.target;

    setValues((prevState) => ({
      ...prevState,
      description: value,
    }));

    setPost(value);
    setDisabled(!(value.length > 0 || type === 'imagem'));
  };

  function handleAvatar() {
    loadAvatar(user.id).then((response) => {
      setAvatar(response.data);
    }).catch((error) => {
      store.addNotification({
        ...settings.notifications,
        message: t('notifications.danger.insert_image', error),
        type: 'danger',
      });
    });
  }

  useEffect(() => {
    handleAvatar();
  }, []);

  const handleTagPeople = () => {
    // if (disabled && file) return;
    inputRef.current.focus();
    setPost((old) => (old.length ? `${old} @` : '@'));
  };

  async function handleSubmit(event) {
    event.preventDefault();
    setDisabled(true);
    try {
      const response = await api.post('/social/posts', {
        user_iri: `/users/${user.id}`,
        post_segmentations: [
          { segment_iri: `/general/field_domain_values/${UF.id}` },
        ],
        ...values,
      });

      if (type === 'imagem') {
        await sendImage(`/social/posts/${response.data.id}`, file);
        setType(undefined);
        setFile(null);
        handleRefreshPosts(0, true);
        handleMediaRemove();
      } else if (type === 'video') {
        setUploading(true);
        await processVideo(file, setFileUploadPercentage, getToken(), `/social/posts/${response.data.id}`);
        setThumbnail(null);
        setFileUploadPercentage(0);
        setUploading(false);
      }
      if (values.description && values.description.indexOf('href="/profile/') !== -1) {
        submitMentionAndNotification(response.data.id, values.description, type);
      }
    } catch (err) {
      store.addNotification({
        ...settings.notifications,
        message: t('notifications.danger.insert_media', err),
        type: 'danger',
      });
    }
    handleMediaRemove();
    toggle();
    handleRefreshPosts(0, true);
  }

  return (
    <Section>
      {getCurrentUser() && (
        <>
          <AvatarLink to={`/profile/${user.id}`}>
            {avatar[0] ? (
              <Image src={avatar[0].file.urlPublica} />
            ) : (
              <Image src={NoAvatar} />
            )}
          </AvatarLink>

          <TextAreaBody>
            <MentionsInputStyled
              value={post}
              onChange={handlePostChange}
              name="post"
              placeholder={`${t('write_comments.create_a_post_now')}`}
              ignoreAccents
              style={defaultMentionStyle}
              inputRef={inputRef}
            >
              <Mention
                trigger="@"
                data={getMentions}
                markup='<a href="/profile/__id__">@__display__</a>'
              />
            </MentionsInputStyled>
          </TextAreaBody>
          {thumbnail && uploading && (
            <Preview>
              <PreviewProgressBar>
                <div>
                  <Percentage>
                    {`${fileUploadPercentage}%`}
                  </Percentage>
                </div>
                <div>
                  <ProgressStyle value={fileUploadPercentage} />
                </div>
              </PreviewProgressBar>
            </Preview>
          )}
          {thumbnail && !uploading && (
            <Preview>
              <PreviewImageRemoveIcon className="fas fa-window-close" onClick={() => handleMediaRemove()} />
              <PreviewImage src={thumbnail} />
              {file && (<PreviewDescription>{`${t('filename')}${file.name}`}</PreviewDescription>)}
            </Preview>
          )}
          <Buttons>
            <ButtonMedia>
              <input type="file" id="uploadFile" onChange={(event) => handleMedia(event)} />
              <i className="far fa-images" />
              {t('write_comments.media')}
            </ButtonMedia>

            <TagPeople onClick={handleTagPeople}>
              <i className="fal fa-user-plus" />
              {t('write_comments.tag_peoples')}
            </TagPeople>

            <ButtonPrimary
              disabled={disabled}
              onClick={handleSubmit}
            >
              {t('write_comments.share')}
            </ButtonPrimary>
          </Buttons>
        </>
      )}
    </Section>
  );
};

WritePost.propTypes = {
  handleRefreshPosts: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default WritePost;
