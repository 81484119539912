/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import { Col, Row, Spinner } from 'reactstrap';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import TabPanel from '../../utils/TabPanel';
import Base from '../../components/Theme/base';
import { Content } from './styles';
import { colourStyles } from '../../utils/colourStyles.js';
import Posts from '../../components/Timeline/Posts';
import WritePostToggle from '../../components/Timeline/WritePostToggle';
import HandleRefreshContext from '../../components/Timeline/PostItem/handleRefreshContext';
import api from '../../services/api';
import {
  getCurrentUser,
  loadStates,
  fetchConnections,
  fetchDirectories,
} from '../../services/endpoints';
import asyncLocalStorage from '../../utils/AsyncLocalStorage';

const Timeline = (props) => {
  const params = new URLSearchParams(window.location.search);
  const filter = params.get('filter');
  const { t } = useTranslation(['common']);
  const tab = parseInt(params.get('tab'), 10) || 0;
  const { slug } = props.match.params || undefined;
  const { promiseInProgress } = usePromiseTracker();
  const user = getCurrentUser();
  const [userProfile, setUserProfile] = useState('');
  const [states, setStates] = useState([]);
  const [selectedOption, setSelectedOption] = useState();
  const [loadingItem, setLoadingItem] = useState(false);
  const itemsPerPage = 5;
  const [numCurrentItens, setNumCurrentItens] = useState(itemsPerPage);
  const [isBottom, setIsBottom] = useState(false);
  const [page, setPage] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [allSegments, setAllSegments] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const history = useHistory();
  const handleChange = (event, newValue) => {
    history.push({
      pathname: history.location.pathname,
      search: `?tab=${newValue}`,
    });
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      borderBottom: '1px solid lightgrey',
      marginTop: '30px',
    },
  }));

  const classes = useStyles();

  if (!localStorage.getItem('directory')) {
    localStorage.setItem('directory', 'all');
  }

  const loadPosts = async (pageNumber, filterValue) => {
    const filterParams = {};
    let resultSegment = '';
    let resultUser = '';
    let resultUserSegment = '';
    const _connections = [];

    await fetchConnections()
      .then((response) => {
        if (response.data.length > 0) {
          const connections = [];
          const segments = [];
          const segmentsId = [];
          response.data.filter((connection) => (connection.user2_iri !== null
            ? connections.push(connection.user2.id)
            : ''));
          response.data.filter((connection) => (connection.segment_iri !== null
            ? segments.push({
              id: connection.segment.id,
              value: connection.segment.value,
            })
            : ''));
          response.data.filter((connection) => (connection.segment_iri !== null
            ? segmentsId.push(connection.segment.id)
            : ''));
          setAllSegments(segmentsId);
          setAllUsers(connections);
          _connections.push(connections);

          connections.map((item, index) => {
            if (index === connections.length - 1) {
              resultUser += `/users/${item}`;
            } else {
              resultUser += `/users/${item}|`;
            }
          });
          const isAll = segments.filter((item) => item.value === 'Todos');
          if (isAll.length > 0) {
            resultSegment = isAll[0].value;
          } else {
            segments.map((item, index) => {
              if (index === segments.length - 1) {
                resultSegment += `/general/field_domain_values/${item.id}`;
              } else {
                resultSegment += `/general/field_domain_values/${item.id}|`;
              }
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });

    await fetchDirectories().then((r) => {
      const directories = [];

      if (resultSegment === 'Todos' || filter === 'all') {
        directories.push(r.data.map((item) => item.user.id));
      } else {
        directories.push(
          _connections[0].filter((o1) => r.data.some((o2) => o1 === o2.user.id)),
        );
      }

      directories[0].map((item, index) => {
        if (index === directories[0].length - 1) {
          resultUserSegment += `/users/${item}`;
        } else {
          resultUserSegment += `/users/${item}|`;
        }
      });
    });

    if (tab === 0) {
      if (localStorage.getItem('directory') !== 'all') {
        Object.assign(filterParams, {
          segment_iri: `/general/field_domain_values/${localStorage.getItem(
            'directory',
          )}`,
        });
      } else if (resultSegment !== 'Todos' && filterValue === undefined && selectedOption === undefined && slug === undefined) {
        if (resultSegment !== '') {
          Object.assign(filterParams, {
            segment_iri: resultSegment,
          });
        }
        if (resultUser !== '') {
          Object.assign(filterParams, {
            or_user_iri: resultUser,
          });
        }
      }
    } else if (filter && filter !== 'all') {
      Object.assign(filterParams, { user_iri: `/users/${filter}` });
    } else if (resultUser !== '') {
      Object.assign(filterParams, { or_user_iri: resultUserSegment });
    }

    if (slug !== undefined) {
      Object.assign(filterParams, {
        id: slug,
      });
    }

    return api.get('/social/posts', {
      params: {
        ...filterParams,
        page: pageNumber,
        perPage: itemsPerPage,
        'order[created_at]': 'desc',
      },
    });
  };

  function handleRefreshPosts(
    pageNumber = currentPage,
    shouldRefresh = false,
    filterValue,
  ) {
    setLoadingItem(true);
    trackPromise(
      loadPosts(pageNumber, filterValue)
        .then((response) => {
          const posts = response.data || [];
          setNumCurrentItens(posts.length);
          setPage((old) => (shouldRefresh ? posts : [...old, ...posts]));
          setCurrentPage(pageNumber + 1);
          setLoadingItem(false);
        })
        .catch((error) => {
          console.log(error);
        }),
    );
    loadStates()
      .then((response) => {
        const options = [
          {
            value: 'all',
            label: t('view_posts_from_all_directories'),
          },
        ];
        const result = response.data.filter((item) => item.value !== 'Todos');
        result.map((state) => options.push({
          value: state.id,
          label: state.value,
        }));
        setStates(options || []);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  window.onscroll = function () {
    if (
      numCurrentItens === itemsPerPage
      && window.innerHeight + window.scrollY >= document.body.offsetHeight - 10
      && loadingItem === false
    ) {
      setIsBottom(true);
      handleRefreshPosts();
    }
  };

  function filterPostsByState(e) {
    setSelectedOption(e);
    localStorage.setItem('directory-name', e.label);
    asyncLocalStorage.setItem('directory', e.value).then(() => {
      handleRefreshPosts(0, true, e);
    });
  }

  useEffect(() => {
    if (!filter) {
      localStorage.setItem('directory', 'all');
      localStorage.removeItem('directory-name');
    }

    switch (tab) {
      case 1:
        handleRefreshPosts(0, true);
        break;
      default:
        handleRefreshPosts(0, true);
    }
    return () => {
      setPage([]);
      setCurrentPage(0);
      setNumCurrentItens(itemsPerPage);
      setSelectedOption();
      setLoadingItem(false);
      setIsBottom(false);
    };
  }, [tab]);

  useEffect(() => {
    if (user !== null) {
      setUserProfile(user.profile.uf ? user.profile.uf.value : '');
    }
  });

  return (
    <Base
      blocks={[
        { name: 'myprofile' },
        { name: 'live' },
        { name: 'image' },
        { name: 'leadership' },
        { name: 'follow' },
        { name: 'courses' },
      ]}
    >
      <>
        <Content>
          {userProfile.length > 0 ? (
            <WritePostToggle handleRefreshPosts={handleRefreshPosts} />
          ) : (
            <h3 className="text-center">
              Você deve preencher seu perfil para criar posts!
            </h3>
          )}

          <div className={classes.root}>
            <Tabs
              value={tab}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleChange}
            >
              <Tab label={t('tabs.all_posts')} />
              <Tab label={t('tabs.all_directories')} />
            </Tabs>
          </div>
          <TabPanel value={tab} index={0}>
            <div>
              <Row>
                <Col xs={12} sm={6}>
                  <Select
                    onChange={filterPostsByState}
                    options={states}
                    placeholder={t('view_posts')}
                    styles={colourStyles}
                    value={filter ? { label: localStorage.getItem('directory-name'), value: localStorage.getItem('directory') } : selectedOption}
                  />
                </Col>
              </Row>

              <HandleRefreshContext.Provider value={{ handleRefreshPosts }}>
                {promiseInProgress === true && !isBottom ? (
                  <div className="text-center">
                    <Spinner color="dark" />
                  </div>
                ) : (
                  <Posts
                    posts={page}
                    allUsers={allUsers}
                    setAllUsers={setAllUsers}
                    allSegments={allSegments}
                    setAllSegments={setAllSegments}
                  />
                )}
              </HandleRefreshContext.Provider>
            </div>
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <HandleRefreshContext.Provider value={{ handleRefreshPosts }}>
              {promiseInProgress === true && !isBottom ? (
                <div className="text-center">
                  <Spinner color="dark" />
                </div>
              ) : (
                <Posts
                  posts={page}
                  allUsers={allUsers}
                  setAllUsers={setAllUsers}
                />
              )}
            </HandleRefreshContext.Provider>
          </TabPanel>

          {!promiseInProgress && numCurrentItens === itemsPerPage && (
            <div className="text-center mb-5">
              {!loadingItem && (
                <div className="text-center">
                  <Spinner color="dark" />
                </div>
              )}
            </div>
          )}
        </Content>
      </>
    </Base>
  );
};

export default Timeline;
