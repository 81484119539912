import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  IconCap,
  IconBox,
  IconArrow,
  FilterBox,
  Box,
  Text,
  Menu,
  Item,
  Icon,
  LinkStyled,
} from './styles';

const CoursesFilter = ({ term }) => {
  const { t } = useTranslation('courses');
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const items = [
    {
      url: '/cursos/filtro/assistidos',
      icon: 'fas fa-eye',
      text: t('Watched'),
    },
    {
      url: '/cursos/filtro/em_andamento',
      icon: 'fas fa-chart-line',
      text: t('In progress'),
    },
    {
      url: '/cursos/filtro/concluidos',
      icon: 'far fa-file-certificate',
      text: t('Completed'),
    },
  ];

  return (
    <div>
      <Box onClick={toggle}>
        <IconBox>
          <IconCap className="fas fa-graduation-cap" />
        </IconBox>
        <FilterBox modal={modal}>
          {modal
            ? <Text>{t('See my courses')}</Text>
            : <Text>{term || t('See my courses')}</Text>}

          <IconArrow className="fas fa-sort-down" />
        </FilterBox>
      </Box>

      {modal && (
      <Menu>
        {items.map((item, index) => (
          <LinkStyled to={item.url} key={index}>
            <Item>
              <Icon className={item.icon} />
              {item.text}
            </Item>
          </LinkStyled>
        ))}
      </Menu>
      )}
    </div>
  );
};

CoursesFilter.propTypes = {
  term: PropTypes.string,
};

export default CoursesFilter;
