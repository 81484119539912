import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { Spinner } from "reactstrap";
import ReactNotification from "react-notifications-component";
import jwtDecode from "jwt-decode";
import Routes from "./routes";
import store from "./store";
import api from "./services/api";
import { getCurrentUser } from "./services/endpoints";
import { getToken, getSession } from "./services/auth";
import { getRefreshToken } from "./services/refresh";

function AuthProvider({ children }) {
  const [state, setState] = useState({
    loading: false,
    error: null,
  });

  const setItem = (key, data) =>
    new Promise((resolve) => {
      localStorage.setItem(key, data);
      setTimeout(() => resolve(true), 1000);
    });

  useEffect(() => {
    setInterval(async () => {
      const currentDate = new Date().getTime() / 1000;
      const { exp } = jwtDecode(getToken());
      const expirateTime = (exp - currentDate) / 60;
      if (expirateTime < 10) {
        await getRefreshToken(getSession());
      }
    }, 300000);
  }, []);

  useEffect(() => {
    async function fetchUser() {
      try {
        const idxUser = `${process.env.REACT_APP_PREFIX}.user`;
        const me = await api.get("/users/me");
        await setItem(idxUser, JSON.stringify(me.data));
        getCurrentUser();
        setState({ loading: true, error: null });
      } catch (e) {
        console.error(e);
        setState({ loading: true, error: e });
      }
    }
    fetchUser();
  }, []);

  return state.loading && state.error === null && getCurrentUser() ? (
    children
  ) : (
    <div className="mt-30 mb-30 text-center">
      <Spinner />
    </div>
  );
}

function App() {
  return (
    <Provider store={store}>
      <AuthProvider>
        <ReactNotification />
        <Routes />
      </AuthProvider>
    </Provider>
  );
}

export default App;
