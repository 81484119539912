/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Col, Row, Progress } from 'reactstrap';
import { Scrollbars } from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';
import PropTypes from 'prop-types';
import { getCurrentUser } from '../../../services/endpoints';
import api from '../../../services/api';
import {
  Card,
  CardItem,
  CountItem,
  CurrentVideo,
  Description,
  List,
  PlaylistTitle,
  Preview,
  PreviewDescription,
  Screen,
  VideoCount,
  Thumbnail,
  Icon,
  ProgressGrid,
  ProgressText,
  ProgressBar,
  LessonCount,
} from './styles';

const Playlist = ({
  data, course, lesson, handleCourseStats, handleLesson, lessonId,
}) => {
  const { t } = useTranslation('courses');
  const user = getCurrentUser();
  const [currentVideo, setCurrentVideo] = useState(data[0]);

  const submitProgress = (progressPercentage, watchedBoolean) => {
    api
      .post('/general/watcheds', {
        user_iri: `/user/${user.id}`,
        progress: progressPercentage,
        content_iri: `/lms/lesson_contents/${currentVideo.id}`,
        watched: watchedBoolean,
      })
      .then(() => {
        handleLesson(lesson.id);
        handleCourseStats(course.id);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCurrentVideo = (video) => {
    setCurrentVideo(video);
  };

  useEffect(() => {
    setCurrentVideo(data[0]);

    return () => {
      setCurrentVideo(data[0]);
    };
  }, [lesson]);


  const getMedia = (media) => {
    const contenttype = media.type !== null ? media.type : null;
    if (contenttype === 'VIMEO_MEDIA' && data.includes(currentVideo) != false) {
      return (
        <Screen className="embed-responsive embed-responsive-16by9">
          <ReactPlayer
            url={`https://player.vimeo.com/video/${media.vimeo_media_reference}`}
            width="100%"
            height="100%"
            playing={false}
            controls
            onStart={() => submitProgress(0, false)}
            onEnded={() => submitProgress(100, true)}
          />
        </Screen>
      );
    }
  };

  const createThumbnail = (media) => {
    const contenttype = media.type !== null ? media.type : null;
    if (contenttype === 'VIMEO_MEDIA') {
      return (
        <Thumbnail>
          <Screen className="embed-responsive embed-responsive-16by9">
            <ReactPlayer
              url={`https://player.vimeo.com/video/${media.vimeo_media_reference}`}
              width="100%"
              height="100%"
              playing={false}
            />
          </Screen>
        </Thumbnail>
      );
    }
  };

  return (
    <>
      <Row>
        <CurrentVideo xs={12} md={7}>
          <VideoCount className="text-sm-right">
            <CountItem>
              Vídeo
              {' '}
              {currentVideo.position < 10
                ? `0${currentVideo.position}`
                : `${currentVideo.position}`}
            </CountItem>
            {' '}
            de
            {' '}
            {data.length < 10 ? `0${data.length}` : data.length}
          </VideoCount>
          <div>{getMedia(currentVideo)}</div>
          <ProgressGrid>
            <LessonCount>
                        Aula
              {' '}
              {lesson.position < 10
                ? `0${lesson.position}`
                : `${lesson.position}`}
              <span className="ml-1">|</span>
            </LessonCount>
            <ProgressText>
              {lessonId !== undefined ? lessonId.progress : 0}
              <span>%</span>
            </ProgressText>
            <ProgressBar>
              <Progress value={lessonId !== undefined ? lessonId.progress : 0} />
            </ProgressBar>
          </ProgressGrid>
          <Description>{lesson.title}</Description>
        </CurrentVideo>

        <List xs={12} md={5}>
          <PlaylistTitle>{t('Next Videos')}</PlaylistTitle>
          <Scrollbars
            renderView={(props) => (
              <div {...props} style={{ ...props.style, overflowX: 'hidden' }} />
            )}
            renderTrackVertical={(props) => (
              <div
                {...props}
                style={{
                  position: 'absolute',
                  width: '9px',
                  right: '3px',
                  bottom: '0',
                  top: '0',
                  borderRadius: '7px',
                  backgroundColor: '#eee',
                }}
              />
            )}
            renderThumbVertical={() => (
              <div
                style={{
                  position: 'relative',
                  display: 'block',
                  backgroundColor: '#004c94',
                  borderRadius: '7px',
                  width: '9px',
                }}
              />
            )}
            style={{
              height: 235,
            }}
          >
            <Card>
              {data.map((video) => (
                <CardItem
                  id="tooltip"
                  title={`${video.title}`}
                  key={video.id}
                  onClick={() => handleCurrentVideo(video)}
                >
                  <Col xs={5}>
                    {video.thumbnail !== null ? (
                      <Preview
                        className="img-fluid"
                        src={video.thumbnail.urlPublica}
                      />
                    ) : (
                      <>{createThumbnail(video)}</>
                    )}
                    <Icon className="fas fa-play-circle" />
                  </Col>
                  <Col xs={7}>
                    <VideoCount>
                      <CountItem>
                        Vídeo
                        {' '}
                        {video.position < 10
                          ? `0${video.position}`
                          : `${video.position}`}
                      </CountItem>
                      {' '}
                      de
                      {' '}
                      {data.length < 10 ? `0${data.length}` : data.length}
                    </VideoCount>

                    <PreviewDescription>{video.title}</PreviewDescription>
                  </Col>
                </CardItem>
              ))}
            </Card>
          </Scrollbars>
        </List>
      </Row>
    </>
  );
};

Playlist.propTypes = {
  data: PropTypes.array.isRequired,
  course: PropTypes.object.isRequired,
  lesson: PropTypes.object.isRequired,
  handleCourseStats: PropTypes.func.isRequired,
  handleLesson: PropTypes.func.isRequired,
};

export default Playlist;
