import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { GuardProvider, GuardedRoute } from 'react-router-guards';
import Timeline from './pages/Timeline';
import Courses from './pages/Courses';
import CoursesFilterPage from './pages/CoursesFilter';
import Profile from './pages/Profile';
import ProfileShow from './pages/ProfileShow';
import Contact from './pages/Contact';
import CoursesShow from './pages/CoursesShow';
import Directories from './pages/Directories';
import Search from './pages/Search';
import LeadershipAndFollow from './pages/LeadershipAndFollow';
import Live from './pages/Live';
import { getCurrentUser } from './services/endpoints';

const requireState = (to, from, next) => {
  const currentUser = getCurrentUser();
  const hasState = currentUser.profile && currentUser.profile.uf && currentUser.profile.uf.value;
  if (hasState) {
    next();
  }
  next.redirect('/profile');
};

const Routes = () => (
  <BrowserRouter>
    <GuardProvider>
      <Switch>
        <GuardedRoute path="/profile" exact component={Profile} />
        <GuardedRoute path="/profile/:slug" exact component={ProfileShow} />
        <GuardProvider guards={[requireState]}>
          <GuardedRoute path="/" exact component={Timeline} />
          <GuardedRoute path="/live" exact component={Live} />
          <GuardedRoute path="/diretorios" exact component={Directories} />
          <GuardedRoute path="/timeline" exact component={Timeline} />
          <GuardedRoute path="/timeline/:slug" exact component={Timeline} />
          <GuardedRoute path="/timeline/:slug/comment/:commentId" exact component={Timeline} />
          <GuardedRoute path="/cursos" exact component={Courses} />
          <GuardedRoute path="/cursos/:slug" exact component={CoursesShow} />
          <GuardedRoute path="/cursos/filtro/:slug" exact component={CoursesFilterPage} />
          <GuardedRoute path="/meu-perfil" exact component={Profile} />
          <GuardedRoute path="/contato" exact component={Contact} />
          <GuardedRoute path="/pesquisa" exact component={Search} />
          <GuardedRoute path="/liderancas-quem-eu-sigo" exact component={LeadershipAndFollow} />
        </GuardProvider>
      </Switch>
    </GuardProvider>
  </BrowserRouter>
);

export default Routes;
