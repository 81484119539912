import styled from "styled-components";

export const Comments = styled.div`

`;

export const ShowComments = styled.div`
  color: #004c94;
  font-size: 16px;
  font-weight: bold;
  padding-top: 30px;
  border-top: 1px solid #bfbfbf;
  padding-bottom: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
  i{
    font-size: 24px;
    padding-left: 10px;
  }
`;
