import React, { useState, useEffect } from 'react';
import PropType from 'prop-types';
import {
  Course, Icon, Image, Link, MetaInfo, Title, Grid,
} from './styles';

const CourseItem = ({ course }) => {
  const url = `/cursos/${course.slug}`;
  const [materials, setMaterials] = useState([]);
  const sum = (accumulator, currentValue) => accumulator + currentValue;

  useEffect(() => {
    if (course.lessons.length) {
      const m = [];
      course.lessons.map((item) => {
        if (item.lesson_support_materials.length > 0) {
          item.lesson_support_materials.map((materialsItem) => m.push(materialsItem.support_material.support_material_contents.length));
        } else {
          m.push(item.lesson_support_materials.length);
        }
        return null;
      });
      setMaterials(m.reduce(sum));
    }
    return () => {
      setMaterials([]);
    };
  }, [course]);

  return (
    <Course>
      <Grid>
        {course.image && (
        <Link to={url}>
          <Image src={course.image.urlPublica} />
        </Link>
        )}
        <Title>{course.title}</Title>
      </Grid>
      <MetaInfo>
        <Icon className="fas fa-play-circle">{course.lessons.length}</Icon>
        <Icon className="far fa-file-pdf">
          {course.lessons.length ? <>{ materials }</> : 0}
        </Icon>
      </MetaInfo>
    </Course>
  );
};

CourseItem.propType = {
  course: PropType.object.isRequired,
};

export default CourseItem;
