/* eslint-disable linebreak-style */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  Cover,
  NoCover,
  Greetings,
  Username,
  Line,
  Text,
  Avatar,
  LinkStyled,
} from './styles';
import { getCurrentUser, loadCover, loadAvatar } from '../../../services/endpoints';
import { loadProfileFields } from '../../../utils/profileInfo';
import NoAvatar from '../../../assets/images/no-avatar-blue.jpg';

const MyProfile = () => {
  const { t } = useTranslation('common');
  const [coverImage, setCoverImage] = useState([]);
  const [avatar, setAvatar] = useState([]);
  const currentUser = getCurrentUser();
  const [data, setData] = useState({});

  const loadData = async () => {
    const profileFields = await loadProfileFields(
      `/users/${currentUser.id}`,
      false,
    );
    setData(profileFields);
    loadCover(currentUser.id)
      .then((response) => {
        setCoverImage(response.data);
      })
      .catch((error) => {
        console.error(error);
      });

    loadAvatar(currentUser.id)
      .then((response) => {
        setAvatar(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    loadData();
  }, []);


  return (
    <div>
      <LinkStyled to="/profile">
        {coverImage[0] ? (
          <Cover src={coverImage[0].file.urlPublica} />
        ) : (
          <NoCover />
        )}
        <Card>
          {
              avatar[0]
                ? (
                  <Avatar
                    src={avatar[0].file.urlPublica}
                  />
                )
                : <Avatar src={NoAvatar} />
            }

          <Greetings>{t('welcome')}</Greetings>
          <Username>{currentUser.name}</Username>
          <Line />
          <Text>
            {`${
              data.cidade && data.cidade.value !== ''
                ? `${data.cidade.value} /`
                : ''
            } ${data.uf ? data.uf.value : ''}`}
            <br />
            {data.cargo && data.cargo.value !== '' ? data.cargo.value : ''}
          </Text>
        </Card>
      </LinkStyled>
    </div>
  );
};

export default MyProfile;
