import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { Courses } from './styles';
import CoursesFilterItem from '../CoursesFilterItem';

const CoursesFilterList = ({ courses, breadcrumb }) => (
  <Courses>
    <Row>
      {courses.map((course) => (
        <Col xs={12} sm={4} key={course.id}>
          <CoursesFilterItem course={course} breadcrumb={breadcrumb} />
        </Col>
      ))}
    </Row>
  </Courses>
);

CoursesFilterList.propTypes = {
  courses: PropTypes.array.isRequired,
  breadcrumb: PropTypes.string.isRequired,
};

export default CoursesFilterList;
