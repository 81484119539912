import axios from 'axios';
import { getToken } from './auth';
import { signinRedirect } from '../utils/auth';

const apiUrl = process.env.REACT_APP_API_URL;

const api = axios.create({
  baseURL: `https://${apiUrl}`,
  headers: { Authorization: getToken() },
});

api.interceptors.request.use(async (config) => config, (error) => Promise.reject(error));

api.interceptors.response.use((response) => response, (error) => {

  const status = error.response && error.response.hasOwnProperty('status') ? error.response.status : error;

  switch (status) {
    case 401:
    case 403:
      signinRedirect(error.response.data.reason);
      break;
    case 405:
      console.log('Method Not Allowed');
      break;
    case 406:
      console.log('Not Acceptable');
      break;
    case 422:
      console.log('Unprocessable Entity');
      break;
    default:
      console.log(`Error ${status}`);
  }

  return Promise.reject(error);
});

export default api;
