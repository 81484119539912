import styled, { css } from 'styled-components';

export const Cover = styled.div`
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  ${(props) => props.source
    && css`
    background-image: url("${props.source}");
  `}
  &:after {
    content: "";
    display: block;
    padding-bottom: 27%;
  }
`;

export const ButtonNewCover = styled.label`
  position: absolute;
  bottom: 30px;
  right: 20%;
  background: rgba(0, 0, 0, 0.6);
  padding: 7px 15px;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  transition: all 300ms linear;
  opacity: 0;
  color: #ffffff;
  &:hover {
    background: rgba(0, 0, 0, 0.9);
  }
  i {
    margin-right: 5px;
  }
  input {
    display: none;
  }
`;

export const CoverContainer = styled.div`
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  position: relative;
  background: #004c94;
  &:hover ${ButtonNewCover} {
    opacity: 1;
  }
`;

export const CoverFrame = styled.div`
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  width: 100%;
  height: auto;
  background: #004c94;
  position: relative;
`;

export const NoCover = styled.div`
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;  
  width: 700px;
  height: 400px;
  background: #004c94;
`;
