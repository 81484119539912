const changeMaskPhone = (value) => {
  if (value !== undefined && value !== null) {
    const newValue = value.replace(/\D/g, '');

    if (newValue.slice(2, 3) === '9') {
      return '(99) 99999-9999';
    }
  }
  return '(99) 9999-9999';
};

export default changeMaskPhone;
