import styled from 'styled-components';
import { Row } from 'reactstrap';
import { Link } from 'react-router-dom';

export const CardHeader = styled.div`
  background: #fff;
  padding: 30px 0 0 0;
  height: 200px;  
  text-align: center;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
`;

export const CardFooter = styled.div`
  background: #fff;
  padding: 15px 0;
  margin-bottom: 30px;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  text-align: center;
`;

export const Image = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
`;

export const Name = styled.p`
  font-weight: bold;
  text-transform: capitalize;
  margin-top: 15px;
  margin-bottom: 0;
`;

export const Role = styled.p`
  color: #7f7f7f;
  font-size: 14px;
`;

export const Line = styled.div`
  border-bottom: 1px solid #d3d3d3;
`;

export const ProfileBtn = styled.button.attrs({
  className: 'btn btn-default',
})`  
`;

export const CustomRow = styled(Row)`  
  padding: 0 20px;
`;

export const LinkCustom = styled(Link)`  
  text-decoration: none;
  &:hover{
    text-decoration: none;
  }
`;
