import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import dateFormat from 'dateformat';
import {
  Curriculum, Date, Item, Title, Line,
} from './styles';

const PublicCurriculum = ({ curriculum }) => {
  const { t } = useTranslation('timeline');

  const RenderDates = ({ start, end }) => {
    if (end !== null && end) {
      if (start !== null && start) {
        return <Date>{`${dateFormat(start, 'dd/mm/yyyy')} até ${end}`}</Date>;
      }
    }
    return <Date>{`${dateFormat(start, 'dd/mm/yyyy')} até a atualidade`}</Date>;
  };

  return (
    <Curriculum>
      {curriculum.length > 0 && (
        <>
          <Line>
            <h2>{t('public_curriculum')}</h2>
          </Line>
          {curriculum.map((item, idx) => (
            // eslint-disable-next-line react/no-array-index-key
            <Item key={idx}>
              <Title>{item.funcao}</Title>
              <RenderDates start={item.data_de_inicio} end={item.data_fim} />
            </Item>
          ))}

        </>
      )}

    </Curriculum>
  );
};

PublicCurriculum.propTypes = {
  curriculum: PropTypes.array.isRequired,
};

export default PublicCurriculum;
