/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row, Spinner } from 'reactstrap';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import Breadcrumb from '../../components/Breadcrumb';
import Base from '../../components/Theme/base';
import CoursesList from '../../components/Courses/CoursesList';
import CoursesFilter from '../../components/Courses/CoursesFilter';
import Search from '../../components/Forms/Elements/Search';
import api from '../../services/api';
import { SearchBox } from './styles';

const Courses = () => {
  const { t } = useTranslation('courses');
  const [searchCourse, setSearchCourse] = useState('');
  const [loadingItem, setLoadingItem] = useState(false);
  const itemsPerPage = 12;
  const [numCurrentItens, setNumCurrentItens] = useState(itemsPerPage);
  const [page, setPage] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const { promiseInProgress } = usePromiseTracker();

  const loadCourses = (pageNumber, search = searchCourse) => {
    const filterParams = {};

    if (searchCourse.length) {
      Object.assign(filterParams, {
        'match[title]': search,
      });
    }

    return api.get('/lms/courses', {
      params: {
        ...filterParams,
        page: pageNumber,
        perPage: itemsPerPage,
        active: true,
        'order[id]': 'desc',
      },
    });
  };

  const getCourses = (pageNumber = currentPage, shouldRefresh = false, search = searchCourse) => {
    setLoadingItem(true);
    trackPromise(
      loadCourses(pageNumber, search).then((response) => {
        const courses = response.data || [];
        setNumCurrentItens(courses.length);
        setPage((old) => (shouldRefresh ? courses : [...old, ...courses]));
        setCurrentPage(pageNumber + 1);
        setLoadingItem(false);
      }).catch((e) => {
        console.log(e);
      }),
    );
  };


  useEffect(() => {
    setLoadingItem(true);
    getCourses(0, true);
    return () => {
      setPage([]);
    };
  }, []);

  window.onscroll = function () {
    if ((numCurrentItens === itemsPerPage) && (window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 10)
    && loadingItem === false) {
      getCourses();
    }
  };

  const getSearch = (value) => {
    setSearchCourse(value);
  };

  const search = (event) => {
    if (!event.shiftKey && (event.charCode === 13 || event.keyCode === 13)) {
      getCourses(0, true);
    }
  };

  const searchOnClick = () => {
    getCourses(0, true);
  };

  return (
    <Base
      blocks={[
        { name: 'myprofile' },
        { name: 'image' },
        { name: 'leadership' },
        { name: 'follow' },
      ]}
    >

      <Row>
        <Col xs={12} md={6}>
          <h1>{t('Courses')}</h1>
          <Breadcrumb root={[
            { title: 'Cursos', url: '/cursos' },
          ]}
          />
        </Col>

        <Col xs={12} md={6}>
          <CoursesFilter />
        </Col>
      </Row>


      <SearchBox className="form-search">
        <Search
          placeholder={t('Search by course')}
          handleKeyUp={search}
          search={searchCourse.toLowerCase()}
          handleGetSearch={getSearch}
          onClick={searchOnClick}
        />
      </SearchBox>

      {promiseInProgress === true ? (
        <div className="text-center">
          <Spinner color="dark" />
        </div>
      ) : page.length > 0 ? (
        <CoursesList courses={page} />
      ) : (
        <>{t('common:no_records')}</>
      )}


      {numCurrentItens === itemsPerPage && (
      <div className="text-center mb-5">
        {!loadingItem && (
        <div className="text-center">
          <Spinner color="dark" />
        </div>
        )}
      </div>
      )}

    </Base>
  );
};

export default Courses;
