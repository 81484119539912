import React from 'react';
import { Col, Container, Row } from "reactstrap";
import { Footer as FooterStyled, Image, Text } from './styles'
import { useTranslation } from "react-i18next";

import Logo from '../../../assets/images/logo-branca.png';
import { Link } from "react-router-dom";

const Footer = () => {

  const {t} = useTranslation();

  return (
    <FooterStyled className="box-footer">
      <Container>
        <Row className="align-items-center">
          <Col xs={12} sm={6}>
            <Link to={'/'}>
              <Image src={Logo}/>
            </Link>
          </Col>
          <Col xs={12} sm={6}>
            <Text>{t('copyright')}</Text>
          </Col>
        </Row>
      </Container>
    </FooterStyled>
  );
};

export default Footer;
