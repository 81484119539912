import styled from 'styled-components';

export const PageTitle = styled.div`
  color: #004e93;
  font-weight: bolder;
  margin-bottom: 20px;
`;

export const Title = styled.div`
  font-weight: bolder;
  margin-bottom: 20px;
  word-wrap: normal;
  padding: 20px;
`;

export const Box = styled.div`
  background: #fff;  
  border-radius: 5px;
`;

export const Description = styled.div`
  color: #004e93;
  font-weight: bolder;
  padding: 20px;
`;

export const DescriptionText = styled.div`  
  font-weight: normal;
  padding: 0 20px 20px 20px;
`;
