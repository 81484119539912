/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  Col, Container, Row, Form,
} from 'reactstrap';
import { store } from 'react-notifications-component';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  Header as HeaderStyle,
  Image,
  Nav,
  Text,
  Logo,
  NavItem,
  MenuTitle,
  Icon,
  ImageIcon,
  LiveText,
  LiveIcon,
  LiveLink,
  InputSearch,
} from './styles';
import NotificationsWidget from '../../NotificationsWidget';
import { loadAvatar } from '../../../services/endpoints';
import NoAvatar from '../../../assets/images/no-avatar.png';
import LogoBranca from '../../../assets/images/logo-branca.png';
import Map from '../../../assets/images/brazil-map.png';
import settings from '../../../services/settings';
import Menu from '../../../assets/jsons/menus';
import { redirectToLogin } from '../../../utils/auth';

const Header = () => {
  const user = JSON.parse(
    localStorage.getItem(`${process.env.REACT_APP_PREFIX}.user`),
  );
  const [avatar, setAvatar] = useState([]);
  const [active, setActive] = useState(false);
  const { t } = useTranslation('common');


  useEffect(() => {
    loadAvatar(user.id)
      .then((response) => {
        setAvatar(response.data);
      })
      .catch((error) => {
        store.addNotification({
          ...settings.notifications,
          message: t('notifications.danger.insert_image', error),
          type: 'danger',
        });
      });
  }, []);


  if (!Object.keys(user).length > 0) {
    return <></>;
  }

  return (
    <>
      <HeaderStyle>
        <Container className="box-header">
          <Row className="align-items-center">
            <Col xs={12} sm={1}>
              <Link to="/timeline">
                <Logo alt="logo-white" src={LogoBranca} />
              </Link>
            </Col>

            <Col xs={12} sm={3}>
              <Form
                action="/pesquisa"
              >
                {!active
                  ? (
                    <div
                      className="form-menu"
                      onClick={() => setActive((prevState) => !prevState)}
                    >
                      <InputSearch
                        className="menu-search"
                        placeholder={t('search_by_subject_posts')}
                      />
                      <i className="far fa-search" />
                    </div>
                  )
                  : (
                    <div
                      className="form-menu-selected"
                    >
                      <InputSearch
                        className="menu-search-selected"
                        type="text"
                        name="term"
                        placeholder={t('search_by')}
                      />
                      <button type="submit">
                        <i className="far fa-search" />
                      </button>
                    </div>
                  )}
              </Form>
            </Col>

            <Col xs={12} sm={1}>
              <LiveLink
                to="/live"
                activeClassName="active"
              >
                <LiveIcon className="fas fa-circle" />
                <LiveText>Live</LiveText>
              </LiveLink>
            </Col>

            <Col xs={12} sm={7}>
              <Nav>
                {Menu.map((menu) => (
                  <NavItem key={menu.id}>
                    <MenuTitle
                      to={menu.url}
                      activeClassName="active"
                      title={menu.title}
                    >

                      {menu.icon !== '' ? (
                        <Icon className={menu.icon} />
                      ) : (
                        <ImageIcon src={Map} />
                      )}

                      <div>{menu.title}</div>
                    </MenuTitle>
                  </NavItem>
                ))}
                <NavItem>
                  <NotificationsWidget profile />
                </NavItem>

                <NavItem>
                  <MenuTitle
                    to="/profile"
                    activeClassName="active"
                  >
                    {avatar[0] ? (
                      <Image src={avatar[0].file.urlPublica} />
                    ) : (
                      <Image src={NoAvatar} />
                    )}
                    <div>{user.name.split(' ')[0]}</div>
                  </MenuTitle>
                </NavItem>

                <NavItem onClick={() => redirectToLogin()}>
                  <Icon className="fas fa-sign-out" />
                  <Text>{t('buttons.logout')}</Text>
                </NavItem>

              </Nav>
            </Col>

          </Row>
        </Container>
      </HeaderStyle>

    </>
  );
};

export default Header;
