/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { Spinner } from 'reactstrap';
import Base from '../../components/Theme/base';
import {
  Box, Title, Description, DescriptionText,
} from './styles';
import { fetchLives } from '../../services/endpoints';
import { convertToUSDate } from '../../utils/convertToUSDate';

const Live = () => {
  const { t } = useTranslation('common');
  const [lives, setLives] = useState([]);
  const [online, setOnline] = useState(1);
  const { promiseInProgress } = usePromiseTracker();

  const handleLives = () => {
    trackPromise(
      fetchLives()
        .then((response) => {
          const data = response.data || [];
          const now = new Date().getTime();
          const r = data.filter(
            (item) => convertToUSDate(item.started_at) <= now
              && convertToUSDate(item.ended_at) >= now,
          );

          if (r.length) {
            setOnline(0);
          }
          setLives(r[0]);
        })
        .catch((error) => console.error(error)),
    );
  };

  useEffect(() => {
    handleLives();
  }, []);

  return (
    <Base blocks={[{ name: 'myprofile' }, { name: 'image' }]}>
      <h1>Live</h1>

      {promiseInProgress === true ? (
        <div className="text-center">
          <Spinner color="dark" />
        </div>
      ) : !online ? (
        <Box>
          <Title>{lives.title}</Title>
          <ReactPlayer
            url={lives.url}
            playing={false}
            height={450}
            width="100%"
            controls
            className="mb-3"
          />
          <Description>{t('titles.description')}</Description>
          <DescriptionText
            dangerouslySetInnerHTML={{
              __html: lives.description,
            }}
          />
        </Box>
      ) : (
        <div>{t('no_records')}</div>
      )}
    </Base>
  );
};
export default Live;
