import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const IconCap = styled.div`
  color: #fff;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  margin-bottom: 30px;
  margin-left: 45%;
  white-space: nowrap;
`;

export const IconBox = styled.div`
  background-color: #004e93;
  padding: 10px;  
  text-align: center;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  width: 30%;  
  height: 46px;
`;

export const IconArrow = styled.div`
  color: #004e93;
  margin-left: 10px;
  font-size: 20px;
  position: absolute;
  right: 7%;
`;

export const FilterBox = styled.div`
  ${(props) => props.modal && css`
    background-color: #e5e5e5 !important;
    border: 0 !important;
    border-bottom-right-radius: 0 !important;
  `}
  width: 80%;
  background-color: transparent;
  border: 1px solid #ced1d5;
  padding: 10px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
`;

export const Text = styled.span`
  font-size: 14px;
  color: #2d2c2c;
`;

export const LinkStyled = styled(Link)`
  text-decorator: none;

`;

export const Menu = styled.span`
  font-size: 14px;
  background-color: #e5e5e5;
  padding: 0 10px 10px 10px;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 60%;
  right: 3.5%;
  z-index: 1;
  width: 159.56px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;

export const Item = styled.div`    
  color: #004e93; 
  margin-top: 3px; 
`;

export const Icon = styled.i`   
  margin-right: 10px;    
  
`;

export const IconArrowMenu = styled.i`   
  font-size: 20px;
  margin-left: 5px;   
  
`;
