import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { fetchSegments, fetchDirectories, loadStateByName } from '../../../../services/endpoints';
import FollowSegment from '../../../../components/FollowSegment';
import Search from '../../../../components/Forms/Elements/Search';
import {
  Card, Directories as DirectoriesStyled, Text, Button,
} from './styles';

const DirectoriesList = () => {
  const { t } = useTranslation(['home']);
  const [searchDirectory, setSearchDirectory] = useState('');
  // const [allStates, setAllStates] = useState('');
  const [directories, setDirectories] = useState([]);
  const [allConnectionsDirectory, setAllConnectionsDirectory] = useState([]);
  const [allConnections, setAllConnections] = useState([]);
  const history = useHistory();

  const loadConnections = () => {
    const connectionsDirectory = [];
    const connections = [];
    fetchSegments().then((response) => {
      response.data.filter((connection) => (connection.segment_iri !== null ? connectionsDirectory.push(connection.segment.id) : ''));
      response.data.filter((connection) => (connection.user2_iri !== null ? connections.push(connection.user2.id) : ''));
      setAllConnectionsDirectory(connectionsDirectory);
      setAllConnections(connections);
    }).catch((error) => {
      console.log(error);
    });
  };

  useEffect(() => {
    loadConnections();
  }, []);

  const getSearch = (value) => {
    setSearchDirectory(value);
  };

  // const loadStates = () => {
  //   getFieldValues().then((response) => {
  //     const resultAll = response.data.filter((item) => item.value === 'Todos');
  //     setAllStates(resultAll[0]);
  //   }).catch((error) => {
  //     console.log(error);
  //   });
  // };

  const loadSegments = (user) => {
    if (user) {
      const stateValue = user.name
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
      loadStateByName(stateValue).then((r) => {
        localStorage.setItem('directory-name', r.data[0].value);
        localStorage.setItem('directory', r.data[0].id);
        history.push({
          pathname: '/timeline',
          search: `?tab=0&filter=${r.data[0].id}`,
        });
      }).catch((error) => {
        console.error(error);
      });
    }
  };

  const loadDirectories = () => {
    const result = [];
    fetchDirectories().then((response) => {
      const resultAll = response.data.filter((item) => item.user.name === 'Diretório Nacional');
      const resultDirectories = response.data.filter((item) => item.user.name !== 'Diretório Nacional');
      result.push(resultAll[0]);
      result.push(...resultDirectories.sort((a, b) => {
        if (a.user.name > b.user.name) {
          return 1;
        }
        if (a.user.name < b.user.name) {
          return -1;
        }
        return 0;
      }));
      setDirectories(result);
    }).catch((error) => {
      console.log(error);
    });
  };

  useEffect(() => {
    loadDirectories();
  }, []);

  return (
    <DirectoriesStyled>
      <Row className="mb-4">
        <Col xs={12} sm={6}>
          <h1>{t('Directory')}</h1>
        </Col>
        <Col xs={12} sm={6}>
          <div className="form-search">
            <Search placeholder={t('Search by directory')} search={searchDirectory.toLowerCase()} handleGetSearch={getSearch} />
          </div>
        </Col>
      </Row>

      <Row>
        {/* <Col xs={6} sm={4}>
          <Card>
            <Image className="ml-1" src={BrazilFlag} alt="" />
            <Text>{t('All directories')}</Text>
            <Button
              to="/timeline?tab=1&filter=all"
              className="list-all"
            >
              {t('See posts')}
            </Button>
            <FollowSegment allSegments={allConnectionsDirectory} setAllSegments={setAllConnectionsDirectory} idAllSegments={allStates.id} segments />
          </Card>
        </Col> */}
        {Object.values(directories).filter((directory) => (searchDirectory !== ''
          ? directory.user.name.toLowerCase().match(searchDirectory.toLowerCase()) : directory)).map((st) => (
            <Col xs={6} sm={4} key={st.id}>
              <Card>
                <img className="ml-1" src={st.user.profile.hasOwnProperty('avatar') ? st.user.profile.avatar.file.urlPublica : ''} alt="" />
                <Text to={`profile/${st.user.id}`}>{st.user.name}</Text>
                <Button
                  // to={`/timeline?tab=0&filter=${st.user.id}`}
                  className="list-states"
                  onClick={() => loadSegments(st.user)}
                >
                  {t('See posts')}
                </Button>
                <FollowSegment
                  allUsers={allConnections}
                  setAllUsers={setAllConnections}
                  idUsers={st.user.id}
                  allSegments={allConnectionsDirectory}
                  setAllSegments={setAllConnectionsDirectory}
                  user={st.user}
                  users
                  segments
                />
              </Card>
            </Col>
        ))}
      </Row>
    </DirectoriesStyled>
  );
};

export default DirectoriesList;
