export const getUrl = (text) => {
  const regex = /((http|https):\/\/)?([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?/;
  const str = text;

  return (() => {
    let m;
    return (m = regex.exec(str)) !== null ? m[0] : null;
  });
};

export const getOptionsPreview = (url) => {
  if (url) {
    if (url.indexOf('yout') !== -1
      || url.indexOf('vimeo') !== -1
      || (url.indexOf('facebook') && url.indexOf('video') !== -1)
    ) {
      return {
        media: 'video',
        size: 'large',
      };
    } if (url.indexOf('spotify') !== -1) {
      return {
        media: 'audio',
      };
    }
  }
  return {};
};
