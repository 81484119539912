import styled from 'styled-components';
import { Link as LinkRouter } from 'react-router-dom';

export const Course = styled.section`
  position: relative;
  margin-bottom: 30px;
`;

export const Image = styled.img`
  max-width: 100%;
  width: 100%;
  height: 180px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

export const MetaInfo = styled.div`
  border: 1px solid #fff;
  background: #fff;
  border-top: 0;
  padding: 20px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;

export const Icon = styled.span`
  color: #004c94;
  font-size: 13px;
  margin-right: 15px;
  display: inline-block;
  font-family: 'Roboto', sans-serif !important;
  font-weight: normal !important;
  &.fas:before{
    font-weight: bold !important;
  }
  &:before{
    font-family: "Font Awesome 5 Pro";
    margin-right: 5px;
    font-size: 15px;
  }
`;

export const Title = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 1.5;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  margin-bottom: 15px;
  margin-top: 15px;
  height: 96px;
`;

export const Link = styled(LinkRouter)`
  display: block;ó
`;

export const Button = styled(LinkRouter).attrs({ className: 'btn btn-secondary' })``;
