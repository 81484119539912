/* eslint-disable no-useless-escape */
/* eslint-disable import/prefer-default-export */

export function createTextLinks(text) {
  return (text || '').replace(
    /([^\S]|^)(((https?\:\/\/)|(www\.))(\S+))/gi,
    (match, space, url) => {
      let hyperlink = url;
      if (!hyperlink.match('^https?:\/\/')) {
        hyperlink = `http://${hyperlink}`;
      }
      return `${space}<a href="${hyperlink}">${url}</a>`;
    },
  );
}
