import styled from 'styled-components';
import Wrap from './Wrap';
import { imageProxy } from '../../../utils';

const Image = styled(Wrap)`
  background-image: ${({imageUrl}) =>
  imageUrl ? `url('${imageProxy(imageUrl)}')` : ''};
`;

Image.defaultProps = {
  className: 'preview_card__media preview_card__media_image'
};

export default Image
