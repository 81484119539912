import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { Courses } from './styles';
import CourseItem from '../CourseItem';

const CoursesList = ({ courses }) => (
    <Courses>
      <Row>
        {courses.map((course) => (
          <Col xs={12} sm={4} key={course.id}>
            <CourseItem course={course} />
          </Col>
        ))}
      </Row>
    </Courses>
  );

CoursesList.propTypes = {
  courses: PropTypes.array.isRequired,
};

export default CoursesList;
