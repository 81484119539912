import styled, { keyframes } from 'styled-components';

export const Box = styled.div`
margin-bottom: 20px;    
  div{
    color: #004c94
    font-size: 15px;
    display: inline-block;   
    font-weight: bold;
    i{      
      font-size: 24px;
      margin-right: 10px;
      vertical-align: bottom;
      cursor: pointer;
    }
  }
`;

const pulse = keyframes`
  0%{
    transform: scale(1);
  }
  25%{
    transform: scale(1);
  }
  30%{
    transform: scale(1.4);
  }
  50%{
    transform: scale(1.2);
  }
  70%{
    transform: scale(1.4);
  }
  90%{
    transform: scale(1);
  }
  100%{
    transform: scale(1);
  }
`;

export const Like = styled.div`
  &.active i{
    animation: 1s ${pulse} linear;
  }
`;

export const Share = styled.div`

`;
