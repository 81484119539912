import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const AuthorInfo = styled.header`
  display: grid;
  grid-template-columns: 0.8fr 10fr 3fr;
  grid-template-areas: "AuthorAvatar AuthorInfo AuthorFollow";
  grid-gap: 10px;
  align-items: center;
  height: 80px;
`;

export const AuthorFollow = styled.div`
  width: 60%;
  margin-left: 25%;
`;

export const AvatarLink = styled(Link)`grid-area: AuthorAvatar;`;

export const Avatar = styled.img`
  width: 50px;
  border-radius: 50%;
  height: 50px;
  object-fit: cover;
`;

export const Info = styled.div`
  grid-area: AuthorInfo; 
`;

export const AuthorTitle = styled(Link)`
  &,
  &:hover {
    color: #2d2c2c;
  }
  font-size: 15px;
  font-weight: bold;
`;

export const AuthorOffice = styled.div`
  color: #777676;
  font-size: 12px;
`;

export const Date = styled.time`
  font-size: 12px;
  color: #bab8b8;
`;
