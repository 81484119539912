/* eslint-disable array-callback-return */
import { toSnakeCase } from './toSnakeCase';
import {
  loadFieldsAsync,
  loadFieldValuesAsync,
  updateFieldValues,
  updateUsers,
  loadFieldValuesCurriculum,
  updateField,
  loadUser,
  loadFieldById,
  loadFieldsCurriculum,
  updateFieldValuesCurriculum,
  removeFieldValuesCurriculum,
} from '../services/endpoints';
import States from '../assets/jsons/states';

const mapProfileFields = {
  name: 'nome',
  birth: 'nascimento',
  membership: 'filiacao',
  officer: 'cargo',
  category: 'categoria',
  voter_number: 'titulo_eleitoral',
  email: 'email',
  phone: 'telefone',
  address: 'logradouro',
  neighborhood: 'bairro',
  city: 'cidade',
  zipcode: 'cep',
  state: 'uf',
};

const updateValuesUserToProfile = (values) => {
  const user = JSON.parse(
    localStorage.getItem(`${process.env.REACT_APP_PREFIX}.user`),
  );
  const updateFields = {
    name: 'nome',
    email: 'email',
  };

  Object.keys(updateFields).forEach((field) => {
    Object.assign(values[updateFields[field]], { value: user[field] });
  });

  return values;
};

const createObjectFieldValues = (fields, fieldValues, isCurrentUser) => {
  const objectValues = {};
  fields.forEach((field) => {
    const key = toSnakeCase(field.title);
    const value = Object.keys(fieldValues).length > 0
      ? fieldValues.filter((val) => val.field.id === field.id)
      : {};

    Object.assign(objectValues, {
      [key]: {
        fieldId: field.id,
        value: value.length ? value[0].value : '',
        id: value.length ? value[0].id : null,
      },
    });
  });

  Object.values(objectValues).forEach((obj) => {
    if (obj.id === null) {
      delete obj.id;
    }
  });

  if (!isCurrentUser) {
    return objectValues;
  }
  return updateValuesUserToProfile(objectValues);
};


export const loadProfileFields = async (userIri, isCurrentUser = true) => {
  const fieldValues = await loadFieldValuesAsync(userIri)
    .then((res) => res.data)
    .catch(() => []);
  const fields = await loadFieldsAsync()
    .then((res) => res.data)
    .catch(() => []);

  return createObjectFieldValues(fields, fieldValues, isCurrentUser);
};


export const updateProfileFieldValues = async (oldValues, values, userId) => {
  const makeStateData = (stateData, value) => ({
    value,
    field: {
      id: stateData.field.id,
    },
    field_domain_value: {
      id: stateData.id,
    },
    user_iri: `/users/${userId}`,
  });

  const fieldsUpdate = [];

  Object.keys(mapProfileFields).forEach((key) => {
    if (key !== 'state' && key !== 'category') {
      fieldsUpdate.push({
        ...oldValues[mapProfileFields[key]],
        value: values[key],
      });
    }
  });


  const stateRequest = await loadFieldById(parseInt(values.state, 10));
  let stateData = null;

  if (stateRequest.data && stateRequest.data.length) {
    [stateData] = stateRequest.data;
  }

  const categoryRequest = await loadFieldById(parseInt(values.category, 10));
  let categoryData = null;

  if (categoryRequest.data && categoryRequest.data.length) {
    [categoryData] = categoryRequest.data;
  }

  const newState = States.find((state) => state.name === stateData.value);
  const alreadyInserted = oldValues[mapProfileFields.state].id;
  const alreadyInsertedCategory = oldValues[mapProfileFields.category].id || null;

  try {
    if (stateData) {
      await updateField(
        alreadyInserted,
        makeStateData(stateData, newState.abbr),
      );
    } if (categoryData) {
      await updateField(
        alreadyInsertedCategory,
        makeStateData(categoryData, categoryData.value),
      );
    }
    if (fieldsUpdate) {
      await updateFieldValues(fieldsUpdate);
    }
    await updateUsers(`/users/${userId}`, { name: values.name });
  } catch (e) {
    return e;
  }
};

export const updateLocalStorageUser = async () => {
  const setItem = (key, data) => new Promise((resolve) => {
    localStorage.setItem(key, data);
    setTimeout(() => resolve(true), 500);
  });

  try {
    const idxUser = `${process.env.REACT_APP_PREFIX}.user`;
    const me = await loadUser();
    await setItem(idxUser, JSON.stringify(me.data));
  } catch (e) {
    console.error(e);
  }
};

// curriculum functions (GET)

export const createObjectCurriculum = (fieldValues, detailIndex) => {
  const arrayCurriculumValues = [];

  detailIndex.forEach((index) => {
    const objectCurriculumValues = {};
    const value = Object.keys(fieldValues).length > 0
      ? fieldValues.filter((val) => val.detail_index === index)
      : {};

    value.map((item) => {
      const title = toSnakeCase(item.field.title);
      Object.assign(objectCurriculumValues,
        {
          [title]: item.value,
        });
    });
    arrayCurriculumValues.push(objectCurriculumValues);
  });
  return arrayCurriculumValues;
};

export const loadCurriculumFields = async (userIri) => {
  const indexArray = [];
  const fieldValues = await loadFieldValuesCurriculum(userIri)
    .then((response) => response.data)
    .catch(() => []);
  if (fieldValues.length > 0) {
    fieldValues.map((item) => (
      indexArray.push(item.detail_index)
    ));

    const detailIndex = [...new Set(indexArray)];

    return createObjectCurriculum(fieldValues, detailIndex);
  }

  return [];
};

// curriculum functions (POST)

export const submitCurriculum = (data) => {
  removeFieldValuesCurriculum().then((response) => {
    updateFieldValuesCurriculum(data);
  });
};

export const createCurriculumFields = async (values, fields) => {
  const fieldsUpdate = [];
  values.curriculum.map((item, index) => {
    Object.keys(item).map((key) => {
      const field = fields.filter((field) => toSnakeCase(field.title) === key);
      fieldsUpdate.push({
        fieldId: field[0].id,
        value: item[key],
        index,
      });
    });
  });
  submitCurriculum(fieldsUpdate);
};

export const handleCurriculumFields = async (values) => {
  const fields = await loadFieldsCurriculum()
    .then((response) => response.data)
    .catch(() => []);

  return createCurriculumFields(values, fields);
};
