import { followSegment, followConnection } from '../services/endpoints';


export const _handleFollow = (allConnections, idSegment, idUser) => {

  if (!allConnections.includes(idSegment)) {
    followSegment(idSegment);
    followConnection(idUser);
  }
};
