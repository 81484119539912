import styled from 'styled-components';

const Curriculum = styled.div`
  margin-bottom: 30px;
  padding: 20px 80px;
  
`;

export const Line = styled.div`
  border-bottom: 1px solid #dddfe0;
  margin-bottom: 20px;
`;

const Item = styled.div`
  margin-bottom: 7px;
  display: flex;
  flex-direction: row;
  margin-left: -15px;
  margin-right: -15px;
  align-items: end;
  position: relative;
`;

const Title = styled.div`
  width: 33.33333%;
  padding-left: 15px;
  padding-right: 15px;
  font-weight: bold;
  font-size: 16px;
  position: relative;
  &:before{
    content: '';
    width: 8px;
    height: 8px;
    background: #f19800;
    display: inline-block;
    margin-right: 7px;
    border-radius: 50%;
    margin-bottom: 2px;
  }
`;

const Date = styled.div`
  width: 33.33333%;
  margin-top: 2px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
  font-weight: bold;
`;


export {
  Curriculum, Date, Item, Title,
};
