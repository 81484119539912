/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import React, { useEffect, useState, useContext } from 'react';
import {
  Button, Modal, ModalBody, ModalFooter,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { store } from 'react-notifications-component';
import { getCurrentUser } from '../../../../services/endpoints';
import HandleRefreshContext from '../handleRefreshContext';
import api from '../../../../services/api';
import { Box, Share as ShareStyled } from './styles';
import settings from '../../../../services/settings';

const Share = ({ post }, props) => {
  const { t } = useTranslation('timeline');
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const { className } = props;
  const user = getCurrentUser();
  const [UF, setUF] = useState('');
  const userUF = user.profile.uf
    ? user.profile.uf.value
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
    : '';
  const { handleRefreshPosts } = useContext(HandleRefreshContext);

  const loadUserUF = () => {
    api
      .get(`/general/field_domain_values?match[value]=${userUF}`)
      .then((response) => {
        setUF(response.data[0]);
      });
  };

  useEffect(() => {
    loadUserUF();
  }, []);

  async function handleSubmit(event) {
    event.preventDefault();
    const postId = post.shared_post !== null ? post.shared_post.id : post.id;
    try {
      const response = await api.post('/social/posts', {
        user_iri: `/users/${user.id}`,
        post_segmentations: [
          { segment_iri: `/general/field_domain_values/${UF.id}` },
        ],
        description: post.description,
        shared_post: {
          id: postId,
        },
      });

      if (post.type === 'FILE') {
        console.log('file');
        await api.put(`/social/posts/${response.data.id}`, {
          type: 'FILE',
          file: {
            id: post.file.id,
          },
        });
      } else if (post.type === 'VIMEO_MEDIA') {
        console.log('vimeo_media');
        await api.put(`/social/posts/${response.data.id}`, {
          type: 'VIMEO_MEDIA',
          vimeo_media_reference: post.vimeo_media_reference,
        });
      }
    } catch (err) {
      store.addNotification({
        ...settings.notifications,
        message: t('notifications.danger.insert_media', err),
        type: 'danger',
      });
    }
    handleRefreshPosts(0, true);
    toggle();
  }

  return (
    <Box>
      <ShareStyled>
        <i className="fas fa-share" onClick={toggle} />
        {t('share')}
      </ShareStyled>

      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalBody>{t('share_post')}</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSubmit}>
            {t('share')}
          </Button>
          <Button color="secondary" onClick={toggle}>
            {t('cancel')}
          </Button>
        </ModalFooter>
      </Modal>
    </Box>
  );
};

Share.propTypes = {
  post: PropTypes.object.isRequired,
};

export default Share;
