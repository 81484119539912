import React from 'react';

import { Container, Col, Row } from 'reactstrap';
import Header from './Header';
import Footer from './Footer';
import ColumnRight from './ColumnRight';
import Main from './Main';

const Base = (props) => (
  <>
    <Header />

    <div className="box-content">
      <Container>
        <Row className="align-items-start">
          <Col xs={12} sm={3}>
            {props.blocks !== undefined && props.blocks.length && props.blocks.map((block, idx) => (
              <ColumnRight key={idx} name={block.name} data={block.data} />
            ))}
          </Col>
          <Col xs={12} sm={9}>
            <Main {...props} />
          </Col>
        </Row>
      </Container>
    </div>

    <Footer />
  </>
);

export default Base;
