/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { setEvaluation, getCurrentUser } from '../../../../services/endpoints';
import HandleRefreshContext from '../handleRefreshContext';
import { Box, Like as LikeStyled } from './styles';

const Like = ({ post, updateLikeCount }) => {
  const { t } = useTranslation('timeline');
  const [like, setLike] = useState(false);
  const user = getCurrentUser();

  useEffect(() => {
    const result = post.evaluations.filter((item) => (item && item.type === 'LIKE'));
    setLike(result.length > 0 ? !!result[0].userEvaluationValue : false);
  }, []);

  function handleLike() {
    setEvaluation(
      1, 'LIKE', `/social/posts/${post.id}`, `/users/${user.id}`,
    )
      .then(() => {
        updateLikeCount(like);
        setLike((prevState) => !prevState);
      })
      .catch(() => {
        setLike((prevState) => prevState);
      });
  }

  return (
    <Box>
      <HandleRefreshContext.Provider value={{ like }}>
        <LikeStyled onClick={handleLike} className={!like || 'active'}>
          <i className={`fa${like ? 's' : 'r'} fa-thumbs-up`} />
          {t('liked')}
        </LikeStyled>
      </HandleRefreshContext.Provider>

    </Box>
  );
};

Like.propTypes = {
  post: PropTypes.object.isRequired,
  updateLikeCount: PropTypes.func,
};

export default Like;
