/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { loadUsersLike } from '../../../../../../services/endpoints';
import {
  Box,
  Likes,
  Text,
  IconLike,
  IconClose,
  User,
  BoxBody,
} from './styles';
import LikeUser from '../LikeUser';

const LikeToggle = ({ post, toggle }) => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation('common');

  const handleUsers = () => {
    setLoading(true);
    loadUsersLike(post.id, 'LIKE')
      .then((response) => {
        setUsers(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    handleUsers();
    return () => {
      setUsers([]);
    };
  }, []);


  return (
    <Box>
      <Likes>
        <IconLike className="far fa-thumbs-up" />
        <Text>{t('likes')}</Text>
        <IconClose onClick={toggle} className="far fa-times-circle" />
      </Likes>
      <div>
        {loading === true ? (
          <div className="text-center">
            <Spinner color="dark" />
          </div>
        )
          : (
            <BoxBody>
              <div>
                {users.length > 0
                  ? (
                    <div>
                      {users.map((user) => (
                        <User key={user.id}>
                          <LikeUser evaluation={user} />
                        </User>
                      ))}
                    </div>
                  )
                  : <div>{t('no_records')}</div>}
              </div>

            </BoxBody>
          )}
      </div>
    </Box>
  );
};

LikeToggle.propTypes = {
  post: PropTypes.object.isRequired,
  toggle: PropTypes.func.isRequired,
};
export default LikeToggle;
