/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import React, {
  useContext, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button, Modal, ModalBody, ModalFooter, Row,
} from 'reactstrap';
import { store } from 'react-notifications-component';
import NoAvatar from '../../../assets/images/no-avatar.png';
import api from '../../../services/api';
import {
  getCurrentUser, loadAvatar,
} from '../../../services/endpoints';
import settings from '../../../services/settings';
import PreviewCard from '../../PreviewCard';
import { getOptionsPreview, getUrl } from '../utils';
import WriteComments from '../WriteComments';
import Comments from './Comments';
import Follow from '../../Follow';
import HandleRefreshContext from './handleRefreshContext';
import Like from './Like';
import LikesAndComments from './LikesAndComments';
import Share from './Share';
import { createTextLinks } from '../../../utils/createTextLinks';
import {
  AuthorInfo,
  AuthorInfoShared,
  AuthorOffice,
  AuthorTitle,
  Avatar,
  AvatarLink,
  Comment,
  Date,
  DeleteButton,
  Image,
  ImageAdjust,
  Info,
  Post,
  Tag,
  IconClose,
  Grid,
  PostFooter,
  CommentBox,
  LikeBox,
  Column,
  CommentsBox,
  ButtonFollowContainer,
} from './styles';

const MediaContent = ({ post }) => {
  const mediaContent = () => {
    switch (post.type) {
      case 'VIMEO_MEDIA':
        return (
          <div className="embed-responsive embed-responsive-16by9">
            <iframe src={`https://player.vimeo.com/video/${post.vimeo_media_reference}`} title={post.vimeo_media_reference} frameBorder="0" allowFullScreen />
          </div>
        );
      case 'FILE':
        return (
          <img className="img-fluid" alt="imagem do post" src={post.file.urlPublica} />
        );
      default:
        return false;
    }
  };

  if (post.type === 'FILE' || post.type === 'VIMEO_MEDIA') {
    return (
      <Image>
        {mediaContent()}
      </Image>
    );
  }

  return <></>;
};


const PostItem = ({
  post, allUsers, setAllUsers, allSegments, setAllSegments,
}) => {
  const { t } = useTranslation(['timeline']);
  const [previewUrl, setPreviewUrl] = useState(null);
  const user = getCurrentUser();
  const userUF = post.post_segmentations[0] ? post.post_segmentations[0].segment.value : '';
  const [comments, setComments] = useState([]);
  const [commentsParents, setCommentsParents] = useState([]);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [isOpen, setIsOpen] = useState(false);
  const [likeCount, setLikeCount] = useState(0);
  const [avatar, setAvatar] = useState([]);
  const { handleRefreshPosts } = useContext(HandleRefreshContext);
  const commentIdFromURL = window.location.pathname.match('/comment/') ? window.location.pathname.match('(/comment/).*') : undefined;
  const commentId = commentIdFromURL ? commentIdFromURL[0].replace('/comment/', '') : '';

  function handleAvatar() {
    loadAvatar(post.user.id).then((response) => {
      setAvatar(response.data);
    }).catch((error) => {
      store.addNotification({
        ...settings.notifications,
        message: t('notifications.danger.insert_image', error),
        type: 'danger',
      });
    });
  }

  useEffect(() => {
    if (post.hasOwnProperty('user') === true) {
      handleAvatar();
    }
  }, []);

  useEffect(() => {
    post.evaluations.map((evaluation) => {
      if (evaluation.type === 'LIKE') {
        setLikeCount(evaluation.count);
      }
      return null;
    });
  }, []);

  function updateLikeCount(like) {
    setLikeCount((prevState) => (!like ? prevState + 1 : prevState - 1));
  }

  function handleRefreshComments() {
    api
      .get('/general/comments', {
        params: {
          parent_iri: `/social/posts/${post.id}`,
        },
      })
      .then((response) => {
        setComments(response.data || []);
      });
  }


  useEffect(() => {
    handleRefreshComments();
  }, []);

  function handleRefreshCommentsParents() {
    api
      .get('/general/comments?exists[parent]=false&order[created_at]=desc', {
        params: {
          parent_iri: `/social/posts/${post.id}`,
        },
      })
      .then((response) => {
        setCommentsParents(response.data || []);
      });
  }

  useEffect(() => {
    handleRefreshCommentsParents();
  }, []);

  useEffect(() => {
    setPreviewUrl(getUrl(post.description));
    return () => {
      setPreviewUrl(null);
    };
  }, []);

  const handleDeletePost = (id, description, e) => {
    e.preventDefault();
    api
      .delete(`/social/posts/${id}`)
      .then(() => {
        handleRefreshPosts(0, true);
      })
      .catch((error) => {
        console.log(error);
      });
    handleRefreshPosts(0, true);
    toggle(e);
  };

  const loadConnections = () => {
    api.get(`/social/connections?user_iri=/users/${user.id}&user2_iri=/users/${post.user.id}`).then((response) => {
    }).catch((error) => {
      console.log(error);
    });
  };

  useEffect(() => {
    if (commentId !== '') {
      setIsOpen(true);
    }
    if (post.hasOwnProperty('user') === true) {
      loadConnections();
    }
  }, []);

  if (post.hasOwnProperty('user') !== true) {
    return <></>;
  }

  return (
    <>
      <Post>
        {post.user.id === user.id
          ? (
            <div>
              <DeleteButton onClick={toggle}>
                <IconClose className="far fa-times-circle" />
              </DeleteButton>

              <Modal isOpen={modal} toggle={toggle}>
                <ModalBody>{t('delete_post')}</ModalBody>
                <ModalFooter>
                  <Button
                    color="primary"
                    onClick={(event) => handleDeletePost(post.id, post.description, event)}
                  >
                    {t('delete')}
                  </Button>
                  <Button color="secondary" onClick={toggle}>
                    {t('cancel')}
                  </Button>
                </ModalFooter>
              </Modal>
            </div>

          )
          : <></>}

        <AuthorInfo>

          <AvatarLink to={`/profile/${post.user.id}`}>
            {avatar[0] && avatar[0].file ? (
              <Avatar src={avatar[0].file.urlPublica} />

            ) : (
              <Avatar src={NoAvatar} />
            )}

          </AvatarLink>
          <Info>
            <AuthorTitle to={`/profile/${post.user.id}`}>
              {post.user.name}
            </AuthorTitle>
            <AuthorOffice>
              {post.user.profile.hasOwnProperty('cargo') ? post.user.profile.cargo.value : ''}
              {post.user.profile.hasOwnProperty('cidade') ? ` | ${post.user.profile.cidade.value}` : ''}

            </AuthorOffice>
            <Date>{post.created_at}</Date>
          </Info>

          {user.id !== post.user.id && (
          <ButtonFollowContainer>
            <Follow
              allUsers={allUsers}
              setAllUsers={setAllUsers}
              idUsers={post.user.id}
              allSegments={allSegments}
              setAllSegments={setAllSegments}
              user={post.user}
            />
          </ButtonFollowContainer>
          )}

        </AuthorInfo>

        <Tag>
          {`UF: ${userUF}`}
        </Tag>

        {post.hasOwnProperty('shared_post') && post.shared_post !== null && (
        <AuthorInfoShared>

          <AvatarLink to={`/profile/${post.shared_post.user.id}`}>
            {post.shared_post.user.profile.hasOwnProperty('avatar') && post.shared_post.user.profile.avatar.file ? (
              <Avatar src={post.shared_post.user.profile.avatar.file.urlPublica} />

            ) : (
              <Avatar src={NoAvatar} />
            )}

          </AvatarLink>
          <Info>
            <AuthorTitle to={`/profile/${post.shared_post.user.id}`}>
              {post.shared_post.user.name}
            </AuthorTitle>
            <AuthorOffice>
              {post.shared_post.user.profile.hasOwnProperty('cargo') ? post.shared_post.user.profile.cargo.value : ''}
              {post.shared_post.user.profile.hasOwnProperty('cidade') ? ` | ${post.shared_post.user.profile.cidade.value}` : ''}
            </AuthorOffice>
            <Date>{post.shared_post.created_at}</Date>
          </Info>


        </AuthorInfoShared>
        )}


        <Comment dangerouslySetInnerHTML={{ __html: createTextLinks(post.description) }} />
        {previewUrl !== null && (
        <PreviewCard {...getOptionsPreview(previewUrl)} url={previewUrl} />
        )}

        <ImageAdjust className="no-image">
          <MediaContent post={post} />
          <Grid>
            <Share post={post} />
            <HandleRefreshContext.Provider value={{
              allUsers, setAllUsers, allSegments, setAllSegments,
            }}
            >
              <LikesAndComments post={post} likeCount={likeCount} commentsCount={comments.length} />
            </HandleRefreshContext.Provider>
          </Grid>
        </ImageAdjust>

      </Post>

      <PostFooter>
        <Row className="text-center">
          <Column xs={12} md={6}>
            <LikeBox>
              <Like post={post} updateLikeCount={updateLikeCount} />
            </LikeBox>
          </Column>

          <Column xs={12} md={6}>
            <CommentBox id="commentbox" onClick={() => setIsOpen((prevState) => !prevState)}>
              <i className="far fa-comment-alt" />
              {t('comment')}
            </CommentBox>
          </Column>
        </Row>

        <HandleRefreshContext.Provider value={{ handleRefreshCommentsParents, handleRefreshComments }}>
          {isOpen && (
            <CommentsBox>
              <WriteComments post={post} />

              <Comments comments={commentsParents} post={post} />
            </CommentsBox>
          )}


        </HandleRefreshContext.Provider>
      </PostFooter>
    </>
  );
};


PostItem.propTypes = {
  post: PropTypes.object.isRequired,
  allUsers: PropTypes.array,
  setAllUsers: PropTypes.func,
  allSegments: PropTypes.array,
  setAllSegments: PropTypes.func,
};

export default PostItem;
