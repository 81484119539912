import styled from 'styled-components';

export const Box = styled.div`
  padding: 20px;
`;

export const BoxBody = styled.div`
  height: fit-content;
  max-height: 325px;
  overflow: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar-track {
    background-color: #eee;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  &::-webkit-scrollbar {
    border-radius: 10px;
    width: 12px;
    background-color: #eee;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #004c94;
  }
`;


export const User = styled.div`
  border-top: 1px solid #d3d3d3;
  &:last-child {
    border-bottom: 1px solid #d3d3d3;
  }
`;

export const Text = styled.div`
  font-weight: bold;
  margin-left: 5px;
`;

export const IconLike = styled.i`
  line-height: 1.5 !important;
`;

export const IconClose = styled.i`
  line-height: 1.5 !important;
  cursor: pointer;
`;

export const Likes = styled.div`
  color: #004c94;
  font-size: 20px;
  display: grid;
  grid-template-columns: min-content auto max-content;
  font-weight: bold;
  margin-bottom: 15px;
`;
