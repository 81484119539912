/* eslint-disable eqeqeq */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { store } from 'react-notifications-component';
import Follow from '../Follow';
import {
  Address,
  Avatar,
  AvatarContainer,
  AvatarFrame, ButtonFollowContainer,
  MetaInfo,
  Name,
  Office,
  Profile,
  UpdatePhoto,
} from './styles';
import {
  getCurrentUser, loadAvatar,
} from '../../services/endpoints';
import { loadProfileFields } from '../../utils/profileInfo';
import settings from '../../services/settings';
import api from '../../services/api';

const ProfileBar = ({
  slug, allUsers, setAllUsers, allSegments, setAllSegments,
}) => {
  const { t } = useTranslation();
  const currentUser = getCurrentUser();
  const [avatar, setAvatar] = useState(null);
  const [avatarImage, setAvatarImage] = useState([]);
  const [data, setData] = useState({});
  const preview = useMemo(() => (avatar ? URL.createObjectURL(avatar) : null),
    [avatar]);
  const [isCurrentUser, setIsCurrentUser] = useState(false);
  const intSlug = parseInt(slug);

  const loadData = async () => {
    const profileFields = await loadProfileFields(`/users/${slug || currentUser.id}`, false);
    setData(profileFields);
  };

  useEffect(() => {
    loadAvatar(slug || currentUser.id).then((response) => {
      setAvatarImage(response.data);
    }).catch((error) => {
      store.addNotification({
        ...settings.notifications,
        message: t('notifications.danger.insert_image', error),
        type: 'danger',
      });
    });
  }, []);

  useEffect(() => {
    if (currentUser.id === intSlug) {
      setIsCurrentUser(true);
    } else if (intSlug == undefined) {
      setIsCurrentUser(true);
    }
    loadData();
    return () => {
      setData({});
    };
  }, []);

  const createAvatar = (event) => {
    const fileImage = event.target.files[0];
    setAvatar(fileImage);
    handleAvatar(fileImage);
  };

  const handleAvatar = async (fileImage) => {
    const profileFields = await loadProfileFields(`/users/${currentUser.id}`, false);
    setData(profileFields);
    if (Object.values(profileFields).length) {
      if (profileFields.hasOwnProperty('avatar') && profileFields.avatar.id) {
        handleSubmit(profileFields.avatar.id, fileImage);
      } else {
        const { fieldId } = profileFields.avatar;
        createFieldValuesBatch(fieldId, fileImage);
      }
    }
  };

  const createFieldValuesBatch = (fieldId, fileImage) => {
    api.post('/general/fieldValuesBatch', [{
      fieldId,
      value: '',
    }]).then((response) => {
      loadAvatar(currentUser.id).then((response) => {
        handleSubmit(response.data[0] ? response.data[0].id : undefined, fileImage);
      }).catch((error) => {
        store.addNotification({
          ...settings.notifications,
          message: t('notifications.danger.insert_image', error),
          type: 'danger',
        });
      });
    }).catch((error) => {
      store.addNotification({
        ...settings.notifications,
        message: t('notifications.danger.insert_image', error),
        type: 'danger',
      });
    });
  };

  const handleSubmit = (id, avatar) => {
    const data = new FormData();
    data.append('file', avatar);
    api.post(`/general/field_values/${id}/upload/file`, data).then((response) => {

    }).catch((error) => {
      store.addNotification({
        ...settings.notifications,
        message: t('notifications.danger.insert_image', error),
        type: 'danger',
      });
    });
  };

  return (
    <Container>
      <Profile>
        <AvatarContainer>
          <AvatarFrame>
            {avatarImage[0] && <Avatar src={preview || avatarImage[0].file.urlPublica} /> }
            {!avatarImage[0] && preview && <Avatar src={preview} />}
            {slug === isCurrentUser.id && (
            <UpdatePhoto>
              <form onSubmit={handleSubmit}>
                <input type="file" onChange={(event) => createAvatar(event)} />
                <i className="fas fa-camera" />
              </form>
            </UpdatePhoto>
            )}
          </AvatarFrame>


        </AvatarContainer>

        <MetaInfo>
          <Address>{`${data.cidade && data.cidade.value !== '' ? `${data.cidade.value} /` : ''}  ${data.uf ? data.uf.value : ''}`}</Address>
          <Name>{data.nome ? data.nome.value : ''}</Name>
          <Office>{data.cargo ? data.cargo.value : ''}</Office>
        </MetaInfo>


        { isCurrentUser === false && slug != undefined && (
          <ButtonFollowContainer>
            <Follow
              allUsers={allUsers}
              setAllUsers={setAllUsers}
              idUsers={intSlug}
              allSegments={allSegments}
              setAllSegments={setAllSegments}
              slug={slug}
            />
          </ButtonFollowContainer>
        )}
      </Profile>
    </Container>
  );
};

ProfileBar.propTypes = {
  slug: PropTypes.string,
  allUsers: PropTypes.array,
  setAllUsers: PropTypes.func,

};

export default ProfileBar;
