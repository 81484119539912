/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Mention } from 'react-mentions';
import { store } from 'react-notifications-component';
import { Progress as ProgressStyle } from 'reactstrap';
import { getMentions } from '../../../utils/getMentions';
import {
  ButtonSecondary,
  Image,
  MentionsInputStyled,
  Section,
  TextAreaBody,
  AvatarLink,
  Preview,
  PreviewImage,
  PreviewDescription,
  PreviewImageRemoveIcon,
  PreviewProgressBar,
} from './styles';
import { Percentage } from '../../../pages/CoursesShow/Playlist/Progress/styles';
import defaultMentionStyle from '../utils/defaultMentionStyle';
import api from '../../../services/api';
import { getCurrentUser, loadAvatar } from '../../../services/endpoints';
import HandleRefreshContext from '../PostItem/handleRefreshContext';
import settings from '../../../services/settings';
import NoAvatar from '../../../assets/images/no-avatar.png';
import { submitMentionAndNotification } from '../../../utils/submitMentionsAndNotifications';
import video from '../../../assets/images/video.svg';
import { processVideo, sendImage } from '../../../utils/media';
import { getToken } from '../../../services/auth';

const WriteComments = ({
  _handleSubmitComment,
  replyId,
  post,
  ...props
}) => {
  const { t } = useTranslation('timeline');
  const user = getCurrentUser();
  const { handleRefreshCommentsParents } = useContext(HandleRefreshContext);
  const { handleRefreshComments } = useContext(HandleRefreshContext);

  const [type, setType] = useState(undefined);
  const [avatar, setAvatar] = useState([]);
  const [file, setFile] = useState(null);
  const [fileUploadPercentage, setFileUploadPercentage] = useState(0);
  const [thumbnail, setThumbnail] = useState(null);
  const [uploading, setUploading] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const inputRef = useRef(undefined);
  const [comment, setComment] = useState('');

  function handleAvatar() {
    loadAvatar(user.id).then((response) => {
      setAvatar(response.data);
    }).catch((error) => {
      store.addNotification({
        ...settings.notifications,
        message: t('notifications.danger.insert_image', error),
        type: 'danger',
      });
    });
  }

  useEffect(() => {
    handleAvatar();
  }, []);

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  // useEffect(() => {
  //   loadUsers().then((response) => {
  //     setUsers(mapToMentionsLib(response.data.filter((item) => item.id !== getCurrentUser().id)));
  //   });
  //   return () => {
  //     setUsers([]);
  //   };
  // }, []);

  useEffect(() => {
    if (props.focus) {
      inputRef.current.focus();
    }
  }, [props.focus]);

  const handleMedia = (event) => {
    if (disabled && file) return;
    if (!event.target.files.length) return;
    const fileType = event.target.files[0].type;
    if (fileType.startsWith('image')) {
      setType('IMAGE');
      setThumbnail(URL.createObjectURL(event.target.files[0]));
    } else if (fileType.startsWith('video')) {
      setType('VIDEO');
      setThumbnail(video);
    } else {
      store.addNotification({
        ...settings.notifications,
        message: t('notifications.danger.invalid_file_type'),
        type: 'danger',
      });
    }
    setFile(event.target.files[0]);
    setDisabled(false);
  };

  function handleMediaRemove() {
    if (disabled && file) return;
    document.getElementById(`uploadFile${post.id}${replyId || ''}`).value = '';
    // document.getElementById(`uploadFile${post.id}`).value = '';
    setType('post');
    setFile(null);
    setDisabled(false);
    setThumbnail(null);
    setUploading(false);
  }

  const handleSubmitComment = async (postId, commentDescription, parent = null) => {
    if (commentDescription.length || type === 'IMAGE') {
      const response = await api({
        method: 'post',
        url: '/general/comments',
        data: {
          parent: parent === null ? null : { id: parent },
          description: comment,
          parent_iri: `/social/posts/${postId}`,
          user_iri: `/users/${user.id}`,
        },
      });
      if (type === 'IMAGE') {
        // sendImageaa(response.data.id);
        await sendImage(`/general/comments/${response.data.id}`, file);
      } else if (type === 'VIDEO') {
        setUploading(true);
        await processVideo(file, setFileUploadPercentage, getToken(), `/general/comments/${response.data.id}`);
        setFileUploadPercentage(0);
        setUploading(false);
      }

      if (comment.indexOf('href="/profile/') !== -1) {
        submitMentionAndNotification(postId, comment, 'comentário', response.data.id);
      }
      handleRefreshCommentsParents();
      handleRefreshComments();
      handleMediaRemove();
    } else {
      store.addNotification({
        ...settings.notifications,
        message: t('write_comments.write_a_comment'),
        type: 'danger',
      });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    handleSubmitComment(post.id, event.target.value, event.target, replyId);
  };

  const handleKeyPress = (event) => {
    if (!event.shiftKey && (event.charCode === 13 || event.keyCode === 13)) {
      event.preventDefault();
      handleSubmitComment(post.id, event.target.value, replyId);
      setComment('');
    }
  };

  return (
    <Section>
      {thumbnail && uploading && (
        <Preview>
          <PreviewProgressBar className="">
            <div>
              <Percentage>
                {`${fileUploadPercentage}%`}
              </Percentage>
            </div>
            <div>
              <ProgressStyle value={fileUploadPercentage} />
            </div>
          </PreviewProgressBar>
        </Preview>
      )}
      {thumbnail && !uploading && (
        <Preview>
          <PreviewImageRemoveIcon className="fas fa-window-close" onClick={() => handleMediaRemove()} />
          <PreviewImage src={thumbnail} />
          {file && (<PreviewDescription>{`${t('filename')}${file.name}`}</PreviewDescription>)}
        </Preview>
      )}
      <AvatarLink to={`/profile/${user.id}`}>
        {avatar[0] ? (
          <Image src={avatar[0].file.urlPublica} />
        ) : (
          <Image src={NoAvatar} />
        )}
      </AvatarLink>

      <TextAreaBody>
        <form method="POST" onSubmit={handleSubmit}>
          <input type="hidden" name="replyId" value={replyId} />

          <MentionsInputStyled
            value={comment}
            onChange={handleCommentChange}
            name="comment"
            onKeyPress={handleKeyPress}
            placeholder={t('write_comments.write_a_comment')}
            ignoreAccents
            style={defaultMentionStyle}
            inputRef={inputRef}
          >
            <Mention
              trigger="@"
              data={getMentions}
              className="mentionedFriend"
              markup='<a href="/profile/__id__">@__display__</a>'
            />
          </MentionsInputStyled>
        </form>
        <ButtonSecondary>
          <input type="file" id={`uploadFile${post.id}${replyId || ''}`} onChange={(event) => handleMedia(event)} />
          <i className="far fa-images" />
          {t('write_comments.media')}
        </ButtonSecondary>
      </TextAreaBody>
    </Section>
  );
};

WriteComments.propTypes = {
  replyId: PropTypes.number,
  post: PropTypes.object,
  _handleSubmitComment: PropTypes.func,
};

export default WriteComments;
