import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Spinner from 'reactstrap/es/Spinner';
import { Comments as CommentsStyled, ShowComments } from './styles';
import Comment from '../Comment';

const Comments = ({ comments, post }) => {
  const { t } = useTranslation('timeline');
  const [loadedAll, setLoadedAll] = useState(false);
  const first = comments.length ? comments.slice(0, 1) : [];
  const rest = comments.length > 1 ? comments.slice(1) : [];
  const [loading, setLoading] = useState(false);
  const commentIdFromURL = window.location.pathname.match('/comment/') ? window.location.pathname.match('(/comment/).*') : undefined;
  const commentId = commentIdFromURL ? commentIdFromURL[0].replace('/comment/', '') : '';

  const handleFindComment = (commentShow) => {
    commentShow.scrollIntoView({
      behavior: 'instant',
      block: 'center',
    });
  };

  const handleShowComments = () => {
    setLoading(true);
    // loadComments();
    setLoadedAll(true);
    setLoading(false);
  };

  useEffect(() => {
    if (commentId !== '') {
      setLoadedAll(true);
      const time = setInterval(() => {
        if (document.getElementById(`comment-${commentId}`) !== null) {
          handleFindComment(document.getElementById(`comment-${commentId}`));
          clearInterval(time);
        }
      }, 1000);
    }
  }, [commentId]);

  return (
    <CommentsStyled>

      {first.length > 0 && first.map((comment, idx) => (
        <Comment comment={comment} key={idx} post={post} mentionedComment={commentId} />
      ))}

      {/* {rest.length > 0 && ( */}
      {rest.length > 0 && (
        <div>
          {loadedAll === false && (
            <ShowComments onClick={handleShowComments}>
              {t('loading_comments', { number: rest.length })}
              <i className="fas fa-caret-right" />
            </ShowComments>
          )}

          {loading === true && (<div className="text-center mt-30 mb-30"><Spinner color="dark" /></div>)}

          {loadedAll === true && rest.map((comment, idx) => (
            <Comment comment={comment} key={idx} post={post} mentionedComment={commentId} />
          ))}
        </div>
      )}

    </CommentsStyled>
  );
};

Comments.propTypes = {
  comments: PropTypes.array.isRequired,
  post: PropTypes.object,
};

export default Comments;
