/* eslint-disable no-nested-ternary */
/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Row, Col, Spinner } from 'reactstrap';
import Select from 'react-select';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import Base from '../../components/Theme/base';
import Search from '../../components/Forms/Elements/Search';
import { colourStyles } from '../../utils/colourStyles.js';
import {
  TabsCustom, TabCustom, TabPanelCustom, Text,
} from './styles';
import {
  loadUsers, fetchConnections, loadCategories, loadLeadership, handleUsersCategories,
} from '../../services/endpoints';
import UsersList from '../../components/Users/UsersList';

const LeadershipAndFollow = () => {
  const { t } = useTranslation('common');
  const params = new URLSearchParams(window.location.search);
  const tab = parseInt(params.get('tab'), 10) || 0;
  const { promiseInProgress } = usePromiseTracker();
  const [searchLeadership, setSearchLeadership] = useState('');
  const [searchConnections, setSearchConnections] = useState('');
  const [leadership, setLeadership] = useState([]);
  const [allConnections, setAllConnections] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loadingItem, setLoadingItem] = useState(false);
  const itemsPerPage = 18;
  const [numCurrentItens, setNumCurrentItens] = useState(itemsPerPage);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedOption, setSelectedOption] = useState({
    value: 'all',
    label: t('all'),
  });
  const history = useHistory();
  const handleChange = (event, newValue) => {
    history.push({
      pathname: history.location.pathname,
      search: `&tab=${newValue}`,
    });
  };

  const loadCategory = async () => {
    const options = [{
      value: 'all',
      label: t('all'),
    }];
    await loadCategories().then((response) => {
      response.data.map((category) => (
        options.push({
          value: category.id,
          label: category.value,
        })
      ));
    }).catch((error) => {
      console.error(error);
    });
    await setCategories(options || []);
  };

  const handleLeadership = async (filter = {}, pageNumber = currentPage, shouldRefresh = false) => {
    setLoadingItem(true);
    const users = [];
    let result = '';
    const leaders = [];
    const usersCategories = [];

    await loadUsers({
      name: searchLeadership || '',
    })
      .then((response) => {
        const usersResult = [];
        Object.values(response.data).map((item) => users.push(item));
        Object.values(response.data).map((item) => usersResult.push(item.id));
        Object.values(usersResult).map((item, index) => {
          if (index === usersResult.length - 1) {
            result += `'/users/${item}'`;
          } else {
            result += `'/users/${item}'|`;
          }
        });
      })
      .catch((error) => {
        console.error(error);
      });

    await handleUsersCategories({
      page: pageNumber,
      perPage: itemsPerPage,
      'match[field_domain_value.value]': filter.label,
    }).then((response) => {
      Object.values(response.data).map((item) => usersCategories.push(item.user));
    }).catch((error) => {
      console.error(error);
    });

    await loadLeadership({
      page: pageNumber,
      perPage: itemsPerPage,
      'wherein[user_iri]': result,
    })
      .then((response) => {
        Object.values(response.data).map((item) => leaders.push(item.user));
      })
      .catch((error) => {
        console.error(error);
      });

    if (Object.values(filter).length > 0 && filter.value !== 'all') {
      const response = users.filter((o1) => usersCategories.some((o2) => o1.id === o2.id));
      setLeadership((old) => (shouldRefresh ? response : [...old, ...response]));
      setNumCurrentItens(response.length);
      setCurrentPage(pageNumber + 1);
    } else if (result === '') {
      setLeadership([]);
      setNumCurrentItens(0);
    } else {
      setLeadership((old) => (shouldRefresh ? leaders : [...old, ...leaders]));
      setNumCurrentItens(leaders.length);
      setCurrentPage(pageNumber + 1);
    }
    setLoadingItem(false);
  };

  const handleSearchConnections = async (pageNumber = currentPage, shouldRefresh = false) => {
    setLoadingItem(true);
    let result = [];
    const connections = [];

    if (searchConnections) {
      await loadUsers({
        name: searchConnections,
      })
        .then((response) => {
          const usersResult = [];
          Object.values(response.data).map((item) => usersResult.push(item.id));
          Object.values(usersResult).map((item, index) => {
            if (index === usersResult.length - 1) {
              result += `'/users/${item}'`;
            } else {
              result += `'/users/${item}'|`;
            }
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }

    await fetchConnections(null, {
      page: pageNumber,
      perPage: itemsPerPage,
      'wherein[user2_iri]': result,
    })
      .then((response) => {
        Object.values(response.data).filter((connection) => (connection.user2_iri !== null ? connections.push(connection.user2) : ''));
      })
      .catch((error) => {
        console.error(error);
      });

    setAllConnections((old) => (shouldRefresh ? connections : [...old, ...connections]));
    setNumCurrentItens(connections.length);
    setCurrentPage(pageNumber + 1);
    setLoadingItem(false);
  };

  window.onscroll = function () {
    if ((numCurrentItens === itemsPerPage) && (window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 10)
    && loadingItem === false) {
      switch (tab) {
        case 1:
          handleSearchConnections();
          break;
        default:
          handleLeadership();
      }
    }
  };

  useEffect(() => {
    loadCategory();
    switch (tab) {
      case 1:
        trackPromise(
          handleSearchConnections(0, true),
        );
        break;
      default:
        trackPromise(
          handleLeadership({}, 0, true),
        );
    }
    return () => {
      setAllConnections([]);
      setLeadership([]);
      setCurrentPage(0);
      setNumCurrentItens(itemsPerPage);
      setLoadingItem(false);
    };
  }, [tab]);

  const search = (event) => {
    if (!event.shiftKey && (event.charCode === 13 || event.keyCode === 13)) {
      if (tab === 0) {
        handleLeadership(selectedOption, 0, true);
      } else {
        handleSearchConnections(0, true);
      }
    }
  };

  const searchOnClick = () => {
    if (tab === 0) {
      handleLeadership(selectedOption, 0, true);
    } else {
      handleSearchConnections(0, true);
    }
  };

  const getSearch = (e) => {
    if (tab === 0) {
      setSearchLeadership(e);
    } else {
      setSearchConnections(e);
    }
  };

  const filterByCategory = (e) => {
    setSelectedOption(e);
    handleLeadership(e, 0, true);
  };


  return (
    <Base
      blocks={[
        { name: 'myprofile' },
        { name: 'image' },
        { name: 'leadership' },
        { name: 'follow' },
      ]}>
      <TabsCustom
        value={tab}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
      >
        <TabCustom label={t('tabs.leadership_psdb')} />
        <TabCustom label={t('tabs.follow')} />
      </TabsCustom>

      <TabPanelCustom value={tab} index={0}>
        <Row>
          <Col xs={12} md={5}>
            <Search
              placeholder={t('search_by_leadership')}
              handleKeyUp={search}
              search={searchLeadership.toLowerCase()}
              handleGetSearch={getSearch}
              onClick={searchOnClick}
            />
          </Col>
          <Col sx={12} md={3} className="text-right">
            <Text>{t('filter_preview')}</Text>
          </Col>
          <Col xs={12} md={4}>
            <Select
              onChange={filterByCategory}
              styles={colourStyles}
              options={categories}
              value={selectedOption}
             />
          </Col>
        </Row>
        {promiseInProgress === true ? (
          <div className="text-center">
            <Spinner color="dark" />
          </div>
        ) : leadership.length > 0 ? (
          <UsersList users={leadership} />
        ) : (
          <>{t('no_records')}</>
        )}
      </TabPanelCustom>

      <TabPanelCustom value={tab} index={1}>
        <Row>
          <Col xs={12} md={5} className="mb-4">
            <Search
              placeholder={t('to_search')}
              handleKeyUp={search}
              search={searchConnections.toLowerCase()}
              handleGetSearch={getSearch}
              onClick={searchOnClick}
            />
          </Col>
        </Row>

        {promiseInProgress === true ? (
          <div className="text-center">
            <Spinner color="dark" />
          </div>
        ) : allConnections.length > 0 ? (
          <UsersList users={allConnections} />
        ) : (
          <>{t('no_records')}</>
        )}
      </TabPanelCustom>
      {numCurrentItens === itemsPerPage && (
        <div className="text-center mb-5">
          {!loadingItem && (
          <div className="text-center">
            <Spinner color="dark" />
          </div>
          )}
        </div>
      )}
    </Base>
  );
};

export default LeadershipAndFollow;
