import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import CardText from './CardText'

import { transition } from '../../theme'
import { isLarge, isNil, isSmall, media } from '../../utils'

const REGEX_STRIP_WWW = /^www\./;

const getHostname = href => {
  if (isNil(href)) return '';
  const {hostname} = new URL(href);

  return hostname.replace(REGEX_STRIP_WWW, '')
};

const mobileDescriptionStyle = css`
  ${media.mobile`
    > p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  `};
`;

export const Content = styled('div')`
  display: flex;
  padding: 10px 15px;
  min-width: 0;
  box-sizing: border-box;
  ${({cardSize}) => css`
    flex: ${!isLarge(cardSize) ? 1 : '0 0 125px'};
    justify-content: ${!isSmall(cardSize) ? 'space-around' : 'space-between'};
    flex-direction: ${!isSmall(cardSize) ? 'column' : 'row'};
    align-items: ${!isSmall(cardSize) ? 'stretch' : 'center'};
  `};
`;

const Header = styled('header')`
  text-align: left;
  font-weight: bold;
  margin: 0;
  width: 100%;
  ${({cardSize}) => css`
    flex-grow: ${!isSmall(cardSize) ? 1.2 : 0.8};
    font-size: ${!isSmall(cardSize) ? '16px' : '15px'};

    ${isSmall(cardSize) && css`
      min-width: 0;
      padding-right: 14px;
    `}
  `}
`;

const Description = styled('div')`
  text-align: left;
  font-size: 14px;
  flex-grow: 2;
  margin: auto 0;
  line-height: 18px;
  ${({cardSize}) => !isLarge(cardSize) && mobileDescriptionStyle};
`

const Footer = styled('footer')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  margin: 0;
  flex-grow: 0;
  ${({cardSize}) => css`
    font-size: ${!isSmall(cardSize) ? '12px' : '10px'};
    ${!isSmall(cardSize) && 'width: 100%;'}
  `};
`

const Author = styled(CardText)`
  opacity: 0.75;
  transition: opacity ${transition.medium};

  .preview_card:hover & {
    opacity: 1;
  }
`;

export default ({title, description, url, cardSize, className}) => {
  const isSmallCard = isSmall(cardSize);
  const formattedUrl = useMemo(() => getHostname(url), [url]);

  return (
    <Content className={className} cardSize={cardSize}>
      <Header className='preview_card__content_title' cardSize={cardSize}>
        <CardText useNanoClamp={false}>{title}</CardText>
      </Header>
      {!isSmallCard && (
        <Description
          className='preview_card__content_description'
          cardSize={cardSize}
        >
          <CardText lines={2}>{description}</CardText>
        </Description>
      )}
      <Footer cardSize={cardSize} className='preview_card__content_url'>
        <Author useNanoClamp={false}>{formattedUrl}</Author>
      </Footer>
    </Content>
  )
}
