import React from 'react';

const Main = (props) => {
  return (
    <article>
      {props.children}
    </article>
  );
};

export default Main;
