const getSigninUrl = `https://${process.env.REACT_APP_SIGNIN_URL}`;

export const redirectToLogin = (msg = "") => {
  localStorage.clear();
  window.location = `${getSigninUrl}?logout=${btoa(document.location.href)}${
    msg ? `&msg=${msg}` : ""
  }`;
};

export const refreshLogin = () => {
  window.location = `${getSigninUrl}?login=${btoa(document.location.href)}`;
};

export const signinRedirect = (detail) => {
  switch (detail) {
    case "expired_token":
      refreshLogin();
      break;
    case "not_found_token":
    case "invalid_token":
    default:
      redirectToLogin();
      break;
  }
};
