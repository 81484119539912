import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CardItem from '../CardItem';
import { loadUsers } from '../../../../services/endpoints';
import {
  Card, Title, CardBody, Button, LinkCustom,
} from '../styles';

const CardLeadership = () => {
  const { t } = useTranslation('common');
  const [leadership, setLeadership] = useState([]);

  function handleLeadership() {
    loadUsers()
      .then((response) => {
        const result = response.data.filter((item) => (item.profile.hasOwnProperty('lideranca')
          ? item.profile.lideranca.value === 'true'
          : ''));
        setLeadership(result);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    handleLeadership();
  }, []);

  return (
    <Card>
      <Title>{t('tabs.leadership_psdb')}</Title>
      <CardBody>
        {leadership.length > 0
          && leadership.map((user, index) => <CardItem user={user} key={index} />)}
      </CardBody>
      <LinkCustom to="/liderancas-quem-eu-sigo?tab=0">
        <Button>{t('buttons.see_all_leaders')}</Button>
      </LinkCustom>
    </Card>
  );
};

export default CardLeadership;
