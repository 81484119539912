import styled from 'styled-components';

export const Button = styled.button.attrs({ className: 'btn btn-default' })`

`;

export const Card = styled.div`
  background: #fff;
  border-radius: 5px;
  margin-bottom: 20px;
`;
