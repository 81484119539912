import styled from 'styled-components';

export const Profile = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: -15px;
  margin-right: -15px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  align-items: center;
`;

export const UpdatePhoto = styled.label`
  cursor: pointer;
  color: #ffffff;
  position: absolute;   
  background: rgba(0, 0, 0, 0.44);
  width: 118px;
  line-height: 48px;
  text-align: center;  
  bottom: -13px;
  right: 3px;
  opacity: 0;
  transition: all 300ms linear;
  input{
    display: none;
  }
 
`;

export const AvatarFrame = styled.div`
  border: 8px solid #fff;
  border-radius: 50%;
  overflow: hidden;
  width: 140px;
  height: 140px;
  position: relative;
  background: #004c94;
  &:hover ${UpdatePhoto}{
    opacity: 1;
  }
`;

export const Avatar = styled.img`
  max-width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`;


export const AvatarContainer = styled.div`
  padding-left: 80px;
  padding-right: 15px;
  margin-top: -40px;
  z-index: 2;
  position: relative; 
`;

export const MetaInfo = styled.div`
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 15px;
  width: 80%;
`;

export const Address = styled.div`
  color: #bab8b8;
  font-size: 14px;
  font-weight: bold;
`;

export const Name = styled.div`
  font-size: 25px;
  font-weight: 900;
  color: #2d2c2c;
`;

export const Office = styled.div`
  font-size: 12px;
  color: #707070;
`;

export const ButtonFollowContainer = styled.div`
  width: 20%;
  margin-right: 10%; 
`;

export const ButtonFollow = styled.button.attrs({ className: 'btn btn-default' })`
  display: inline-block !important;  
  padding-left: 15px;
  padding-right: 15px;
`;
