/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Spinner, Row, Col, Progress,
} from 'reactstrap';
import api from '../../services/api';
import Base from '../../components/Theme/base';
import Breadcrumb from '../../components/Breadcrumb';
import Description from './Description';
import { loadSpecificCourse } from '../../services/endpoints';
import ComplementaryMaterial from './ComplementaryMaterial';
import Playlist from './Playlist';
import { Card, Lesson, LessonStyled } from './Playlist/Lessons/styles';
import {
  Line,
  Box,
  CourseTitle,
  ProgressText,
  ProgressBar,
  ProgressGrid,
  LessonText,
  LessonProgress,
  LessonGrid,
  LessonCount,
  ProgressBox,
  CertificateButton,
  CertificateLink,
} from './styles';
import CoursesFilter from '../../components/Courses/CoursesFilter';

const CoursesShow = (props) => {
  const { t } = useTranslation('courses');
  const { slug } = props.match.params;
  const [course, setCourse] = useState([]);
  const [lesson, setLesson] = useState({});
  const [courseStats, setCourseStats] = useState([]);
  const [lessonStats, setLessonStats] = useState([]);
  const [lessonId, setLessonId] = useState([]);
  const [certificate, setCertificate] = useState([]);
  const [media, setMedia] = useState([]);
  const [description, setDescription] = useState('');
  const filterLesson = [];
  const [complementaryMaterial, setComplementaryMaterial] = useState([]);

  const handleCourseStats = (courseId) => {
    api.get('/lms/course_stats').then((response) => {
      const result = response.data.filter((p) => p.course.id === courseId);
      setCourseStats(result[0]);
    }).catch((error) => {
      console.error(error);
    });
  };

  const handleLessonStats = (lessonId) => {
    api.get('/lms/lesson_stats').then((response) => {
      setLessonStats(response.data);
      const r = response.data.filter((item) => item.lesson.id === lessonId);
      setLessonId(r[0]);
    }).catch((error) => {
      console.error(error);
    });
  };

  const filterLessonStats = (l, index) => {
    const result = lessonStats.filter((item) => item.lesson.id === l.id);
    filterLesson.push(result[0]);
    return (
      <LessonGrid>
        <LessonCount>
          Aula
          {' '}
          {l.position < 10 ? `0${l.position}` : `${l.position}`}
          <span className="ml-1">|</span>
        </LessonCount>
        <LessonText>
          {filterLesson[index] !== undefined ? filterLesson[index].progress : 0}
          <span>%</span>
        </LessonText>
        <LessonProgress>
          <Progress
            value={
              filterLesson[index] !== undefined
                ? filterLesson[index].progress
                : 0
            }
          />
        </LessonProgress>
      </LessonGrid>
    );
  };

  const getCourse = () => {
    loadSpecificCourse(slug)
      .then((response) => {
        setCourse(response.data[0] || []);
        setMedia(response.data[0].lessons[0].lesson_contents);
        setCertificate(response.data[0].certificate_template.certificate_image.urlPublica);
        setLesson(response.data[0].lessons[0]);
        setDescription(response.data[0].lessons[0].description);
        setComplementaryMaterial(
          response.data[0].lessons[0].lesson_support_materials,
        );
        handleCourseStats(response.data[0].id);
        handleLessonStats(response.data[0].id);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getLessons = (l) => {
    setDescription(l.description);
    setMedia(l.lesson_contents);
    setLesson(l);
    handleLessonStats(l.id);
    setComplementaryMaterial(l.lesson_support_materials);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    getCourse();
    if (course.length > 0) {
      handleCourseStats();
    }
    if (lesson.length > 0) {
      handleLessonStats();
    }
    return () => {
      setCourse([]);
      setCourseStats([]);
      setLessonStats([]);
      setLessonId([]);
      setMedia([]);
      setLesson([]);
      setDescription('');
      setComplementaryMaterial([]);
    };
  }, []);

  return (
    <Base
      blocks={[
        { name: 'myprofile' },
        { name: 'image' },
        { name: 'leadership' },
        { name: 'follow' },
      ]}
    >
      {Object.values(course).length > 0 ? (
        <>
          <Row>
            <Col xs={12} md={6}>
              <h1>{t('Courses')}</h1>
              <Breadcrumb
                root={[
                  { title: 'Cursos', url: '/cursos' },
                  { title: `${course ? course.title : ''}` },
                ]}
              />
            </Col>

            <Col xs={12} md={6}>
              <CoursesFilter />
            </Col>
          </Row>
          {course.lessons ? (
            course.lessons.length > 0 ? (
              <>
                <Box>
                  <CourseTitle>{course ? course.title : ''}</CourseTitle>

                  {media.length > 0 && (
                    <Line>
                      <Playlist
                        course={course}
                        data={media}
                        lesson={lesson}
                        handleCourseStats={handleCourseStats}
                        handleLesson={handleLessonStats}
                        lessonId={lessonId}
                      />
                    </Line>
                  )}
                  <Line>
                    <Row>
                      <Col xs={12} md={8}>
                        {description.length > 0 && <Description data={description} />}
                      </Col>
                      <Col xs={12} md={4}>
                        {complementaryMaterial.length > 0 && (
                          <ComplementaryMaterial data={complementaryMaterial} />
                        )}
                      </Col>
                    </Row>
                  </Line>


                  <Row>
                    <Col xs={12} md={8}>
                      <h1>{t('Lessons')}</h1>
                    </Col>

                    <Col xs={12} md={4}>
                      <ProgressBox>
                        {t('Course Progress')}
                        <ProgressGrid>
                          <ProgressText>
                            {courseStats !== undefined ? courseStats.progress : 0}
                            <span>%</span>
                          </ProgressText>
                          <ProgressBar>
                            <Progress
                              value={
                              courseStats !== undefined
                                ? courseStats.progress
                                : 0
                            }
                            />
                          </ProgressBar>
                        </ProgressGrid>
                      </ProgressBox>
                    </Col>
                  </Row>
                  <LessonStyled>
                    {course.lessons.map((l, index) => (
                      <Card
                        key={l.id}
                        onClick={() => {
                          getLessons(l);
                        }}
                      >
                        <Row>
                          <Col xs={12} md={7}>
                            {filterLessonStats(l, index)}
                          </Col>
                        </Row>
                        <Lesson>{`${l.title}`}</Lesson>
                      </Card>
                    ))}
                  </LessonStyled>

                  <CertificateButton allow={courseStats ? courseStats.progress === 100 ? true : undefined : undefined}>
                    <CertificateLink target="_blank" href={certificate} allow={courseStats ? courseStats.progress === 100 ? true : undefined : undefined}>
                      <i className="fas fa-file-certificate" />
                      {t('Certificate')}
                    </CertificateLink>
                  </CertificateButton>

                </Box>
              </>
            ) : (
              <div className="mt-3">{t('No-Lessons')}</div>
            )
          ) : (
            ''
          )}
        </>
      ) : (
        <div className="text-center">
          <Spinner />
        </div>
      )}
    </Base>
  );
};

export default CoursesShow;
