import styled, { css } from 'styled-components';
import { FormGroup as FormGroupBT } from 'reactstrap';

export const Description = styled.div`
  font-weight: bold;
  margin-bottom: 60px;
  p{
    margin-bottom: 5px;
  }
`;

export const Card = styled.div`
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  margin-top: 30px;
}
`;

export const Button = styled.button.attrs({ className: 'btn btn-primary' })`
  width: auto !important;
  display: inline-block !important;
  padding-left: 30px;
  padding-right: 30px;
`;

export const FormGroup = styled(FormGroupBT)`
  color: #2d2c2c;
  font-weight: bold;
`;

const placeholder = css`
  color: #2d2c2c;
  font-weight: bold;
`;

export const ContactStyled = styled.div`

  .form-group{
    margin-bottom: 30px;
    position: relative;
    transition: width 300ms linear;
    & .form-input:focus {
      &:before{
        width: 100%;
      }    
    }
    &:before{
      content: '';
      position: absolute;
      z-index: 2;
      width: 0;
      bottom: 0;
      left: 0;
      height: 2px;
      background-color: #004c94;
    }
  }
  
  label{
    font-size: 12px;
    display: block;
    margin-bottom: 0;
    color: #777676;
  }
  
  .form-input,
  .form-textarea {
    font-size: 15px;
    width: 100%;
    outline: none;

    &::-webkit-input-placeholder { ${placeholder} }
    &:-moz-placeholder { ${placeholder} opacity: 1; }
    &::-moz-placeholder { ${placeholder} opacity: 1; }
    &:-ms-input-placeholder { ${placeholder} }
    &::-ms-input-placeholder { ${placeholder} }
    &::placeholder { ${placeholder} }  
  }
  
  .form-input{
    border: 0;
    border-bottom: 1px solid #2b2b2b;
    padding: 5px 0;
    height: 38px;
  }
  .form-textarea{
    border: 1px solid #2b2b2b;
    padding: 15px;
    resize: none;
    border-radius: 5px;
  }
  
  .error {
    position: absolute;
    bottom: -20px;
    height: 20px;
    background: #ea4747;
    right: 0;
    font-size: 12px;
    color: #fff;
    padding: 0 3px;
    border-radius: 4px;
    &:after{
      content: '';
      position: absolute;
      top: -7px;
      right: 10px;
      border-bottom: 7px solid #ea4747;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
    }
  }
  
`;
