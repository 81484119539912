import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Directories = styled.div`
  margin-bottom: 60px;
`;

export const Card = styled.div`
  background: #fff;
  border-radius: 5px;
  margin-bottom: 15px;
  display: grid;
  font-size: 15px;
  text-align: center;
  align-items: center;
  min-height: 66px;
  color: #777676;
  transition: all 300ms linear;
  grid-template-columns: 1fr 2fr;
  grid-template-areas:
    "i t"
    "b1 b2";
  padding: 10px;
  &.list-states {
    grid-template-columns: 1fr 2fr;
    grid-template-areas:
      "i t"
      "b1 b2";
    padding: 10px;
    margin-left: 5px;
  }
  &:hover {   
    text-decoration: none;    
  }
`;

export const Image = styled.img`
  grid-area: i;
  width: 72px;
  height: 48px;
  border-radius: 5px;
`;

export const Text = styled(Link)`
  grid-area: t;
  text-align: left;
  color: #2d2c2c;
  &:hover {   
    text-decoration: none;    
    color: #2d2c2c;
  }
`;

export const Button = styled.button.attrs({ className: 'btn btn-default' })`
  grid-area: b1;
  height: fit-content !important;
  line-height: normal !important;
  margin-top: 15px !important;
`;
