import React from 'react';
import PropsTypes from 'prop-types';
import Aside from '../../Widgets/Aside';
import Live from '../../Widgets/Live';
import MyProfile from '../../Widgets/MyProfile';
import CardLeadership from '../../Widgets/LeadershipAndFollow/CardLeadership';
import CardFollow from '../../Widgets/LeadershipAndFollow/CardFollow';
import CoursesList from '../../Widgets/Courses/CoursesList';


const ColumnRight = ({ name, data = undefined, ...props }) => {
  const components = {
    image: Aside,
    live: Live,
    myprofile: MyProfile,
    leadership: CardLeadership,
    follow: CardFollow,
    courses: CoursesList,
  };

  if (components.hasOwnProperty(name)) {
    const RenderComponent = components[name];
    return (<RenderComponent {...data} />);
  }
  return (<></>);
};

ColumnRight.propTypes = {
  name: PropsTypes.string.isRequired,
  data: PropsTypes.any,
};

export default ColumnRight;
