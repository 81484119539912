import styled from 'styled-components';

export const Cards = styled.article`
  padding: 10px;
  height: fit-content;
  overflow-y: scroll;
  margin-right: 10px;
  
  &::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  &::-webkit-scrollbar {
    border-radius: 10px;
    width: 12px;
    background-color: #eee;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #004c94;
  }
  
`;
