import React from 'react';
import { Row } from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';
import { useTranslation } from 'react-i18next';
import { CardStyled, Desc } from './styles';

const Description = ({ data }) => {
  const { t } = useTranslation('courses');

  return (
    <Row>
      <CardStyled xs={12}>
        <h1>{t('Description')}</h1>
        <Desc>{ ReactHtmlParser(`${data}`) }</Desc>
      </CardStyled>
    </Row>
  );
};

export default Description;
