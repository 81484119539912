import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  AuthorInfo,
  AuthorOffice,
  AuthorTitle,
  Avatar,
  AvatarLink,
  Date,
  Info,
  AuthorFollow,
} from './styles';
import NoAvatar from '../../../../../../assets/images/no-avatar.png';
import { getCurrentUser } from '../../../../../../services/endpoints';
import HandleRefreshContext from '../../../handleRefreshContext';
import Follow from '../../../../../Follow';

const LikeUser = ({ evaluation }) => {
  const user = getCurrentUser();
  const { allUsers } = useContext(HandleRefreshContext);
  const { setAllUsers } = useContext(HandleRefreshContext);
  const { allSegments } = useContext(HandleRefreshContext);
  const { setAllSegments } = useContext(HandleRefreshContext);


  return (
    <div>
      <AuthorInfo>

        <AvatarLink to={`/profile/${evaluation.user.id}`}>
          {evaluation.user.profile.hasOwnProperty('avatar') ? (
            <Avatar src={evaluation.user.profile.avatar.file.urlPublica} />

          ) : (
            <Avatar src={NoAvatar} />
          )}

        </AvatarLink>
        <Info>
          <AuthorTitle to={`/profile/${evaluation.user.id}`}>
            {evaluation.user.name}
          </AuthorTitle>
          <AuthorOffice>
            {evaluation.user.profile.hasOwnProperty('cargo') ? evaluation.user.profile.cargo.value : ''}

            {evaluation.user.profile.hasOwnProperty('cidade') ? ` | ${evaluation.user.profile.cidade.value}` : ''}

          </AuthorOffice>
          <Date>{evaluation.created_at}</Date>
        </Info>

        {user.id !== evaluation.user.id && (
        <AuthorFollow>
          <Follow
            allUsers={allUsers}
            setAllUsers={setAllUsers}
            idUsers={evaluation.user.id}
            allSegments={allSegments}
            setAllSegments={setAllSegments}
            user={evaluation.user}
          />
        </AuthorFollow>
        )}

      </AuthorInfo>
    </div>
  );
};

LikeUser.propTypes = {
  evaluation: PropTypes.object.isRequired,
};

export default LikeUser;
