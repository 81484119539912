import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Box = styled(Link)`
&:hover{
  text-decoration: none;
}
`;

export const Item = styled.section`
  background-color: #ffffff;
  color: #313131;
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-areas: "i t t t"
                       "d d d d"
                       "s s s s";
  align-content: center;
  grid-gap: 0;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  border: 1px solid transparent;
  transition: border 300ms linear;
  &:hover{
    border-color: #004c94;
  }
`;

export const Image = styled.img`
  grid-area: i;
  border-radius: 50%;
  text-align: center;
  display: block;
  object-fit: cover;
  height: 45px;
  width: 45px;  
`;

export const Name = styled.div`
  padding-left: 5px;
  grid-area: t;
  color:#2d2c2c;
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 15px;
  white-space: normal;
`;

export const Title = styled.div`
  grid-area: d;
  color: #2d2c2c;
  font-size: 12px;
  overflow: hidden;
  white-space: normal;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;

export const Description = styled.div`
  grid-area: d;
  color: #2d2c2c;
  font-size: 12px;
  margin-bottom: 15px; 
  line-height: 2px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;

export const Date = styled.time`
  grid-area: s;
  text-transform: lowercase;
  color: #bab8b8;
  font-size: 12px;
`;
