import styled, { css } from 'styled-components';
import { Col, Row } from 'reactstrap';

const font = css`
  font-size: 1.1875em;
  color: #2d2c2c;
  font-weight: 600;
`;

// const border = css`
//   border-bottom: 1px solid #d2d2d2;
//   margin-bottom: 30px;
// `;

export const VideoCount = styled.div`
  color: #707070;
  font-size: 0.8125em;
  padding-bottom: 5px;
`;

export const CountItem = styled.span`  
  color: #004c94;
  font-weight: bold;
`;


export const Screen = styled.div`
  border-radius: 8px;
`;

export const Description = styled.p`
  ${font};
  margin: 20px 0;
`;

export const Image = styled.img`
  border-radius: 5px;  
`;

export const CurrentVideo = styled(Col)``;
export const Icon = styled.i`
  position: absolute;
  top: 20%;
  right: 40%;
  color: #ffffff;
  font-size: 20px;
`;

export const Thumbnail = styled.div`
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
  }
`;

export const List = styled(Col)`
  position: relative;
`;

export const PlaylistTitle = styled.h3`
  font-size: 1.1875em;
  font-weight: 600;
  color: #004c94;
  padding-bottom: 10px;
`;

export const Video = styled.img``;

export const Card = styled.div`
  margin-right: 30px;
`;

export const CardItem = styled(Row)`
  border-bottom: 1px solid #d2d2d2;
  padding-bottom: 5px;
  margin-bottom: 10px;
  cursor: pointer;

  &:hover {
    color: #004c94;
  }
`;

export const Preview = styled.img`
  border-radius: 5px; 
`;


export const PreviewDescription = styled.p`
  font-size: 0.875em;
  font-weight: 600;
  @media (min-width: 960px) {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
`;

export const ProgressGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 5fr;
  grid-template-areas: "count text bar";
  margin-top: 30px;
  white-space: nowrap;
`;

export const ProgressText = styled.div`  
  grid-area: "text";
  text-align: center;
`;

export const ProgressBar = styled.div`
  grid-area: "bar";
  margin-top: 5px;
.progress {
  display: flex;
  height: 10px;
  width: 63%;
  background-color: #e5e5e5;
  border-radius: 5px;
}

.progress-bar {
  white-space: nowrap;
  border-radius: 10px;
  background-color: #004c94;
}
`;

export const LessonCount = styled.div`
  grid-area: "count";
  color: #004c94;
  font-weight: bold;
`;
