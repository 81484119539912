/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { Field, Form, Formik, FieldArray } from "formik";
import { Col, Input, Label, Row, Modal } from "reactstrap";
import MaskedInput from "react-input-mask";
import Moment from "moment";
import { store } from "react-notifications-component";
import { useHistory } from "react-router-dom";
import CoverImage from "../../../../components/CoverImage";
import ProfileBar from "../../../../components/ProfileBar";
import {
  loadStates,
  loadCategories,
  changePasswordUser,
} from "../../../../services/endpoints";
import {
  Button,
  ButtonAdd,
  ButtonDel,
  EditProfile as EditProfileStyled,
  FormGroup,
  MetaInfo,
  MyData,
  MyDataButton,
  Period,
  Box,
  Title,
  Notice,
  Icon,
  FormBox,
  ButtonModal,
} from "./styles";
import changeMaskPhone from "../../../../utils/changeMaskPhone";
import States from "../../../../assets/jsons/states";
import {
  loadProfileFields,
  updateProfileFieldValues,
  updateLocalStorageUser,
  handleCurriculumFields,
} from "../../../../utils/profileInfo";
import { isCPF } from "../../../../utils/identityValidate";
import settings from "../../../../services/settings";

const EditProfile = ({
  user,
  handleUser,
  curriculum,
  loadCurriculum,
  loadState,
}) => {
  const { t } = useTranslation("timeline");
  const [data, setData] = useState({});
  const [states, setStates] = useState([]);
  const [categories, setCategories] = useState([]);
  const [modal, setModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  settings.notifications.dismiss.duration = 5000;
  const history = useHistory();

  const mapStateAbbr = (abbr) => {
    const stateSelected = States.find((state) => state.abbr === abbr);
    if (!stateSelected) {
      return null;
    }
    const stateData = states.find(
      (state) => state.value === stateSelected.name
    );
    if (stateData) {
      return stateData.id;
    }

    return "";
  };

  const mapCategory = (value) => {
    const categoryData = categories.find(
      (category) => category.value === value
    );
    if (categoryData) {
      return categoryData.id;
    }
    return "";
  };

  const initialValues = {
    name: data.nome ? data.nome.value : "",
    membership: data.filiacao ? data.filiacao.value : "",
    officer: data.cargo ? data.cargo.value : "",
    voter_number: data.titulo_eleitoral ? data.titulo_eleitoral.value : "",
    email: data.email ? data.email.value : "",
    phone: data.telefone ? data.telefone.value : "",
    address: data.logradouro ? data.logradouro.value : "",
    neighborhood: data.bairro ? data.bairro.value : "",
    city: data.cidade ? data.cidade.value : "",
    zipcode: data.cep ? data.cep.value : "",
    state: data.uf ? mapStateAbbr(data.uf.value) : "",
    category: data.categoria ? mapCategory(data.categoria.value) : "",
    birth: data.nascimento ? data.nascimento.value : "",
    password: "",
    confirm_password: "",
    curriculum: curriculum || [],
  };

  const loadData = async () => {
    const statesLoaded = await loadStates();
    const result = await statesLoaded.data.filter(
      (item) => item.value !== "Todos"
    );
    if (statesLoaded.data && statesLoaded.data.length) {
      setStates(result);
    }
    const categoriesLoaded = await loadCategories();
    if (categoriesLoaded.data && categoriesLoaded.data.length) {
      setCategories(categoriesLoaded.data);
    }
    const profileFields = await loadProfileFields(`/users/${user.id}`);
    setData(profileFields);
  };

  useEffect(() => {
    loadData();
    loadState();
    return () => {
      setData({});
    };
  }, [modal]);

  const toggle = () => {
    setModal(!modal);
  };

  // eslint-disable-next-line arrow-body-style,prefer-arrow-callback,func-names
  Yup.addMethod(Yup.date, "dateFormat", function (
    formats = "DD/MM/YYYY",
    parseStrict
  ) {
    // eslint-disable-next-line react/no-this-in-sfc,func-names
    return this.transform(function (value, originalValue) {
      // eslint-disable-next-line react/no-this-in-sfc
      if (this.isType(value)) return value;

      // eslint-disable-next-line no-param-reassign
      value = Moment(originalValue, formats, parseStrict);

      return value.isValid() ? value.toDate() : new Date("");
    });
  });

  // eslint-disable-next-line arrow-body-style,prefer-arrow-callback,func-names
  Yup.addMethod(Yup.string, "cpf", function (message) {
    // eslint-disable-next-line react/no-this-in-sfc,func-names
    return this.test("cpf", message || t("validates.invalid_cpf"), (value) =>
      isCPF(value)
    );
  });

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("common:form.validations.required")),
    state: Yup.mixed().required(t("common:form.validations.required")),
    category: Yup.mixed().required(t("common:form.validations.required")),
    voter_number: Yup.string().required(t("common:form.validations.required")),
    birth: Yup.date()
      .dateFormat("DD/MM/YYYY", true)
      .max(new Date(), t("common:form.validations.invalid_date_max"))
      .min(new Date(1900, 1, 1), t("common:form.validations.invalid_date_min"))
      .required(t("common:form.validations.required"))
      .typeError(t("common:form.validations.invalid_date")),
    membership: Yup.date()
      .dateFormat("DD/MM/YYYY", true)
      .max(new Date(), t("common:form.validations.invalid_date_max"))
      .min(new Date(1900, 1, 1), t("common:form.validations.invalid_date_min"))
      .required(t("common:form.validations.required"))
      .typeError(t("common:form.validations.invalid_date")),
    password: Yup.string()
      .notRequired()
      .test(t("common:form.validations.required"), (value) => {
        // eslint-disable-next-line
        if (!!value) {
          const schema = Yup.string().min(1);
          return schema.isValidSync(value);
        }
        return true;
      })
      .min(8, t("common:form.validations.pwd_min", { number: 8 }))
      .max(24, t("common:form.validations.pwd_max", { number: 24 }))
      .matches(/[a-z]/, t("common:form.validations.one_lowercase"))
      .matches(/[A-Z]/, t("common:form.validations.one_uppercase"))
      .matches(/[0-9]/, t("common:form.validations.one_number"))
      .matches(/[@$!%*#?&.<>]/, t("common:form.validations.one_symbol")),
    confirm_password: Yup.string()
      .when("password", {
        is: (val) => val !== undefined,
        then: (fieldSchema) =>
          fieldSchema.required(t("common:form.validations.required")),
      })
      .oneOf(
        [Yup.ref("password"), null],
        t("common:form.validations.pwd_must_match")
      ),
  });

  return (
    <EditProfileStyled>
      <MyData>
        <MyDataButton onClick={toggle}>
          <Icon className="fas fa-pencil-alt" onClick={toggle} />
          {t("edit")}
        </MyDataButton>
      </MyData>
      <Modal
        isOpen={modal}
        fade={false}
        toggle={toggle}
        style={{
          maxWidth: "1050px",
          marginTop: "5px",
        }}
      >
        <MetaInfo>
          <CoverImage user={user} isCurrentUser />
          <Row>
            <Col xs={12} md={8}>
              <ProfileBar user={user} isCurrentUser />
            </Col>
            <Col xs={12} md={4}>
              <ButtonModal onClick={toggle}>
                <Icon className="far fa-times-circle" onClick={toggle} />
                {t("close")}
              </ButtonModal>
            </Col>
          </Row>
          <FormBox>
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={async (values) => {
                setIsSubmitting(true);
                try {
                  await updateProfileFieldValues(data, values, user.id);
                  try {
                    await updateLocalStorageUser();
                    if (values.password.length >= 8) {
                      await changePasswordUser({
                        plainPassword: values.password,
                      });
                    }
                    history.go();
                    //setIsSubmitting(false);
                    //loadState();
                    //loadData();
                    //handleUser();
                    //loadCurriculum();
                  } catch (error) {
                    store.addNotification({
                      ...settings.notifications,
                      message: t("common:notifications.danger.update_profile"),
                      type: "danger",
                    });
                    console.error("updateLocalStorageUser", error);
                  }
                } catch (error) {
                  console.error("updateProfileFieldValues", error);
                }
              }}
            >
              {({
                errors,
                touched,
                values,
                setFieldValue,
                handleChange,
                handleBlur,
              }) => (
                <Form>
                  {console.log("values", values)}

                  <Row>
                    <Col xs={8}>
                      <FormGroup>
                        <Label htmlFor="name">{`${t(
                          "common:form.labels.name"
                        )}`}</Label>
                        <Field
                          className="form-input"
                          name="name"
                          placeholder={t(
                            "common:form.placeholders.insert_your_name"
                          )}
                        />
                        {errors.name && touched.name ? (
                          <div className="error">{errors.name}</div>
                        ) : null}
                      </FormGroup>
                    </Col>

                    <Col xs={4}>
                      <FormGroup>
                        <Label htmlFor="birth">{`${t(
                          "common:form.labels.birth"
                        )}`}</Label>
                        <Field name="birth">
                          {({ field }) => (
                            <MaskedInput
                              {...field}
                              className="form-input"
                              maskChar=""
                              mask="99/99/9999"
                              placeholder={t(
                                "common:form.placeholders.your_birth"
                              )}
                            />
                          )}
                        </Field>
                        {errors.birth && touched.birth ? (
                          <div className="error">{errors.birth}</div>
                        ) : null}
                      </FormGroup>
                    </Col>

                    <Col xs={12} sm={6} md={4}>
                      <FormGroup>
                        <Label htmlFor="membership">{`${t(
                          "common:form.labels.membership"
                        )}`}</Label>
                        <Field name="membership">
                          {({ field }) => (
                            <MaskedInput
                              {...field}
                              className="form-input"
                              maskChar=""
                              mask="99/99/9999"
                              placeholder={t(
                                "common:form.placeholders.your_membership"
                              )}
                            />
                          )}
                        </Field>
                        {errors.membership && touched.membership ? (
                          <div className="error">{errors.membership}</div>
                        ) : null}
                      </FormGroup>
                    </Col>

                    <Col xs={12} sm={6} md={4}>
                      <FormGroup>
                        <Label htmlFor="officer">
                          {t("common:form.labels.officer")}
                        </Label>
                        <Field
                          className="form-input"
                          name="officer"
                          placeholder={t(
                            "common:form.placeholders.your_officer"
                          )}
                        />
                        {errors.officer && touched.officer ? (
                          <div className="error">{errors.officer}</div>
                        ) : null}
                      </FormGroup>
                    </Col>

                    <Col xs={12} sm={6} md={4}>
                      <FormGroup>
                        <Label htmlFor="voter_number">{`${t(
                          "common:form.labels.voter_number"
                        )}`}</Label>
                        <Field
                          name="voter_number"
                          className="form-input"
                          maxLength={16}
                          placeholder={t(
                            "common:form.placeholders.enter_a_number"
                          )}
                          onChange={(val) => {
                            const value = val.target.value || "";
                            setFieldValue(
                              "voter_number",
                              value.replace(/[^\d]/g, "")
                            );
                          }}
                        />
                        {errors.voter_number && touched.voter_number ? (
                          <div className="error">{errors.voter_number}</div>
                        ) : null}
                      </FormGroup>
                    </Col>

                    <Col xs={12} sm={6} md={4}>
                      <FormGroup>
                        <Label htmlFor="email">{`${t(
                          "common:form.labels.email"
                        )}`}</Label>
                        <p className="form-input">{values.email}</p>
                      </FormGroup>
                    </Col>

                    <Col xs={12} sm={6} md={4}>
                      <FormGroup>
                        <Label htmlFor="phone">
                          {t("common:form.labels.phone")}
                        </Label>
                        <Field name="phone">
                          {({ field }) => (
                            <MaskedInput
                              {...field}
                              className="form-input"
                              value={values.phone}
                              maskChar=""
                              mask={changeMaskPhone(values.phone)}
                              placeholder={t(
                                "common:form.placeholders.your_phone"
                              )}
                            />
                          )}
                        </Field>
                        {errors.phone && touched.phone ? (
                          <div className="error">{errors.phone}</div>
                        ) : null}
                      </FormGroup>
                    </Col>

                    <Col xs={12} md={4}>
                      <FormGroup>
                        <Label htmlFor="category">{`${t(
                          "common:form.labels.category"
                        )}`}</Label>
                        <Field name="category">
                          {({ field }) => (
                            <Input
                              {...field}
                              type="select"
                              className="form-input"
                              value={values.category}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <option value="" disabled>
                                Selecione
                              </option>
                              {categories.map((category, index) => (
                                <option value={category.id} key={index}>
                                  {category.value}
                                </option>
                              ))}
                            </Input>
                          )}
                        </Field>
                        {errors.state && touched.state ? (
                          <div className="error">{errors.state}</div>
                        ) : null}
                      </FormGroup>
                    </Col>

                    <Col xs={12} md={8}>
                      <FormGroup>
                        <Label htmlFor="address">
                          {t("common:form.labels.address")}
                        </Label>
                        <Field
                          className="form-input"
                          name="address"
                          placeholder={t(
                            "common:form.placeholders.your_address"
                          )}
                        />
                        {errors.address && touched.address ? (
                          <div className="error">{errors.address}</div>
                        ) : null}
                      </FormGroup>
                    </Col>

                    <Col xs={12} md={4}>
                      <FormGroup>
                        <Label htmlFor="neighborhood">
                          {t("common:form.labels.neighborhood")}
                        </Label>
                        <Field
                          className="form-input"
                          name="neighborhood"
                          placeholder={t(
                            "common:form.placeholders.your_neighborhood"
                          )}
                        />
                        {errors.neighborhood && touched.neighborhood ? (
                          <div className="error">{errors.neighborhood}</div>
                        ) : null}
                      </FormGroup>
                    </Col>

                    <Col xs={12} md={8}>
                      <FormGroup>
                        <Label htmlFor="city">
                          {t("common:form.labels.city")}
                        </Label>
                        <Field
                          className="form-input"
                          name="city"
                          placeholder={t("common:form.placeholders.your_city")}
                        />
                        {errors.city && touched.city ? (
                          <div className="error">{errors.city}</div>
                        ) : null}
                      </FormGroup>
                    </Col>

                    <Col xs={12} md={2}>
                      <FormGroup>
                        <Label htmlFor="zipcode">
                          {t("common:form.labels.zipcode")}
                        </Label>
                        <Field name="zipcode">
                          {({ field }) => (
                            <MaskedInput
                              {...field}
                              className="form-input"
                              value={values.zipcode}
                              maskChar=""
                              mask="99999-999"
                              placeholder={t(
                                "common:form.placeholders.your_zipcode"
                              )}
                            />
                          )}
                        </Field>
                        {errors.zipcode && touched.zipcode ? (
                          <div className="error">{errors.zipcode}</div>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={2}>
                      <FormGroup>
                        <Label htmlFor="state">{`${t(
                          "common:form.labels.state"
                        )}`}</Label>
                        <Field name="state">
                          {({ field }) => (
                            <Input
                              {...field}
                              type="select"
                              className="form-input"
                              value={values.state}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <option value="" disabled>
                                Selecione
                              </option>
                              {states.map((state, index) => (
                                <option value={state.id} key={index}>
                                  {state.value}
                                </option>
                              ))}
                            </Input>
                          )}
                        </Field>
                        {errors.state && touched.state ? (
                          <div className="error">{errors.state}</div>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} sm={6} md={4}>
                      <FormGroup>
                        <Label htmlFor="password">
                          {t("common:form.labels.password")}
                        </Label>
                        <Field
                          name="password"
                          className="form-input"
                          type="password"
                          placeholder={t(
                            "common:form.placeholders.your_password"
                          )}
                          maxLength="24"
                          onChange={(val) => {
                            const value = val.target.value || "";
                            setFieldValue(
                              "password",
                              value.replace(" ", "").trim()
                            );
                          }}
                        />
                        {errors.password && touched.password ? (
                          <div className="error">{errors.password}</div>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col xs={12} sm={6} md={4}>
                      <FormGroup>
                        <Label htmlFor="confirm_password">
                          {t("common:form.labels.confirm_password")}
                        </Label>
                        <Field
                          name="confirm_password"
                          className="form-input"
                          type="password"
                          placeholder={t(
                            "common:form.placeholders.your_confirm_password"
                          )}
                          maxLength="24"
                          onChange={(val) => {
                            const value = val.target.value || "";
                            setFieldValue(
                              "confirm_password",
                              value.replace(" ", "").trim()
                            );
                          }}
                        />
                        {errors.confirm_password && touched.confirm_password ? (
                          <div className="error">{errors.confirm_password}</div>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>

                  <Box>
                    <Title>{t("my_public_curriculum")}</Title>

                    <FieldArray
                      name="curriculum"
                      render={({ remove, push }) => (
                        <div>
                          {values.curriculum.length > 0 &&
                            values.curriculum.map((item, index) => (
                              <Row key={index}>
                                <Col xs={12} md={4}>
                                  <FormGroup>
                                    <Label
                                      htmlFor={`curriculum.${index}.funcao`}
                                    >
                                      {t("common:form.labels.role")}
                                    </Label>
                                    <Field
                                      className="form-input"
                                      name={`curriculum.${index}.funcao`}
                                    />
                                    {errors.curriculum &&
                                    errors.curriculum[index] &&
                                    errors.curriculum[index].funcao &&
                                    touched.curriculum &&
                                    touched.curriculum[index].funcao ? (
                                      <div className="error">
                                        {errors.curriculum[index].funcao}
                                      </div>
                                    ) : null}
                                  </FormGroup>
                                </Col>

                                <Col xs={12} md={4}>
                                  <FormGroup>
                                    <Label
                                      style={{ whiteSpace: "nowrap" }}
                                      htmlFor="period"
                                    >
                                      {t("common:form.labels.period")}
                                    </Label>
                                    <Row>
                                      <Col xs={12} md={5}>
                                        <Field
                                          className="form-input"
                                          name={`curriculum.${index}.data_de_inicio`}
                                        >
                                          {({ field }) => (
                                            <MaskedInput
                                              {...field}
                                              className="form-input"
                                              maskChar=""
                                              mask="99/99/9999"
                                            />
                                          )}
                                        </Field>
                                      </Col>
                                      <Period>até</Period>
                                      {errors.curriculum &&
                                      errors.curriculum[index] &&
                                      errors.curriculum[index].data_de_inicio &&
                                      touched.curriculum &&
                                      touched.curriculum[index]
                                        .data_de_inicio ? (
                                        <div className="error">
                                          {
                                            errors.curriculum[index]
                                              .data_de_inicio
                                          }
                                        </div>
                                      ) : null}

                                      <Col xs={12} md={5}>
                                        <Field
                                          className="form-input"
                                          name={`curriculum.${index}.data_fim`}
                                        >
                                          {({ field }) => (
                                            <MaskedInput
                                              {...field}
                                              className="form-input"
                                              maskChar=""
                                              mask="99/99/9999"
                                            />
                                          )}
                                        </Field>
                                      </Col>
                                    </Row>
                                    {errors.curriculum &&
                                    errors.curriculum[index] &&
                                    errors.curriculum[index].data_fim &&
                                    touched.curriculum &&
                                    touched.curriculum[index].data_fim ? (
                                      <div className="error">
                                        {errors.curriculum[index].data_fim}
                                      </div>
                                    ) : null}
                                  </FormGroup>
                                </Col>

                                <Col>
                                  <ButtonDel onClick={() => remove(index)}>
                                    <i className="fas fa-times-circle" />
                                  </ButtonDel>
                                </Col>
                              </Row>
                            ))}
                          <Row>
                            <Col xs={12} md={4} className="pt-3">
                              <ButtonAdd
                                type="button"
                                onClick={() =>
                                  push({
                                    funcao: "",
                                    data_de_inicio: "",
                                    data_fim: "",
                                  })
                                }
                              >
                                {values.curriculum.length > 0
                                  ? t("insert_more_experiences")
                                  : t("insert_experiences")}
                              </ButtonAdd>
                            </Col>
                          </Row>
                        </div>
                      )}
                    />
                    <br />
                  </Box>
                  <Row>
                    <Col xs="10">
                      <Notice>{t("edit_profile")}</Notice>
                    </Col>

                    <Col xs="2" className="text-right">
                      <Button type="submit" disabled={isSubmitting}>
                        {!isSubmitting
                          ? t("common:form.labels.save")
                          : t("common:form.labels.saving")}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </FormBox>
        </MetaInfo>
      </Modal>
    </EditProfileStyled>
  );
};

EditProfile.propTypes = {
  curriculum: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  handleUser: PropTypes.func.isRequired,
  loadCurriculum: PropTypes.func.isRequired,
};

export default EditProfile;
