import styled from 'styled-components';
import { Col } from 'reactstrap';

export const CardStyled = styled(Col)`
  
`;

export const Desc = styled.div`
  font-size: 0.875em;
  font-style: normal !important;
  color: #2d2c2c;  
  margin-bottom: 40px;
`;
