/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Spinner from 'reactstrap/es/Spinner';
import { store } from 'react-notifications-component';
import PreviewCard from '../../../PreviewCard';
import { getUrl } from '../../utils';
import api from '../../../../services/api';
import WriteComments from '../../WriteComments';
import CommentsChildren from '../CommentsChildren';
import NoAvatar from '../../../../assets/images/no-avatar.png';
import settings from '../../../../services/settings';
import {
  AuthorComment,
  AuthorTitle,
  Avatar,
  BottonsReply,
  Box,
  BoxBody,
  BoxFooter,
  Comment as CommentStyled,
  CreatedAt,
  Reply,
  ShowReply,
  ButtonDelete,
  Bottons,
  CommentsImage,
  Image,
  AvatarLink,
} from './styles';
import { getCurrentUser, loadAvatar } from '../../../../services/endpoints';
import HandleRefreshContext from '../handleRefreshContext';
import { createTextLinks } from '../../../../utils/createTextLinks';

const Comment = ({
  comment, post, mentionedComment,
}) => {
  const { t } = useTranslation('timeline');
  const { t1 } = useTranslation('common');
  const [previewUrl, setPreviewUrl] = useState(null);
  const [replyActive, setReplyActive] = useState(false);
  const [showCommentsChildren, setShowCommentsChildren] = useState(false);
  const [loadingComments, setLoadingComments] = useState(false);
  const user = getCurrentUser();
  const comments = comment;
  const [avatar, setAvatar] = useState([]);
  const { handleRefreshCommentsParents } = useContext(HandleRefreshContext);
  const { handleRefreshComments } = useContext(HandleRefreshContext);

  useEffect(() => {
    setPreviewUrl(getUrl(comment.text));
    return () => {
      setPreviewUrl(null);
    };
  }, []);

  useEffect(() => {
    if (mentionedComment === '') {
      setShowCommentsChildren(false);
    } else {
      setShowCommentsChildren(true);
    }
  }, []);

  function handleAvatar() {
    loadAvatar(comment.user.id).then((response) => {
      setAvatar(response.data);
    }).catch((error) => {
      store.addNotification({
        ...settings.notifications,
        message: t1('notifications.danger.insert_image', error),
        type: 'danger',
      });
    });
  }

  useEffect(() => {
    handleAvatar();
  }, []);

  const MediaContent = () => {
    switch (comments.type) {
      case 'VIMEO_MEDIA':
        return (
          <div className="embed-responsive embed-responsive-16by9 pr-3 pl-3 mt-3">
            <iframe src={`https://player.vimeo.com/video/${post.vimeo_media_reference}`} title={post.vimeo_media_reference} frameBorder="0" allowFullScreen />
          </div>
        );
      case 'FILE':
        return (
          <img className="img-fluid" alt="imagem do post" src={comments.file.urlPublica} />
        );
      default:
        return false;
    }
  };

  const toogleReplyActive = () => {
    setReplyActive((prevState) => !prevState);
  };

  const handleShowCommentsChildren = () => {
    setLoadingComments(true);

    setInterval(() => {
      setLoadingComments(false);
      setShowCommentsChildren(true);
    }, 1000);
  };

  const getUserName = () => {
    if (comment != null && comment.user != null && comment.user.name != null) {
      return comment.user.name;
    }
    return '';
  };

  const getUserId = () => {
    if (comment != null && comment.user != null && comment.user.id != null) {
      return comment.user.id;
    }
    return null;
  };

  const deleteComments = (id) => {
    api.delete(`/general/comments/${id}`).then((response) => {
      handleRefreshCommentsParents();
      handleRefreshComments();
    }).catch((error) => {
      console.log(error);
    });
  };

  const handleDeleteComments = (id, e) => {
    e.preventDefault();
    if (comment.description.indexOf('href') !== -1) {
      api.get(`/general/notifications?match[item_iri]=/general/comments/${id}`).then((response) => {
        const notifications = response.data;
        if (!notifications.length) {
          const mentionId = notifications.item_iri.split(',');
          api.delete(`/social/mentions/${mentionId[0].replace('/social/mentions/', '')}`);
          api.delete(`/general/notifications/${notifications.id}`);
        } else {
          notifications.map((notification) => {
            const mentionId = notification.item_iri.split(',');
            api.delete(`/social/mentions/${mentionId[0].replace('/social/mentions/', '')}`);
            api.delete(`/general/notifications/${notification.id}`);
            return null;
          });
        }
        deleteComments(id);
      });
    } else {
      deleteComments(id);
    }
  };

  return (
    <CommentStyled id={`comment-${comment.id}`}>
      <AvatarLink to={`/profile/${comment.user.id}`}>
        {comments.id && avatar[0] && avatar[0].file ? (
          <Avatar src={avatar[0].file.urlPublica} />
        ) : (
          <Avatar src={NoAvatar} />
        )}
      </AvatarLink>
      <Box>
        <BoxBody>
          <AuthorComment>
            <AuthorTitle to={`/profile/${getUserId()}`}>
              {getUserName()}
            </AuthorTitle>
            <CreatedAt>{comments.created_at}</CreatedAt>
          </AuthorComment>

          <div dangerouslySetInnerHTML={{ __html: createTextLinks(comments.description) }} />
          {previewUrl !== null && <PreviewCard url={previewUrl} />}

          {(comments.file !== null && comments.type === 'FILE')
            ? (
              <CommentsImage>
                <Image>{MediaContent()}</Image>
              </CommentsImage>
            )
            : (comments.file === null && comments.type === 'VIMEO_MEDIA')
              ? (
                <CommentsImage>
                  {MediaContent()}
                </CommentsImage>
              ) : <></>}

          <Bottons>
            <BottonsReply>
              <Reply onClick={toogleReplyActive}>
                {!replyActive ? t('reply') : t('cancel')}
              </Reply>

              {comments.children.length > 0
              && showCommentsChildren !== true && (
                <ShowReply onClick={handleShowCommentsChildren}>
                  {comments.children.length === 1 ? t('show_replys', { number: comments.children.length }) : t('show_replys_plural', { number: comments.children.length }) }
                  <i className="fas fa-caret-right" />
                </ShowReply>
              )}
            </BottonsReply>

            {user.id === comments.user.id
              ? <ButtonDelete onClick={(event) => handleDeleteComments(comment.id, event)}>Excluir</ButtonDelete>
              : <></>}
          </Bottons>
        </BoxBody>

        <BoxFooter>
          {loadingComments === true && (
            <div className="text-center mb-30">
              <Spinner />
            </div>
          )}

          {showCommentsChildren === true && comment.children.length > 0
          && <CommentsChildren comments={comment} mentionedComment={mentionedComment} />}

          {replyActive === true && (
            <>
              <WriteComments replyId={comment.id} focus={replyActive} post={comment.parent ? comment.parent : comment} />
            </>
          )}
        </BoxFooter>
      </Box>
    </CommentStyled>
  );
};

Comment.propTypes = {
  comment: PropTypes.object.isRequired,
  post: PropTypes.object.isRequired,
  mentionedComment: PropTypes.string,
};

export default Comment;
