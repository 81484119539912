import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Comment = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  //margin-bottom: 30px;
`;

export const Avatar = styled.img`
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 15px;
`;

export const Box = styled.div`
  width: 100%;
  position: relative;
  color: #313131;
  &:before {
    content: "";
    border-right: 10px solid #eee;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    position: absolute;
    top: 15px;
    left: -10px;
  }
`;

export const ButtonDelete = styled.span`
  display: none;
  margin-right: 15px;
  color: #777676;
  margin-top: 15px;
  margin-bottom: 15px;
  cursor: pointer;
  font-size: 15px;
  &:hover {
    text-decoration: underline;
  }
`;

export const BoxBody = styled.div`
  background: #eee;
  padding: 15px;
  margin-bottom: 30px;
  border-radius: 10px;
  &:hover ${ButtonDelete} {
    display: block;
  }
`;

export const BoxFooter = styled.div`
  /*${BoxBody} {
    margin-bottom: 0;  
  }*/
`;

export const BottonsReply = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 15px;
  > div {
    display: inline-block;
    cursor: pointer;
  }
`;

export const Bottons = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Reply = styled.div`
  margin-right: 15px;
  color: #777676;
  &:hover {
    text-decoration: underline;
  }
`;

export const ShowReply = styled.div`
  color: #004c94;
  font-weight: bold;
  &:hover{
    text-decoration: underline;
  }  
  i {
    padding-left: 5px;
    font-size: 24px;
    vertical-align: sub;
    text-decoration: none;
  }
`;

export const AuthorComment = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const AuthorTitle = styled(Link)`
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 15px;
  flex: 1;
  &,
  &:hover {
    color: #2d2c2c;
  }
`;

export const CreatedAt = styled.time`
  margin-bottom: 15px;
  font-size: 12px;
  color: #b3b3b3;
`;

export const CommentsImage = styled.div`
text-align: center;
`;

export const Image = styled.div`
  margin-top: 20px
`;

export const AvatarLink = styled(Link)`

`;
