import moment from 'moment';
import api from './api';

export const getCurrentUser = () => JSON.parse(localStorage.getItem(`${process.env.REACT_APP_PREFIX}.user`));

export const loadUser = () => api.get('/users/me');

export const loadUsers = (_params = {}) => api.get('/users', {
  params: { ..._params },
});

export const changePasswordUser = (params) => api.post(`/users/${getCurrentUser().id}/change_password`, params);

// endpoints /cms/menus
export const loadMenus = () => api.get('/cms/menus?match[title]=menu');

// endpoints /social/posts
export const loadPosts = (_params = {}, extra = {}) => api.get('/social/posts', {
  ...extra,
  params: {
    'order[created_at]': 'desc', ..._params,
  },
});

export const handlePosts = (_params = {}, extra = {}) => api.get('/social/posts', {
  ...extra,
  params: {
    ..._params,
  },
});

export const submitPost = (postData, callback) => {
  api.post('/social/posts', { user_iri: `/users/${getCurrentUser().id}`, ...postData })
    .then(callback);
};

// endpoints /general/users
// export const loadUsers = () => api.get('/users');

export const loadLeadership = (_params = {}) => api.get("/general/field_values?wherein[field.title]='Lideranca'&match[value]=true&order[user.name]=asc", {
  params: {
    ..._params,
  },
});

export const fetchDirectories = async (_params = {}) => api.get("/general/field_values?wherein[field.title]='Diretorio'&match[value]=true", {
  params: {
    ..._params,
  },
});

export const handleUsersCategories = (_params = {}) => api.get("/general/field_values?wherein[field.title]='Categoria'", {
  params: {
    ..._params,
  },
});

export const updateUsers = (userId, values) => api.put(userId, values);

export const updateField = (id = null, data) => {
  if (id) {
    return api.put(`/general/field_values/${id}`, data);
  }

  return api.post('/general/field_values', data);
};

// endpoints /general/field_domain_values
export const loadCategories = () => api.get('/general/field_domain_values?field.title=Categoria&order[value]=asc');

export const loadStates = (_params = {}) => api.get('/general/field_domain_values?field.title=UF&order[value]=asc', {
  params: _params,
});

export const loadFieldById = async (value) => api.get(`/general/field_domain_values?id=${value}`);

export const loadStateByName = (value) => api.get(`/general/field_domain_values?match[value]=${value}`);

// endpoints /general/fields
export const loadFields = () => api.get('/general/fields');

export const loadFieldsAsync = async () => api.get('/general/fields');

export const loadFieldsCurriculum = () => api.get('/general/fields?detail_title=curriculos');

// endpoints /general/field_values
export const loadFieldValues = (userIri) => api.get(`/general/field_values?user_iri=${userIri}`);

export const loadFieldValuesAsync = async (userIri) => api.get(`/general/field_values?user_iri=${userIri}`);

export const loadFieldValuesCurriculum = async (userIri) => api.get(`/general/field_values?user_iri=${userIri}&detail_title=curriculos`);

export const updateFieldValues = (data = []) => api.post('/general/fieldValuesBatch', data);

export const updateFieldValuesCurriculum = (data) => api.post('/general/fieldValuesBatch', data);

export const removeFieldValuesCurriculum = () => api.delete('/general/field_values/delete?detail_title=curriculos');

export const loadAvatar = (userId) => api.get(`/general/field_values?user_iri=/users/${userId}&match[field.title]=avatar`);

export const loadCover = (userId) => api.get(`/general/field_values?user_iri=/users/${userId}&match[field.title]=cover`);

// endpoints /cms/contact_messages
export const sendContactMessages = (values) => api.post('/cms/contact_messages', {
  content: values.message,
  subject: values.subject,
  user_iri: `/users/${getCurrentUser().id}`,
  ip: '0.0.0.0',
  user_agent: navigator.userAgent,
});

// endpoints /general/evaluations

export const loadUsersLike = (idPost, type) => api.get(`/general/evaluations?type=${type}&content_iri=/social/posts/${idPost}&required_user=false`);

export const loadEvaluation = (idPost) => api.get(`/general/currentUserEvaluations?content_iri=/social/posts/${idPost}`);

export const setEvaluation = (value, type, content_iri, user_iri) => api.post('/general/evaluations', {
  value,
  type,
  content_iri,
  user_iri,
});

// endpoints /lms/courses
export const loadCourses = (params = {}) => api.get('lms/courses', {
  params: {
    ...params,
  },
});

export const loadCourseStats = (params = {}) => api.get('/lms/course_stats', {
  params: {
    ...params,
  },
});

export const loadCourseStatsId = (id) => api.get(`/lms/course_stats/${id}`);


// endpoints /lms/courses/slug
export const loadSpecificCourse = (slug) => api.get(`lms/courses/?slug=${slug}`);

// /general/notifications?user_iri=/users/6&&viewed=true quando danilo arrumar o problema
export const loadNotifications = (id) => api.get(`/general/notifications?user_iri=/users/${id}&viewed=false&order[moment]=desc`);

export const sendMentionsAndNotifications = (postId, message, type, result, commentId) => {
  result.forEach((mentionedUser) => {
    api.post('/social/mentions', {
      post: `/social/posts/${postId}`,
      mentioned_user_iri: `/users/${mentionedUser}`,
      user_iri: getCurrentUser().id,
    }).then((response) => {
      const time = moment().format('YYYY-MM-DD HH:mm:ss');
      api.post('/general/notifications', {
        title: `${getCurrentUser().name} marcou você em ${type} `,
        description: message,
        moment: time,
        user_iri: `/users/${mentionedUser}`,
        created_by_iri: `/users/${getCurrentUser().id}`,
        viewed: 'false',
        item_iri: type === 'comentário' ? `/social/mentions/${response.data.id},/social/posts/${postId},/general/comments/${commentId}`
          : `/social/mentions/${response.data.id},/social/posts/${postId}`,
      });
    });
  });
};

// endpoints /social/connections

export function fetchConnections(user2Id = undefined, _params = {}) {
  return api.get(`social/connections?user_iri=/users/${getCurrentUser().id}${user2Id ? `&user2_iri=/users/${user2Id}` : ''}`, {
    params: {
      ..._params,
    },
  });
}

export function followConnection(followId) {
  return api.post('/social/connections', {
    user_iri: `/users/${getCurrentUser().id}`,
    user2_iri: `/users/${followId}`,
  });
}

export function unfollowConnection(unfollowId) {
  return api.delete(`/social/connections/${unfollowId}`);
}

export function fetchSegments(segmentId = undefined, _params = {}) {
  return api.get(`social/connections?user_iri=/users/${getCurrentUser().id}${segmentId ? `&segment_iri=/general/field_domain_values/${segmentId}` : ''}`, {
    params: {
      ..._params,
    },
  });
}

export function followSegment(followId) {
  return api.post('/social/connections', {
    user_iri: `/users/${getCurrentUser().id}`,
    segment_iri: `/general/field_domain_values/${followId}`,
  });
}

export function unfollowSegment(unfollowId) {
  return api.delete(`/social/connections/${unfollowId}`);
}

// endpoints /cms/banners

export const fetchBanners = () => api.get('cms/banners');

// endpoints /social/lives

export const fetchLives = () => api.get('/general/lives?order[ended_at desc, started_at]=desc');
