import VimeoUpload from '@coassemble/vimeo-upload';
import api from '../services/api';

export const uploadVideo = async (videoFile, progressMutator, token) => new Promise((resolve, reject) => {
  const uploader = new VimeoUpload({
    url: `https://${process.env.REACT_APP_API_URL}/social/vimeomedia/createmedia`,
    file: videoFile,
    token,
    name: videoFile.name,
    onProgress(progress) {
      if (progress.total) {
        const currentProgress = (progress.loaded / progress.total) * 100;
        progressMutator(currentProgress.toFixed(2));
      }
    },
    onComplete(data) {
      return resolve(data);
    },
    onError(err) {
      return reject(err);
    },
  });
  uploader.upload();
});


export const sendVideo = (url, videoId) => api.put(url, { type: 'VIMEO_MEDIA', vimeo_media_reference: videoId });

export const activateVideo = (videoId) => api.get(`/social/vimeomedia/activatemedia?id=${videoId}`);

export const processVideo = async (currentFile, percentageMutator, token, url) => {
  const videoId = await uploadVideo(currentFile, percentageMutator, token);
  await activateVideo(videoId);
  await sendVideo(url, videoId);
};

export const sendImage = async (url, imageFile) => {
  try {
    const data = new FormData();
    data.append('file', imageFile);
    await api.post(`${url}/upload/file`, data);
    await api.put(url, { type: 'FILE' });
    return (true);
  } catch (error) {
    console.error(error);
    throw (error);
  }
};
