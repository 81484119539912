import styled, { css } from 'styled-components';

const card = css`
  border: 1px solid #d2d2d2;
  border-radius: 10px;
  padding: 30px;
  height: 103px;
  margin-bottom: 20px;
`;

export const ProgressStyled = styled.div`
    border-bottom: 1px solid #d2d2d2;
`;
export const Course = styled.div`
  ${card};
`;

export const Title = styled.h3`
  color: #2d2c2c;
  font-size: 0.9375em;
`;

export const Percentage = styled.div``;

export const Certified = styled.div`
  ${card};
`;

export const Icon = styled.i`
  font-size: 1.875em;
`;

export const Link = styled.a`  
  color: #2d2c2c;
  font-size: 0.9375em;
  margin-left: 10px;
  &:hover{
    color:#2d2c2c;
    text-decoration: underline;
  }
`;

export const ProgressBarStyle = styled.div`
  grid-area: ProgressBar;
  .progress {
    display: flex;
    height: 7px;
    background-color: #e5e5e5;
    border-radius: 5px;
  }
  
  .progress-bar {
    white-space: nowrap;
    border-radius: 10px;
    background-color: #004c94;
  }
`;
