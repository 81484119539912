import Cookie from "js-cookie";

export function getToken() {
  return Cookie.get(process.env.REACT_APP_TOKEN);
}
export function getSession() {
  return Cookie.get("sessionId");
}

export async function isAuthenticated() {
  return true;
}

export const createCookie = (response) => {
  if (window.location.origin.indexOf("localhost") !== -1) {
    Cookie.set(process.env.REACT_APP_TOKEN, response.data.token);
  }

  Cookie.set(process.env.REACT_APP_TOKEN, response.data.token, {
    domain: process.env.REACT_APP_DOMAIN,
    secure: true,
  });
};
