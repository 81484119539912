import styled from 'styled-components';
import { Row } from 'reactstrap';
import { Link } from 'react-router-dom';

export const CardHeader = styled.div`
  background: #fff;
  padding: 15px 0 15px 0;
  margin-right: 7px;  
  border-top: 1px solid #d3d3d3;
  &:last-child{
    border-bottom: 1px solid #d3d3d3;
  }
`;

export const Image = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  grid-area: "image"; 
`;

export const Name = styled.p`
  font-weight: bold;
  font-size: 14px;
  text-transform: capitalize;
  margin-top: 10px;
  margin-bottom: 0;
  grid-area: "name"; 
  overflow: hidden;
 `;

export const Role = styled.p`
  color: #707070
  font-size: 13px;
  text-transform: capitalize;
`;

export const ProfileBtn = styled.button.attrs({
  className: 'btn btn-default',
})`    
`;

export const CustomRow = styled(Row)`  
  
`;

export const LinkCustom = styled(Link)`  
  text-decoration: none;
  &:hover{
    text-decoration: none;
  }
`;

export const Grid = styled.div`
  display: grid; 
  grid-template-columns: 1fr 3fr;
  grid-template-areas: "image" "name";
  margin-bottom: 10px;
`;

export const Line = styled.div`
  margin-top: 10px;
  border-bottom: 1px solid #d3d3d3;  
`;
