/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Header,
  Icon,
  Section,
  Text,
  Title,
  IconAlert,
} from './styles';
import Cards from './Cards';
import { getCurrentUser, loadNotifications } from '../../services/endpoints';

const NotificationsWidget = ({ profile = false }) => {
  const node = useRef({});
  const { t } = useTranslation('notifications');
  const [numberNotifications, setNumberNotifications] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [active, setActive] = useState(false);
  let time = null;

  const handleClickOutside = (e) => {
    if (node.current.contains(e.target)) {
      return;
    }
    setActive(false);
  };

  const getNotifications = () => {
    loadNotifications(getCurrentUser().id)
      .then((response) => {
        setNotifications(response.data);
        setNumberNotifications(response.data.length);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (active) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [active]);

  useEffect(() => {
    getNotifications();
    if (time === null) {
      time = setInterval(() => {
        getNotifications();
      }, 30000);
    }

    return () => {
      setNotifications([]);
      clearInterval(time);
      time = null;
    };
  }, []);

  const showNotifications = () => {
    if (numberNotifications > 0) {
      setActive((prevState) => !prevState);
      getNotifications();
    }
  };

  const RenderCards = () => {
    if (active) {
      return (
        <>
          <Cards cards={notifications} getNotifications={getNotifications} />
        </>
      );
    }
    return <></>;
  };

  return (
    <Box ref={node}>
      <Header active={active}>
        <Icon
          className={`fas fa-bell${numberNotifications ? ' active' : ''}`}
          onClick={showNotifications}
        />
        {(numberNotifications > 0) && (
        <IconAlert>
            {numberNotifications}
        </IconAlert>
        )}
        <Title onClick={showNotifications}>{t('notifications')}</Title>
      </Header>

      {(active) && (numberNotifications > 0) && (
        <Section>
            {numberNotifications > 0 ? (
              <Text
                dangerouslySetInnerHTML={{
                  __html: t('message-alert', { count: numberNotifications }),
                }}
              />
            ) : (
              <Text dangerouslySetInnerHTML={{ __html: t('no-notifications') }} />
            )}
          <RenderCards />
        </Section>
      )}
    </Box>
  );
};

NotificationsWidget.propTypes = {
  profile: PropTypes.bool,
};

export default NotificationsWidget;
