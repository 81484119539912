import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CardItem from '../CardItem';
import { fetchConnections } from '../../../../services/endpoints';
import {
  Card, Title, Button, CardBody, LinkCustom,
} from '../styles';

const CardFollow = () => {
  const { t } = useTranslation('common');
  const [allConnections, setAllConnections] = useState([]);

  const handleConnections = async () => {
    const connections = [];

    await fetchConnections()
      .then((response) => {
        if (response.data.length > 0) {
          response.data.map((connection) => connections.push(connection.user2));
        }
      })
      .catch((error) => {
        console.error(error);
      });

    setAllConnections(connections);
  };

  useEffect(() => {
    handleConnections();
  }, []);

  return (
    <Card>
      <Title>{t('tabs.follow')}</Title>
      <CardBody>
        {allConnections.length > 0
          && allConnections.map((user, index) => <CardItem user={user} key={index} />)}
      </CardBody>
      <LinkCustom to="/liderancas-quem-eu-sigo?tab=1">
        <Button>{t('buttons.see_all_following')}</Button>
      </LinkCustom>
    </Card>
  );
};

export default CardFollow;
