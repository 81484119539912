import styled, { css } from 'styled-components';
import { FormGroup as FormGroupBT } from 'reactstrap';

export const EditProfile = styled.div`
  margin-bottom: 30px;
`;

export const Box = styled.div`
  padding: 15px 0;
  margin-bottom: 20px;
}
`;

export const Title = styled.div`
  color: #004c94;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 20px;
`;

export const Period = styled.span`
  font-size: 12px;
  padding-top: 20px;
`;

export const MyData = styled.div``;

export const MyDataTitle = styled.div`
  flex-basis: 80%;
  width: 80%;
  color: #ffffff;
  font-weight: bold;
  font-size: 25px;
`;

export const MyDataButton = styled.button.attrs({
  className: 'btn btn-default',
})`  
  width: 50% !important;
  margin: 35px 0 0 115px !important;
  display: inline-block !important;
  padding-left: 30px;
  padding-right: 30px;
  transition: all 400ms linear !important;
  i{
    vertical-align: initial !important;
    font-size: 14px !important;
  }
`;

const placeholder = css`
  color: #2d2c2c;
  font-weight: bold;
`;

export const FormGroup = styled(FormGroupBT)`
  color: #2d2c2c;
  font-weight: bold;
  margin-bottom: 30px;
  position: relative;
  transition: width 300ms linear;

  label {
    font-size: 12px;
    display: block;
    margin-bottom: 0;
    color: #777676;
  }

  .form-input,
  .form-textarea {
    font-size: 15px;
    width: 100%;
    outline: none;

    &::-webkit-input-placeholder {
      ${placeholder}
    }
    &:-moz-placeholder {
      ${placeholder} opacity: 1;
    }
    &::-moz-placeholder {
      ${placeholder} opacity: 1;
    }
    &:-ms-input-placeholder {
      ${placeholder}
    }
    &::-ms-input-placeholder {
      ${placeholder}
    }
    &::placeholder {
      ${placeholder}
    }
  }

  .form-input {
    border: 0;
    border-radius: 0;
    font-weight: bold;
    color: #2d2c2c;
    border-bottom: 1px solid #2b2b2b;
    padding: 5px 0;
    height: 38px;
    &:focus {
      border-bottom: 1px solid #ea4747;
    }
  }
  .form-textarea {
    border: 1px solid #2b2b2b;
    padding: 15px;
    resize: none;
    border-radius: 5px;
  }

  .error {
    position: absolute;
    bottom: -20px;
    height: 20px;
    background: #ea4747;
    right: 0;
    font-size: 12px;
    color: #fff;
    padding: 0 3px;
    border-radius: 4px;
    &:after {
      content: "";
      position: absolute;
      top: -7px;
      right: 10px;
      border-bottom: 7px solid #ea4747;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
    }
  }
`;

export const MetaInfo = styled.div`
  border: 1px solid #bfbfbf;
  border-top: 0;
  margin-top: -3px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;

export const Button = styled.button.attrs({ className: 'btn btn-primary' })`
  .btn {
    width: 70% !important;
  }
  display: inline-block !important;
  padding-left: 30px;
  padding-right: 30px;  
`;

export const ButtonModal = styled.button.attrs({
  className: 'btn btn-default',
})`
  width: 30% !important;
  margin: 35px 0 0 115px !important;
  display: inline-block !important;
  padding-left: 30px;
  padding-right: 30px;
  transition: all 400ms linear !important;
`;

export const ButtonAdd = styled.button.attrs({
  className: 'btn btn-white-yellow',
})`
  &:hover {
    background-color: #ffffff !important;
    border-color: #7d7d7d !important;
  }
`;

export const ButtonDel = styled.div`
  cursor: pointer;
  color: #7d7d7d;
`;

export const Notice = styled.div`
  font-size: 12px;
  font-weight: bold;
  padding: 15px 0;
`;

export const FormBox = styled.div`
  padding: 0 80px 30px 80px;
`;

export const Icon = styled.i`
  margin-right: 5px;
  vertical-align: sub;
  font-size: 18px;
`;
