import PropTypes from 'prop-types';
import {
  Modal,
} from 'reactstrap';
import React, { useState } from 'react';
import LikeToggle from './components/LikeToggle';
import {
  Box, Comments, Likes, Count,
} from './styles';

const LikesAndComments = ({ likeCount, commentsCount, post }) => {
  const comment = commentsCount > 1 ? 'Comentários' : 'Comentário';
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  return (
    <Box>
      <div>
        <Modal
          isOpen={modal}
          fade={false}
          toggle={toggle}
          style={{
            margin: '5% 0 0 29%',
            maxWidth: '840px',
          }}
        >
          <LikeToggle post={post} toggle={toggle} />
        </Modal>
      </div>

      <Likes onClick={toggle}>
        <i className="fas fa-thumbs-up" />
        <Count>{likeCount}</Count>
      </Likes>

      <Comments>
        <i className="fas fa-comment-alt" />
        <Count>
          {commentsCount}
          {' '}
          {comment}
        </Count>
      </Comments>
    </Box>
  );
};

LikesAndComments.propTypes = {
  likeCount: PropTypes.number.isRequired,
  commentsCount: PropTypes.number.isRequired,
  post: PropTypes.object.isRequired,
};

export default LikesAndComments;
