import React from 'react';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import UsersItem from '../UsersItem';

const UsersList = ({ users }) => (
  <Row>
      {users.map((user) => (
        <Col xs={12} sm={4} key={user.id}>
          <UsersItem user={user} />
        </Col>
      ))}
    </Row>
);

UsersList.propTypes = {
  users: PropTypes.array,
};

export default UsersList;
