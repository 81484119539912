/* eslint-disable import/prefer-default-export */
import striptags from 'striptags';
import { sendMentionsAndNotifications } from '../services/endpoints';

export const submitMentionAndNotification = (postId, values, type, commentId) => {
  const result = new Set();
  let userMatch = '';
  const message = striptags(values);
  const taggedUsers = values.split('</a>');

  taggedUsers.map((user) => {
    userMatch = user.match("(href=[\\\"\\'])([^\\\"\\']+)[\\\"\\']");
    if (userMatch !== null) {
      result.add(userMatch[2].replace('/profile/', ''));
    }
    return '';
  });
  sendMentionsAndNotifications(postId, message, type, result, commentId);
};
