
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  Col, Container, Row, Spinner, Modal, ModalBody, ModalFooter, Button,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import CoverImage from '../../components/CoverImage';
import ProfileBar from '../../components/ProfileBar';
import BaseProfile from '../../components/Theme/profile';
import Posts from '../../components/Timeline/Posts';
import WritePostToggle from '../../components/Timeline/WritePostToggle';
import { colourStyles } from '../../utils/colourStyles.js';
import {
  getCurrentUser, handlePosts, fetchSegments, loadStateByName, fetchDirectories,
} from '../../services/endpoints';
import EditProfile from './components/EditProfile';
import HandleRefreshContext from '../../components/Timeline/PostItem/handleRefreshContext';
import PublicCurriculum from '../../components/PublicCurriculum';
import { loadCurriculumFields } from '../../utils/profileInfo';
import Aside from '../../components/Widgets/Aside';
import CardLeadership from '../../components/Widgets/LeadershipAndFollow/CardLeadership';
import CardFollow from '../../components/Widgets/LeadershipAndFollow/CardFollow';
import CoursesList from '../../components/Widgets/Courses/CoursesList';
import { Card } from './styles';
import { _handleFollow } from '../../utils/Follow';

const Profile = () => {
  const { t } = useTranslation(['profile']);
  const [user, setUser] = useState(getCurrentUser());
  const itemsPerPage = 5;
  const [loadingItem, setLoadingItem] = useState(false);
  const [numCurrentItens, setNumCurrentItens] = useState(itemsPerPage);
  const [page, setPage] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [userProfile, setUserProfile] = useState([]);
  const [selectedOption, setSelectedOption] = useState();
  const options = [
    {
      value: 'asc',
      label: t('order_asc'),
    },
    {
      value: 'desc',
      label: t('order_desc'),
    },
  ];
  const [modal, setModal] = useState(true);
  const toggle = () => setModal(!modal);
  const [curriculum, setCurriculum] = useState([]);

  const loadCurriculum = async () => {
    const curriculumFields = await loadCurriculumFields(`/users/${user.id}`);
    setCurriculum(curriculumFields);
  };

  useEffect(() => {
    loadCurriculum();

    return () => {
      setCurriculum([]);
    };
  }, []);

  const loadState = () => {
    const connections = [];
    const stateValue = user.profile.uf
      ? user.profile.uf.value
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
      : '';
    fetchSegments().then((response) => {
      if (response.data.length > 0) {
        response.data.filter((connection) => (connection.segment_iri !== null ? connections.push(connection.segment.id) : ''));
      }
      if (stateValue !== '') {
        fetchDirectories().then((r) => {
          const result = (r.data.filter((item) => item.user.name === user.profile.uf.value));
          const userId = result[0].user.id;
          loadStateByName(stateValue).then((r) => {
            _handleFollow(connections, r.data.length > 0 ? r.data[0].id : '', userId);
          }).catch((error) => {
            console.error(error);
          });
        });
      }
    }).catch((error) => {
      console.error(error);
    });
  };

  function handleRefreshPosts(filter = {}, pageNumber = currentPage, shouldRefresh = false) {
    setLoadingItem(true);
    handlePosts({
      user_iri: `/users/${user.id}`,
      perPage: `${itemsPerPage}`,
      page: `${pageNumber}`,
      'order[created_at]': Object.values(filter).length > 0 ? filter.value : 'desc',
    }).then((response) => {
      const posts = response.data || [];
      setLoadingItem(false);
      setNumCurrentItens(posts.length);
      setPage((old) => (shouldRefresh ? posts : [...old, ...posts]));
      setCurrentPage(pageNumber + 1);
    }).catch((error) => {
      console.log(error);
    });
    return null;
  }

  function handleUser() {
    const u = getCurrentUser();
    setUser(u);
    setUserProfile(u.profile.uf ? u.profile.uf.value : '');
  }

  useEffect(() => {
    handleUser();
    setLoadingItem(true);
    handleRefreshPosts({}, 0, true);
  }, []);

  window.onscroll = function () {
    if ((numCurrentItens === itemsPerPage) && (window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 10) && loadingItem === false) {
      handleRefreshPosts();
    }
  };

  function filterPosts(e) {
    setSelectedOption(e);
    handleRefreshPosts(e, 0, true);
  }

  return (
    <BaseProfile>

      <div className="box-content">
        <Container>
          <Card>
            <CoverImage user={user} isCurrentUser />
            <Row>
              <Col xs={12} md={8}>
                <ProfileBar user={user} isCurrentUser />
              </Col>
              <Col xs={12} md={4}>
                <EditProfile loadState={loadState} user={user} handleUser={handleUser} curriculum={curriculum} loadCurriculum={loadCurriculum} />
              </Col>
            </Row>
          </Card>
          {curriculum.length > 0 && (
            <Card>
              <PublicCurriculum user={user.id} curriculum={curriculum} />
            </Card>
          )}

          <Row className="align-items-start">
            <Col xs={12} sm={3}>
              <Aside />
              <CardLeadership />
              <CardFollow />
              <CoursesList />
            </Col>
            <Col xs={12} sm={9}>

              {userProfile.length > 0 ? (
                <>
                  <WritePostToggle handleRefreshPosts={handleRefreshPosts} />
                  <Row>
                    <Col xs={12} sm={6}>
                      <Select
                        onChange={filterPosts}
                        value={selectedOption}
                        options={options}
                        placeholder={t('view_posts')}
                        styles={colourStyles}
                      />
                    </Col>
                  </Row>

                  <HandleRefreshContext.Provider value={{ handleRefreshPosts }}>
                    <Posts posts={page} />
                  </HandleRefreshContext.Provider>

                  {numCurrentItens === itemsPerPage && (
                  <div className="text-center mb-5">
                    {!loadingItem && (
                      <div className="text-center">
                        <Spinner color="dark" />
                      </div>
                    )}
                  </div>
                  )}
                </>
              ) : (<h3 className="text-center">Você deve preencher seu perfil para criar posts!</h3>)}


            </Col>
          </Row>

          <Modal isOpen={modal && !user.profile.uf} toggle={toggle} style={{ paddingTop: '20%' }}>
            <ModalBody>{t('new_user_state')}</ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggle}>
                {t('close')}
              </Button>
            </ModalFooter>
          </Modal>
        </Container>
      </div>
    </BaseProfile>
  );
};

export default Profile;
