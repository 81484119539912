import styled from 'styled-components';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Input } from 'reactstrap';
import TabPanel from '../../utils/TabPanel';


export const Select = styled(Input).attrs({ type: 'select', className: 'input-select' })`

`;

export const TabsCustom = styled(Tabs)`
  button{
    border-bottom: 3px solid #d3d3d3;
    margin: 0 10px;
    color: #d3d3d3;
  }
`;

export const TabCustom = styled(Tab)`
 span{
  font-weight: bolder;
  font-size: 18px;
 }  
`;

export const TabPanelCustom = styled(TabPanel)`
  padding: 20px 10px;
`;

export const Text = styled.span`
 font-size: 12px;
 line-height: 40px;
`;
