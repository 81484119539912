import React from 'react';
import NoAvatar from '../../../assets/images/no-avatar.png';
import {
  Date, Image, Item, Title, Box, Name,
} from './styles';

const CardItem = ({ card }) => {
  const findPost = card.item_iri.split(',');
  const postId = findPost[1].replace('/social/posts/', '');
  const commentId = findPost[2]
    ? findPost[2].replace('/general/comments/', '')
    : '';

  return (
    <>
      {card && (
        <Box
          to={
            commentId !== ''
              ? `/timeline/${postId}/comment/${commentId}`
              : `/timeline/${postId}`
          }
          target="_blank"
        >
          <Item>
            {card.hasOwnProperty('created_by')
            && card.created_by.profile.avatar ? (
              <Image src={card.created_by.profile.avatar.file.urlPublica} />
              ) : (
                <Image src={NoAvatar} />
              )}
            <Name>{card.created_by.name}</Name>
            <Title>{card.title}</Title>
            {/* <Description>{ReactHtmlParser(card.description)}</Description> */}
            <Date>{card.moment}</Date>
          </Item>
        </Box>
      )}
    </>
  );
};

export default CardItem;
