import styled from 'styled-components';
import TextareaAutosize from 'react-autosize-textarea';
import { MentionsInput } from 'react-mentions';
import { Link } from 'react-router-dom';
import { ProgressBarStyle } from '../../../pages/CoursesShow/Playlist/Progress/styles';

export const Section = styled.section`
  display: grid;
  grid-template-columns: 1fr 20fr;
  grid-template-areas: "writeCommentAvatar writeCommentComment"
                       "empty preview";
  margin-bottom: 30px;
`;

export const Image = styled.img`
  grid-area: writeCommentAvatar;
  width: 40px;
  height: 40px;
  object-fit: cover;
  margin: auto;
  border-radius: 50%;
`;

export const Preview = styled.div`
  margin-top: 30px;
  grid-area: preview;
  margin-left: 15px;
  display: grid;
  position: relative;
  grid-template-columns: 1fr 5fr;
  grid-template-areas: "col1 col2";
  grid-gap: 20px;
`;

export const PreviewImage = styled.img`
  grid-area: col1;
  height: 75px;
  width: 100%;
  object-fit: scale-down;
`;

export const PreviewDescription = styled.p`
  position: absolute;
  top: 47px;
  font-size: 12px;
  grid-area: col2;
  color: white;
  border-color: #ffef00;
  background-color: #004c94;
  border-radius: 4px;
  padding: 5px;
`;

export const PreviewImageRemoveIcon = styled.i`
  font-size: 25px;
  position: absolute;    
  top: -13px;
  color: red;
  cursor: pointer;
`;

export const PreviewProgressBar = styled(ProgressBarStyle)`
  grid-area: col2;
`;


export const TextAreaBody = styled.div`
  grid-area: writeCommentComment;
  position: relative;
  margin-left: 15px;
  &:before {
    content: "";
    border-right: 10px solid #eeeeee;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -10px;
  }
`;

const placeholder = `
  color: #707070;
`;

export const TextArea = styled(TextareaAutosize).attrs((props) => ({ rows: props.rows, maxRows: props.maxRows }))`
  border: none;
  background: #eee;
  padding: 11px 130px 11px 15px;
  border-radius: 10px;
  width: 100%;
  outline: none;
  resize: none;
  
  &::-webkit-input-placeholder {
    ${placeholder}
  }
  &:-moz-placeholder {
    ${placeholder}
    opacity: 1;
  }
  &::-moz-placeholder {
    ${placeholder}
    opacity: 1;
  }
  &:-ms-input-placeholder {
    ${placeholder}
  }
  &::-ms-input-placeholder {
    ${placeholder}
  }
  &::placeholder {
    ${placeholder}
  }
`;

export const MentionsInputStyled = styled(MentionsInput)`
  border: none;
  background: #eee;
  padding: 11px 130px 11px 15px;
  border-radius: 10px;
  width: 100%;
  outline: none;
  resize: none;
  textarea {
      border: 0;
      padding: 11px 130px 11px 15px;
      outline: none;      
  }  
  &::-webkit-input-placeholder {
    ${placeholder}
  }
  &:-moz-placeholder {
    ${placeholder}
    opacity: 1;
  }
  &::-moz-placeholder {
    ${placeholder}
    opacity: 1;
  }
  &:-ms-input-placeholder {
    ${placeholder}
  }
  &::-ms-input-placeholder {
    ${placeholder}
  }
  &::placeholder {
    ${placeholder}
  }
`;

export const ButtonSecondary = styled.label.attrs()`
  position: absolute;
  right: 5px;
  top: 10px;
  background: none;
  border: 0;
  color: #707070;
  cursor: pointer;

  input{
    display: none;
  }
  
  &:hover{
    color: #004c94;    
  }
  i {
    font-size: 18px;
    margin-right: 5px;
  }
  &:visited,
  &:focus,
  &:hover,
  &:active {
    outline: none;
  }
`;

export const AvatarLink = styled(Link)`

`;
