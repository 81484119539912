import styled, { css } from 'styled-components';

const font = css`
  font-size: 0.875em;
`;

export const Materials = styled.div`
  margin-bottom: 50px;

  @media (min-width: 576px) {
    margin-bottom: 0px;
  }
`;

export const Instruction = styled.h3`
  ${font};  
  font-weight: 400;
  margin-bottom: 30px;
`;

export const Icon = styled.i`
  color: #004c94;
`;

export const Description = styled.a`
  ${font};
  color: #2d2c2c;  
  font-weight: 500;  
  margin-left: 10px;
  &:hover {
    color: #2d2c2c;  
    text-decoration: underline;
  }
`;
