import styled from 'styled-components';
import { ImageAdjust } from '../styles';

export const Box = styled.div`
  color: #fff;
  position: absolute;
  bottom: 30px;
  left: 25px;
  font-size: 12px;
  z-index: 9;
  ${ImageAdjust}.no-image &{
    position: relative;
    color: #313131;
    left: inherit;
    bottom: inherit;
    margin: 20px 26px 0 26px;
  }
  i{
    font-size: 17px;
    margin-right: 5px;
    vertical-align: text-bottom;
  }
`;

export const Likes = styled.div`
  display: inline-block;
  margin-right: 30px;
  cursor: pointer;
`;

export const Comments = styled.div`
  display: inline-block;
`;

export const Count = styled.span`
  font-size: 14px;
  color: #212529;
`;
