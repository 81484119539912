import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Courses = styled.div`
  background: #fff;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
`;

export const Title = styled.div`
  color: #004e93;
  font-weight: bold;
  margin-bottom: 15px;
  font-size: 18px;
`;

export const Card = styled.div`
height: fit-content;
max-height: 380px;
overflow: auto;
overflow-x: hidden;

&::-webkit-scrollbar-track {
  background-color: #eee;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

&::-webkit-scrollbar {
  border-radius: 10px;
  width: 12px;
  background-color: #eee;
}

&::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #004c94;
}
`;

export const Button = styled.button.attrs({
  className: 'btn btn-primary',
})`  
  margin-top: 20px;  
`;

export const LinkCustom = styled(Link)`  
  text-decoration: none;
  &:hover{
    text-decoration: none;
  }
`;
