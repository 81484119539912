function calculateIdentity(str, weight) {
  let sum = 0;

  for (let i = str.length - 1, digit; i >= 0; i--) {
    digit = parseInt(str.substring(i, i + 1));
    sum += digit * weight[weight.length - str.length + i];
  }

  sum = 11 - sum % 11;
  return sum > 9 ? 0 : sum;
}

function cpfValidate(cpf) {
  const weight = [11, 10, 9, 8, 7, 6, 5, 4, 3, 2];

  cpf = cpf.replace(/\D/g, '');

  if ((cpf === null) || (cpf.length !== 11)) return false;

  const digit1 = calculateIdentity(cpf.substring(0, 9), weight);
  const digit2 = calculateIdentity(cpf.substring(0, 9) + digit1, weight);

  return cpf === cpf.substring(0, 9) + digit1.toString() + digit2.toString();
}

function cnpjValidate(str) {
  const weight = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];

  str = str.replace(/\D/g, '');

  if ((str === null) || (str.length !== 14)) return false;

  const digit1 = calculateIdentity(str.substring(0, 12), weight);
  const digit2 = calculateIdentity(str.substring(0, 12) + digit1, weight);

  return str === str.substring(0, 12) + digit1.toString() + digit2.toString();
}


export function isCPF(value = '') {
  if (value === undefined || value === null || !value.length) {
    return true;
  }
  return !!cpfValidate(value);
}

export function isCNPJ(value = '') {
  if (value === undefined || value === null || !value.length) {
    return true;
  }
  return !!cnpjValidate(value);
}
