import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  LiveButton,
  LiveButtonOffline,
  LiveMessage,
  LiveStatus,
  LiveStatusOffline,
  LiveTitle,
} from './styles';
import { convertToUSDate } from '../../../utils/convertToUSDate';
import { fetchLives } from '../../../services/endpoints';

const Live = () => {
  const { t } = useTranslation('notifications');
  const [online, setOnline] = useState(1);
  const [lives, setLives] = useState([]);

  const handleLives = () => {
    fetchLives().then((response) => {
      const data = response.data || [];
      const now = new Date().getTime();
      const r = data.filter((item) => convertToUSDate(item.started_at) <= now && convertToUSDate(item.ended_at) >= now);

      if (r.length) {
        setOnline(0);
      }
      setLives(r[0]);
    }).catch((error) => console.error(error));
  };

  useEffect(() => {
    handleLives();
  }, []);

  return (
    <div className="mt-4">
      {!online ? (
        <LiveButton to="/live" target="blank">
          <LiveStatus>{t('button-live-title')}</LiveStatus>
          <LiveTitle>{lives.title}</LiveTitle>
          <LiveMessage>{t('button-live')}</LiveMessage>
        </LiveButton>
      ) : (
        <LiveButtonOffline>
          <LiveStatusOffline>{t('button-live-title')}</LiveStatusOffline>
        </LiveButtonOffline>
      )}
    </div>
  );
};

export default Live;
