import styled from 'styled-components';

export const Button = styled.button.attrs({ className: 'btn btn-default' })`
  width: 30% !important;
  margin: 35px 0 0 115px !important;
`;

export const Card = styled.div`
  background: #fff;
  border-radius: 5px;
  margin-bottom: 20px;
`;

export const SelectBox = styled.div`
  width:50%;
  margin-top: 20px;
`;
