import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'reactstrap';
import Col from 'reactstrap/es/Col';
import {
  Description, Icon, Materials,
} from './styles';

const ComplementaryMaterial = ({ data }) => {
  const { t } = useTranslation('courses');

  const getIcon = (material) => {
    const contenttype = material.hasOwnProperty('file') && material.file.hasOwnProperty('contenttype') ? material.file.contenttype : null;
    if (contenttype !== null) {
      switch (contenttype) {
        case 'image/jpeg':
        case 'image/jpg':
        case 'image/png':
          return <i className="fal fa-file-image" />;
        case 'video/mp4':
        case 'video/avi':
          return <i className="fal fa-file-video" />;
        case 'application/pdf':
          return <i className="fal fa-file-pdf" />;
        default:
          break;
      }
    }
  };

  const getUrl = (material) => {
    const url = material.hasOwnProperty('file') && material.file.hasOwnProperty('urlPublica') ? material.file.urlPublica : null;
    if (url !== null) {
      return url;
    }
    return '#';
  };

  return (
    <Materials>
      <Row>
        <Col xs={12}>
          <h1>{t('Complementary Material')}</h1>
        </Col>
      </Row>
      <Row>
        {data.map((material) => (
          <Col xs={12} key={material.support_material.id}>
            {material.support_material.support_material_contents.map((m) => (
              <div className="mb-1" key={m.id}>
                <Icon>
                  {getIcon(m)}
                </Icon>
                <Description target="_blank" href={getUrl(m)}>{m.title}</Description>
              </div>
            ))}

          </Col>
        ))}
      </Row>
    </Materials>
  );
};

export default ComplementaryMaterial;
