import styled, { css } from 'styled-components';

const progress = css` 
  grid-area: "bar";
  margin-top: 5px;
.progress {
  display: flex;
  height: 10px;  
  background-color: #e5e5e5;
  border-radius: 5px;
}

.progress-bar {
  white-space: nowrap;
  border-radius: 10px;
  background-color: #004c94;
}
`;

export const Line = styled.div`
  border-bottom: 1px solid #d2d2d2;
  margin-bottom: 30px;
`;

export const CourseTitle = styled.div`
  border-bottom: 1px solid #d2d2d2;
  margin-bottom: 30px;
  font-weight: bold;
  padding-bottom: 20px;
`;

export const Box = styled.div`
  background: #fff;
  border-radius: 5px;
  padding: 20px;
  margin: 20px 0;
`;

export const ProgressBox = styled.div`  
  font-size: 15px;
`;

export const ProgressGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 5fr;
  grid-template-areas: "text bar";  
  white-space: nowrap;
`;

export const ProgressText = styled.div`  
  grid-area: "text"; 
`;

export const ProgressBar = styled.div`
  ${progress}
  .progress{
    width: 100%;
  }
`;
export const LessonGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 5fr;
  grid-template-areas: "count text bar";  
  white-space: nowrap;
  margin-bottom: 10px
`;

export const LessonText = styled.div`  
  grid-area: "text";
  text-align: center;
`;

export const LessonProgress = styled.div`
  ${progress}
  .progress{
    width: 63%;
  }
`;

export const LessonCount = styled.div`
  grid-area: "count";
  color: #004c94;
  font-weight: bold;
`;

export const CertificateLink = styled.a`
${(props) => props.allow === undefined && css`
    pointer-events:none !important;
  `}
 &,
  &:hover{
    color: #fff;
    text-decoration: none;
  }
`;

export const CertificateButton = styled.div`
${(props) => props.allow === undefined && css`
    cursor: not-allowed !important;
    opacity: 0.8;
  `}

  width: 100%;
  height: auto;
  background-color: #004c94;
  border-radius: 5px;
  text-align: center;
  padding: 25px 0;
  color: #fff;
  cursor: pointer;

  i{
    margin-right: 5px;
    font-size: 25px;
    vertical-align: bottom;
  }
`;
