/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  Col, Container, Row, Spinner,
} from 'reactstrap';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import BaseProfile from '../../components/Theme/profile';
import { colourStyles } from '../../utils/colourStyles.js';
import ProfileBar from '../../components/ProfileBar';
import CoverImage from '../../components/CoverImage';
import Posts from '../../components/Timeline/Posts';
import HandleRefreshContext from '../../components/Timeline/PostItem/handleRefreshContext';
import { getCurrentUser, handlePosts, fetchConnections } from '../../services/endpoints';
import PublicCurriculum from '../../components/PublicCurriculum';
import { loadCurriculumFields } from '../../utils/profileInfo';
import Aside from '../../components/Widgets/Aside';
import CardLeadership from '../../components/Widgets/LeadershipAndFollow/CardLeadership';
import CardFollow from '../../components/Widgets/LeadershipAndFollow/CardFollow';
import CoursesList from '../../components/Widgets/Courses/CoursesList';
import { Card, SelectBox } from './styles';

const ProfileShow = (props) => {
  const { slug } = props.match.params;
  const { t } = useTranslation(['profile']);
  const currentUser = getCurrentUser();
  const itemsPerPage = 5;
  const [loadingItem, setLoadingItem] = useState(false);
  const [numCurrentItens, setNumCurrentItens] = useState(itemsPerPage);
  const [page, setPage] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [allUsers, setAllUsers] = useState([]);
  const [allSegments, setAllSegments] = useState([]);
  const [curriculum, setCurriculum] = useState([]);
  const [selectedOption, setSelectedOption] = useState();
  const options = [
    {
      value: 'asc',
      label: t('order_asc'),
    },
    {
      value: 'desc',
      label: t('order_desc'),
    },
  ];

  const loadCurriculum = async () => {
    const curriculumFields = await loadCurriculumFields(`/users/${slug}`);
    setCurriculum(curriculumFields);
  };

  useEffect(() => {
    loadCurriculum();

    return () => {
      setCurriculum([]);
    };
  }, []);

  const loadConnections = () => {
    const connections = [];
    const segments = [];
    fetchConnections().then((response) => {
      response.data.filter((connection) => (connection.user2_iri !== null ? connections.push(connection.user2.id) : ''));
      response.data.filter((connection) => (connection.segment_iri !== null ? segments.push(
        connection.segment.id,
      ) : ''));
      setAllSegments(segments);
      setAllUsers(connections);
    }).catch((error) => {
      console.log(error);
    });
  };

  useEffect(() => {
    loadConnections();
  }, []);


  function handleRefreshPosts(filter = {}, pageNumber = currentPage, shouldRefresh = false) {
    setLoadingItem(true);
    handlePosts({
      user_iri: `/users/${slug}`,
      perPage: `${itemsPerPage}`,
      page: `${pageNumber}`,
      'order[created_at]': Object.values(filter).length > 0 ? filter.value : 'desc',
    }).then((response) => {
      const posts = response.data || [];
      setLoadingItem(false);
      setNumCurrentItens(posts.length);
      setPage((old) => (shouldRefresh ? posts : [...old, ...posts]));
      setCurrentPage(pageNumber + 1);
    }).catch((error) => {
      console.log(error);
    });
    return null;
  }

  useEffect(() => {
    setLoadingItem(true);
    handleRefreshPosts(0, true);
  }, []);

  window.onscroll = function () {
    if ((numCurrentItens === itemsPerPage) && (window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 10) && loadingItem === false) {
      handleRefreshPosts();
    }
  };
  function filterPosts(e) {
    setSelectedOption(e);
    handleRefreshPosts(e, 0, true);
  }

  return (
    <BaseProfile>
      <Container>
        <Card>
          <CoverImage slug={slug} />
          <ProfileBar
            slug={slug}
            isCurrentUser={slug === currentUser.id}
            allUsers={allUsers}
            setAllUsers={setAllUsers}
            allSegments={allSegments}
            setAllSegments={setAllSegments}

          />
        </Card>
        {curriculum.length > 0 && (
          <Card>
            <PublicCurriculum user={slug} curriculum={curriculum} />
          </Card>
        )}

        <div className="box-content">

          <Row className="align-items-start">

            <Col xs={12} sm={3}>
              <Aside />
              <CardLeadership />
              <CardFollow />
              <CoursesList />
            </Col>

            <Col xs={12} sm={9}>
              <SelectBox>

                <Select
                  onChange={filterPosts}
                  value={selectedOption}
                  options={options}
                  placeholder={t('view_posts')}
                  styles={colourStyles}
                />
              </SelectBox>

              <HandleRefreshContext.Provider value={{ handleRefreshPosts }}>
                <Posts
                  posts={page}
                  allUsers={allUsers}
                  setAllUsers={setAllUsers}
                />
              </HandleRefreshContext.Provider>

              {numCurrentItens === itemsPerPage && (
              <div className="text-center mb-5">
                {!loadingItem && (
                <div className="text-center">
                  <Spinner color="dark" />
                </div>
                )}
              </div>
              )}

            </Col>
          </Row>

        </div>
      </Container>
    </BaseProfile>
  );
};

export default ProfileShow;
