import styled from 'styled-components';


export const Section = styled.section`
  display: grid;
  grid-template-columns: auto 3fr 1fr;
  grid-template-areas: "image textarea buttons";   
  grid-column-gap: 15px;                    
  background: #fff;
  margin-bottom: 30px;
  border-radius: 5px;
  padding: 20px;
`;

export const Icon = styled.i`
  position: absolute;
  right: 1%;
  top: 5%;
  color: #004c94;
  cursor: pointer;
  font-size: 18px;
`;

export const Image = styled.img`
  grid-area: image;
  height: 55px;
  object-fit: cover;
  width: 55px;
  margin-top: 50%;
  border-radius: 50%;
`;

export const TextArea = styled.div`
  grid-area: textarea;
  position: relative;
  cursor: pointer;
  min-height: 110px;
  border: 0;
  background: #eeeeee;
  border-radius: 10px;
  width: 100%;
  outline: none;
  resize: none;
  position: relative;
  padding: 15px;  
  &:before {
    content: "";
    border-right: 10px solid #eeeeee;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -10px;
  }
`;

export const Placeholder = styled.div`
  color: #004c94;
  font-size: 25px;
  font-weight: 900;
  padding-left: 15px;
  line-height: 85px;
  overflow: hidden;
`;


export const Buttons = styled.div`  
  grid-area: buttons;
  margin-left: 15px;
  display: grid;
  grid-template-rows: 1fr 1fr 
  grid-template-areas: "col1"
                       "col2";
  grid-gap: 20px;
`;


export const ButtonMedia = styled.label.attrs({ className: 'btn btn-secondary' })`
  grid-area: col1;
  color: $9e9e9e !important;
  text-align: left !important;
  line-height: 40px !important;
  padding: 0 10px ;
  &:hover{   
    i{
      color: #004c94 !important;
    }
    border-color: #363636 !important;
    color: #9e9e9e !important;
    background-color: #fff !important;
  }

  input{
    display: none;
  }
  i{
    margin-right: 15px !important;
  }
  
`;

export const TagPeople = styled(ButtonMedia)`
  position: relative;
  grid-area: col2;
`;
