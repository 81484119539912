import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CoursesList from '../CoursesList';
import { loadCourses } from '../../../services/endpoints';
import { ButtonCourse } from './styles';

const CoursesFeatures = () => {
  const { t } = useTranslation('courses');
  const [courses, setCourses] = useState([]);


  const getCourses = () => {
    loadCourses({
      perPage: 6,
      page: 0,
      highlight: true,
      active: true,
      'order[id]': 'desc',
    }).then((response) => {
      setCourses(response.data);
    }).catch((e) => {
      console.log(e);
    });
  };

  useEffect(() => {
    getCourses();
  }, []);

  return (
    <>
      <h1>{t('Featured Courses')}</h1>
      <CoursesList courses={courses} />
      <ButtonCourse to="/cursos">{t('See all courses')}</ButtonCourse>
    </>
  );
};


export default CoursesFeatures;
