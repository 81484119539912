export const colourStyles = {
  option: (styles, {
    data, isDisabled, isFocused, isSelected,
  }) => ({
    ...styles,
    backgroundColor: isDisabled
      ? '#3f51b5b3'
      : isSelected
        ? '#3f51b5'
        : isFocused
          ? '#3f51b5b3'
          : null,
    color: isDisabled
      ? '#fff'
      : isSelected
        ? '#fff'
        : isFocused
          ? '#fff'
          : null,

    ':active': {
      ...styles[':active'],
      backgroundColor: !isDisabled && (isSelected ? data.color : '#3f51b5'),
    },
  }),
};
