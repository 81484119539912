/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */

import React, { useState, useEffect } from 'react';
import { Spinner } from 'reactstrap';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Base from '../../components/Theme/base';
import { Term } from './styles';
import { loadCourses, loadUsers, loadLeadership } from '../../services/endpoints';
import TabPanel from '../../utils/TabPanel';
import CoursesList from '../../components/Courses/CoursesList';
import UsersList from '../../components/Users/UsersList';

const Search = () => {
  const params = new URLSearchParams(window.location.search);
  const term = params.get('term');
  const { promiseInProgress } = usePromiseTracker();
  const tab = parseInt(params.get('tab'), 10) || 0;
  const { t } = useTranslation('common');
  const [leadership, setLeadership] = useState([]);
  const [affiliates, setAffiliates] = useState([]);
  const [courses, setCourses] = useState([]);
  const [loadingItem, setLoadingItem] = useState(false);
  const itemsPerPage = 18;
  const [numCurrentItens, setNumCurrentItens] = useState(itemsPerPage);
  const [currentPage, setCurrentPage] = useState(0);
  const history = useHistory();
  const handleChange = (event, newValue) => {
    history.push({
      pathname: history.location.pathname,
      search: `?term=${term}&tab=${newValue}`,
    });
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      borderBottom: '1px solid lightgrey',
      marginTop: '30px',
    },
  }));

  const classes = useStyles();

  function handleCourses(pageNumber = currentPage, shouldRefresh = false) {
    setLoadingItem(true);
    trackPromise(
      loadCourses({
        'match[title]': term,
        page: pageNumber,
        perPage: itemsPerPage,
      })
        .then((response) => {
          setCourses((old) => (shouldRefresh ? response.data : [...old, ...response.data]));
          setNumCurrentItens(response.data.length);
          setCurrentPage(pageNumber + 1);
          setLoadingItem(false);
        })
        .catch((error) => {
          console.error(error);
        }),
    );
  }

  const handleLeadership = async (pageNumber = currentPage, shouldRefresh = false) => {
    setLoadingItem(true);
    let result = '';
    const leaders = [];

    await loadUsers({
      name: term || '',
    })
      .then((response) => {
        const users = [];
        Object.values(response.data).map((item) => users.push(item.id));
        Object.values(users).map((item, index) => {
          if (index === users.length - 1) {
            result += `'/users/${item}'`;
          } else {
            result += `'/users/${item}'|`;
          }
        });
      })
      .catch((error) => {
        console.error(error);
      });

    if (result === '') {
      setLeadership([]);
      setLoadingItem(false);
      setNumCurrentItens(0);
    } else {
      await loadLeadership({
        page: pageNumber,
        perPage: itemsPerPage,
        'wherein[user_iri]': result,
      })
        .then((response) => {
          Object.values(response.data).map((item) => leaders.push(item.user));
        })
        .catch((error) => {
          console.error(error);
        });
      setLeadership((old) => (shouldRefresh ? leaders : [...old, ...leaders]));
      setNumCurrentItens(leaders.length);
      setCurrentPage(pageNumber + 1);
      setLoadingItem(false);
    }
  };

  function handleAffiliates(pageNumber = currentPage, shouldRefresh = false) {
    setLoadingItem(true);
    trackPromise(
      loadUsers({
        name: term,
        page: pageNumber,
        itemsPerPage,
      })
        .then((response) => {
          setAffiliates((old) => (shouldRefresh ? response.data : [...old, ...response.data]));
          setNumCurrentItens(response.data.length);
          setCurrentPage(pageNumber + 1);
          setLoadingItem(false);
        })
        .catch((error) => {
          console.error(error);
        }),
    );
  }

  window.onscroll = function () {
    if ((numCurrentItens === itemsPerPage) && (window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 10)
    && loadingItem === false) {
      switch (tab) {
        case 1:
          handleAffiliates();
          break;
        case 2:
          handleCourses();
          break;
        default:
          handleLeadership();
      }
    }
  };

  useEffect(() => {
    switch (tab) {
      case 1:
        handleAffiliates(1, true);
        break;
      case 2:
        handleCourses(0, true);
        break;
      default:
        trackPromise(
          handleLeadership(0, true),
        );
    }
    return () => {
      setAffiliates([]);
      setCourses([]);
      setLeadership([]);
      setCurrentPage(0);
      setNumCurrentItens(itemsPerPage);
      setLoadingItem(false);
    };
  }, [tab]);

  return (
    <Base
      blocks={[
        { name: 'myprofile' },
        { name: 'image' },
        { name: 'leadership' },
        { name: 'follow' },
      ]}
    >
      <div>{t('search_result')}</div>
      <Term>{term}</Term>
      <div className={classes.root}>
        <Tabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
        >
          <Tab label={t('tabs.leadership')} />
          <Tab label={t('tabs.affiliates')} />
          <Tab label={t('tabs.courses')} />
        </Tabs>
      </div>
      <TabPanel value={tab} index={0}>
        {promiseInProgress === true ? (
          <div className="text-center">
            <Spinner color="dark" />
          </div>
        ) : leadership.length > 0 ? (
          <UsersList users={leadership} />
        ) : (
          <>{t('no_records')}</>
        )}
      </TabPanel>
      <TabPanel value={tab} index={1}>
        {promiseInProgress === true ? (
          <div className="text-center">
            <Spinner color="dark" />
          </div>
        ) : affiliates.length > 0 ? (
          <UsersList users={affiliates} />
        ) : (
          <>{t('no_records')}</>
        )}
      </TabPanel>

      <TabPanel value={tab} index={2}>
        {promiseInProgress === true ? (
          <div className="text-center">
            <Spinner color="dark" />
          </div>
        ) : courses.length > 0 ? (
          <CoursesList courses={courses} />
        ) : (
          <>{t('no_records')}</>
        )}

      </TabPanel>
      {numCurrentItens === itemsPerPage && (
        <div className="text-center mb-5">
          {!loadingItem && (
          <div className="text-center">
            <Spinner color="dark" />
          </div>
          )}
        </div>
      )}
    </Base>
  );
};
export default Search;
