/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import React, { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { store } from 'react-notifications-component';
import { getCurrentUser, loadCover } from '../../services/endpoints';
import {
  ButtonNewCover, Cover, CoverContainer, CoverFrame, NoCover,
} from './styles';
import api from '../../services/api';
import settings from '../../services/settings';
import { loadProfileFields } from '../../utils/profileInfo';

const CoverImage = ({ slug, isCurrentUser }) => {
  const { t } = useTranslation('common');
  const currentUser = getCurrentUser();
  const [coverImage, setCoverImage] = useState([]);
  const [updateCover, setUpdateCover] = useState(null);
  const preview = useMemo(
    () => (updateCover ? URL.createObjectURL(updateCover) : null),
    [updateCover],
  );


  useEffect(() => {
    loadCover(isCurrentUser === true ? currentUser.id : slug)
      .then((response) => {
        setCoverImage(response.data);
      })
      .catch((error) => {
        store.addNotification({
          ...settings.notifications,
          message: t('notifications.danger.insert_image', error),
          type: 'danger',
        });
      });
  }, []);

  const createCover = (event) => {
    const coverImage = event.target.files[0];
    setUpdateCover(coverImage);
    handleCover(coverImage);
  };

  const handleCover = async (coverImage) => {
    const profileFields = await loadProfileFields(`/users/${currentUser.id}`, false);
    if (Object.values(profileFields).length) {
      if (profileFields.cover.id) {
        handleSubmit(profileFields.cover.id, coverImage);
      } else {
        const { fieldId } = profileFields.cover;
        createFieldValuesBatch(fieldId, coverImage);
      }
    }
  };

  const createFieldValuesBatch = (fieldId, coverImage) => {
    api
      .post('/general/fieldValuesBatch', [
        {
          fieldId,
          value: '',
        },
      ])
      .then((response) => {
        loadCover(currentUser.id)
          .then((response) => {
            handleSubmit(
              response.data[0] ? response.data[0].id : undefined,
              coverImage,
            );
          })
          .catch((error) => {
            store.addNotification({
              ...settings.notifications,
              message: t('notifications.danger.insert_image', error),
              type: 'danger',
            });
          });
      })
      .catch((error) => {
        store.addNotification({
          ...settings.notifications,
          message: t('notifications.danger.insert_image', error),
          type: 'danger',
        });
      });
  };

  const handleSubmit = (id, coverImage) => {
    const data = new FormData();
    data.append('file', coverImage);
    api
      .post(`/general/field_values/${id}/upload/file`, data)
      .then((response) => {
      })
      .catch((error) => {
        store.addNotification({
          ...settings.notifications,
          message: t('notifications.danger.insert_image', error),
          type: 'danger',
        });
      });
  };
  return (
    <CoverContainer>
        <CoverFrame>
        {coverImage[0] ? <Cover source={preview || coverImage[0].file.urlPublica} />
          : preview && !coverImage[0] ? <Cover source={preview} /> : <NoCover />}

      </CoverFrame>

        {isCurrentUser === true && (
        <ButtonNewCover>
          <form onSubmit={handleSubmit}>
            <input type="file" onChange={(event) => createCover(event)} />
            <i className="fas fa-camera" />
            {t('buttons.edit')}
          </form>
        </ButtonNewCover>
      )}
      </CoverContainer>
  );
};

CoverImage.propTypes = {
  slug: PropTypes.string,
  isCurrentUser: PropTypes.bool,
};

export default CoverImage;
