export default {
  highlighter: {
    color: '#000',
  },
  suggestions: {
    zIndex: 99,
    list: {
      maxHeight: 150,
      overflowY: 'auto',
      position: 'absolute',
      backgroundColor: '#004c94',
      color: '#fff',
      fontSize: 14,
      maxWidth: 180,
      width: '100%',
    },
    item: {
      padding: 5,
      '&focused': {
        backgroundColor: '#ffef00',
        color: '#313131',
      },
    },
  },
};
