/* eslint-disable linebreak-style */
/* eslint-disable react/prop-types */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-return-assign */
import React, { useState, useEffect } from 'react';
import { Col } from 'reactstrap';
import PropType from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Image,
  Name,
  Role,
  ProfileBtn,
  CustomRow,
  CardHeader,
  LinkCustom,
  Grid,
} from './styles';
import NoAvatar from '../../../../assets/images/no-avatar.png';
import { fetchConnections } from '../../../../services/endpoints';
import Follow from '../../../Follow';

const CardItem = ({ user }) => {
  const { t } = useTranslation('common');
  const [allUsers, setAllUsers] = useState([]);
  const [allSegments, setAllSegments] = useState([]);
  const username = user ? user.name.split(' ') : '';

  const loadConnections = () => {
    const connections = [];
    const segments = [];
    fetchConnections()
      .then((response) => {
        if (response.data.length > 0) {
          response.data.filter((connection) => (connection.user2_iri !== null ? connections.push(connection.user2.id) : ''));
          response.data.filter((connection) => (connection.segment_iri !== null ? segments.push(
            connection.segment.id,
          ) : ''));
          setAllSegments(segments);
          setAllUsers(connections);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    loadConnections();
  }, []);

  return (
    <>
      {user && (
        <CardHeader>
          <Grid>
            <Image
              src={
                  user.profile.hasOwnProperty('avatar')
                    ? user.profile.avatar.file.urlPublica
                    : { NoAvatar }
                }
            />
            <Name>{`${username[0]} ${username[1] || ''}`}</Name>

          </Grid>
          <Role>
            {user.profile.hasOwnProperty('cargo') ? user.profile.cargo.value : ''}
          </Role>
          <CustomRow>
            <Col xs={12} md={6}>
              <LinkCustom to={`/profile/${user.id}`}>
                <ProfileBtn>{t('buttons.profile')}</ProfileBtn>
              </LinkCustom>
            </Col>
            <Col xs={12} md={6}>
              <Follow
                allUsers={allUsers}
                setAllUsers={setAllUsers}
                allSegments={allSegments}
                setAllSegments={setAllSegments}
                user={user}
                idUsers={user.id}
              />
            </Col>
          </CustomRow>
        </CardHeader>
      )}

    </>
  );
};

CardItem.propType = {
  user: PropType.object.isRequired,
};

export default CardItem;
