import React, { useState, useEffect } from 'react';
import { Aside as AsideStyled, Image, Link } from './styles';
import { fetchBanners } from '../../../services/endpoints';

const Aside = () => {
  const [banners, setBanners] = useState([]);
  const bannerLateral = banners.filter((item) => item.title === 'banner_lateral')[0] || [];
  useEffect(() => {
    fetchBanners().then((response) => {
      setBanners(response.data);
    });
  }, []);

  return (
    <AsideStyled>

      {Object.values(bannerLateral).length > 0 && (
        <>
          <Link href={bannerLateral.url} target="_blank">
            <Image src={bannerLateral.file.urlPublica} />
          </Link>
        </>
      )}

    </AsideStyled>
  );
};


export default Aside;
