import styled, { css, keyframes } from 'styled-components';

export const Box = styled.section`
  position: relative;
`;

export const Title = styled.div`
  color: #eeeeee;
  font-size: 15px;
  line-height: 35px;
  position: relative;
  display: block;
  cursor: pointer;
} 
`;

export const Text = styled.div`
  font-size: 14px;
  display: inline-block;  
  color: #004d95
  padding: 0 10px;
`;

export const IconAlert = styled.div`
  background: #083763;
  height: 18px;
  position: absolute;
  top: 15%;
  right: 35%;
  font-size: 12px;
  padding: 0 5px;
  line-height: 18px;
  width: fit-content;
  border-radius: 2px;
`;

export const Header = styled.header` 
  width: 100%;
  z-index: 2;
  ${(props) => props.active && css`
    background-color: #e5e5e5;
    color: #004d95;  
    ${Title}{
      color: #004d95;  
      font-weight: bold;
    }  
    ${IconAlert}{
      color: #fff;
    }
  `}
`;

const shake = keyframes`
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-25deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(25deg);
  }
`;

export const Icon = styled.i`
  vertical-align: bottom;
  font-size: 18px;
  display: inline-block;
  cursor: pointer;
  position: relative;
  // &.active{
  //   animation: ${shake} 0.3s linear 4;   
  // }
`;

export const Section = styled.section`
  position: absolute;
  width: 250%;
  right: 0;
  top: auto;
  z-index: 9;
  background-color: #e5e5e5;
  padding: 20px 0;
  text-align: left;
`;

export const Footer = styled.div`
  padding: 10px;
`;

export const Logout = styled.div`
  line-height: 38px;
  width: 100%;
  background-color: #004c94;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  transition: all 300ms linear;
  display: block;
  text-align: center;
  cursor: pointer;
  &:hover {
    background-color: #fd0100;
    text-decoration: none;
    color: #ffffff;  
  }

  i{
    margin-right: 5px;  
  }
`;

export const IconProfile = styled.i`
  position: relative;
  overflow: hidden;
  vertical-align: bottom;
  margin-left: 10px;
  cursor: pointer;
`;

export const Avatar = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
`;
