import React from 'react';
import Base from '../../components/Theme/base';
import CoursesFeatures from '../../components/Courses/CoursesFeatures';
import DirectoriesList from './components/DirectoriesList';

const Directories = () => (
  <Base
    blocks={[
      { name: 'myprofile' },
      { name: 'image' },
      { name: 'leadership' },
      { name: 'follow' },
      { name: 'courses' },
    ]}
  >
    <DirectoriesList />
    <CoursesFeatures />
  </Base>
);

export default Directories;
