/* eslint-disable linebreak-style */
/* eslint-disable react/prop-types */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-return-assign */
import React, { useState, useEffect } from 'react';
import { Col } from 'reactstrap';
import PropType from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Image, Name, Role, Line, ProfileBtn, CustomRow, CardHeader, CardFooter, LinkCustom,
} from './styles';
import NoAvatar from '../../../assets/images/no-avatar.png';
import { fetchConnections, getCurrentUser } from '../../../services/endpoints';
import Follow from '../../Follow';


const UsersItem = ({ user }) => {
  const currentUser = getCurrentUser();
  const { t } = useTranslation('common');
  const [allUsers, setAllUsers] = useState([]);
  const [allSegments, setAllSegments] = useState([]);

  const loadConnections = () => {
    const connections = [];
    const segments = [];
    fetchConnections().then((response) => {
      response.data.filter((connection) => (connection.user2_iri !== null ? connections.push(connection.user2.id) : ''));
      response.data.filter((connection) => (connection.segment_iri !== null ? segments.push(
        connection.segment.id,
      ) : ''));
      setAllSegments(segments);
      setAllUsers(connections);
    }).catch((error) => {
      console.log(error);
    });
  };

  useEffect(() => {
    loadConnections();
  }, []);

  return (
    <>
      <CardHeader>
        <Image src={user.profile.hasOwnProperty('avatar') ? user.profile.avatar.file.urlPublica : { NoAvatar }} />
        <Name>{user.name}</Name>
        <Role>{user.profile.hasOwnProperty('cargo') ? user.profile.cargo.value : ''}</Role>
      </CardHeader>
      <Line />
      <CardFooter>
        <CustomRow>
          <Col xs={12} md={6}>
            <LinkCustom to={`/profile/${user.id}`}>
              <ProfileBtn>{t('buttons.profile')}</ProfileBtn>
            </LinkCustom>
          </Col>
          <Col xs={12} md={6}>
            {user.id !== currentUser.id && (
            <Follow
              allUsers={allUsers}
              setAllUsers={setAllUsers}
              idUsers={user.id}
              allSegments={allSegments}
              setAllSegments={setAllSegments}
              user={user}
            />
            )}

          </Col>
        </CustomRow>
      </CardFooter>
    </>

  );
};

UsersItem.propType = {
  user: PropType.object.isRequired,
};

export default UsersItem;
