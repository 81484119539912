import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Field, Formik } from 'formik';
import { Col, Label, Row } from 'reactstrap';
import Base from '../../components/Theme/base';
import {
  Button, ContactStyled, Description, FormGroup, Card,
} from './styles';
import { sendContactMessages } from '../../services/endpoints';

const Contact = () => {
  const { t } = useTranslation(['common', 'contact']);
  const data = JSON.parse(
    localStorage.getItem(`${process.env.REACT_APP_PREFIX}.user`),
  );
  const initialValues = {
    subject: '',
    message: '',
  };

  const validationSchema = Yup.object().shape({
    subject: Yup.string().required(t('common:form.validations.required')),
    message: Yup.string().required(t('common:form.validations.required')),
  });

  return (
    <Base
      blocks={[
        { name: 'myprofile' },
        { name: 'image' },
      ]}
    >

      <h1>{t('contact:title')}</h1>
      <Card>
        <Description
        dangerouslySetInnerHTML={{ __html: t('contact:description') }}
      />

        <ContactStyled>
        {Object.keys(data).length > 0 && (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              if (values) {
                sendContactMessages(values)
                  .then(() => {
                    resetForm(initialValues);
                  })
                  .catch(() => {
                    alert('Erro ao enviar o formulario');
                  });
              }
              setSubmitting(false);
            }}
          >
            {({
              errors, touched, isSubmitting, handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <Row>
                  <Col xs={12} sm={6} md={8}>
                    <FormGroup>
                      <Label htmlFor="name">
                        {t('common:form.labels.name')}
                      </Label>
                      <p className="form-input">{data.name || ''}</p>
                    </FormGroup>
                  </Col>
                  <Col xs={12} sm={6} md={4}>
                    <FormGroup>
                      <Label htmlFor="email">
                        {t('common:form.labels.email')}
                      </Label>
                      <p className="form-input">{data.email || ''}</p>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} sm={6} md={4}>
                    <FormGroup>
                      <Label htmlFor="phone">
                        {t('common:form.labels.phone')}
                      </Label>
                      <p className="form-input">
                        {data.profile.telefone
                          ? data.profile.telefone.value
                          : ''}
                      </p>
                    </FormGroup>
                  </Col>
                  <Col xs={12} sm={6} md={8}>
                    <FormGroup>
                      <Label htmlFor="subject">
                        {t('common:form.labels.subject')}
                      </Label>
                      <Field
                        className="form-input"
                        name="subject"
                        placeholder={t('common:form.placeholders.your_subject')}
                      />
                      {errors.subject && touched.subject ? (
                        <div className="error">{errors.subject}</div>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <FormGroup>
                      <Label htmlFor="message">
                        {t('common:form.labels.message')}
                      </Label>
                      <Field
                        name="message"
                        id="message"
                        className="form-textarea"
                        placeholder={t(
                          'common:form.placeholders.write_yout_message',
                        )}
                        component="textarea"
                        rows="6"
                      />
                      {errors.message && touched.message ? (
                        <div className="error">{errors.message}</div>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>

                <div className="text-right">
                  <Button type="submit" disabled={isSubmitting}>
                    {!isSubmitting
                      ? t('common:form.labels.send')
                      : t('common:form.labels.sending')}
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        )}
      </ContactStyled>
      </Card>
    </Base>
  );
};

export default Contact;
