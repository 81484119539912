import Cookie from "js-cookie";
import api from "./api";
import { createCookie } from "./auth";

export const redirectToLogout = () => {
  window.location = `https://${process.env.REACT_APP_SIGNIN_URL}?logout=${btoa(
    document.location.origin
  )}`;
};

export const redirectToRefresh = () => {
  window.location = `https://${process.env.REACT_APP_SIGNIN_URL}?refresh=${btoa(
    document.location.origin
  )}`;
};

export const getRefreshToken = async (sessionId) => {
  const response = await api.post("/users/refresh_token", { sessionId });
  createCookie(response);
  window.location.reload();
  return response;
};
