import styled from 'styled-components';
import { MentionsInput } from 'react-mentions';
import { FormGroup, Input } from 'reactstrap';
import { Link } from 'react-router-dom';
import { ProgressBarStyle } from '../../../pages/CoursesShow/Playlist/Progress/styles';


export const Section = styled.section`  
  display: grid;
  grid-template-columns: 1fr 11fr;
  grid-template-areas: "image textarea"
                       "empty preview"
                       "empty buttons";
  background: #fff;
  border-radius: 5px;
  padding: 25px;
`;

export const Image = styled.img`
  grid-area: image;
  height: 55px;
  object-fit: cover;
  width: 55px;
  margin-top: 50%;
  border-radius: 50%;
`;

export const Preview = styled.div`
  margin-top: 30px;
  grid-area: preview;
  margin-left: 15px;
  display: grid;
  position: relative;
  grid-template-columns: 1fr 5fr;
  grid-template-areas: "col1 col2";
  grid-gap: 20px;
`;

export const PreviewImage = styled.img`
  grid-area: col1;
  height: 75px;
  width: 100%;
  object-fit: scale-down;
`;

export const PreviewDescription = styled.p`
  position: absolute;
  top: 47px;
  font-size: 12px;
  grid-area: col2;
  color: white;
  border-color: #ffef00;
  background-color: #004c94;
  border-radius: 4px;
  padding: 5px;
`;

export const PreviewImageRemoveIcon = styled.i`
  font-size: 25px;
  position: absolute;    
  top: -13px;
  color: red;
  cursor: pointer;
`;

export const PreviewProgressBar = styled(ProgressBarStyle)`
  grid-area: col2;
`;

export const TextAreaBody = styled.div`
  grid-area: textarea;
  position: relative;
  margin-left: 15px;
  &:before {
    content: "";
    border-right: 10px solid #eeeeee;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -10px;
  }
`;

const placeholder = `
  color: #004c94;
  font-size: 25px;
  font-weight: 900;
  text-align: center;
  line-height: 70px;
  overflow: hidden;   
  
`;

export const MentionsInputStyled = styled(MentionsInput)`
  min-height: 110px;
  border: 0;
  background: #eeeeee;
  border-radius: 10px;
  width: 100%;
  outline: none;
  resize: none;
  position: relative;
  padding: 15px;  
  textarea {
    word-break: break-all;
    border: 0;
    padding: 15px;
    outline: none;
    &::-webkit-input-placeholder {
      ${placeholder}
    }
    &:-moz-placeholder {
      ${placeholder}
      opacity: 1;
    }
    &::-moz-placeholder {
      ${placeholder}
      opacity: 1;
    }
    &:-ms-input-placeholder {
      ${placeholder}
    }
    &::-ms-input-placeholder {
      ${placeholder}
    }
    &::placeholder {
      ${placeholder}
    }
  }
 
`;

export const Buttons = styled.div`
  margin-top: 30px;
  grid-area: buttons;
  margin-left: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-areas: "col1 col2 col3 col4";
  grid-gap: 20px;
`;

export const ButtonVideo = styled.label.attrs({ className: 'btn btn-secondary' })`
  grid-area: col2;

  &:hover{
    cursor: pointer;
  }

  input{
    display: none;
  }
`;

export const ButtonPhoto = styled.label.attrs({ className: 'btn btn-secondary' })`
  grid-area: col1;

  &:hover{
    cursor: pointer;
  }

  input{
    display: none;
  }
`;

export const ButtonMedia = styled.label.attrs({ className: 'btn btn-secondary' })`
  grid-area: col1;

  &:hover{
    cursor: pointer;
  }

  input{
    display: none;
  }
`;

export const TagPeople = styled(ButtonVideo)`
  position: relative;
  grid-area: col3;
`;

export const TaggedPerson = styled(FormGroup)`
  margin: 5px 0;
`;

export const InputPerson = styled(Input)`
  margin-right: 5px;
`;

export const ButtonTagUsers = styled.div`
  margin: 30px 0 5px 0;
  width: 100px;
  color: #000;
  background-color: #7d7d7d;
  
  &:hover{
    cursor: pointer;
  }
`;

export const ButtonBack = styled.div`
  color: #000;
  background-color: #7d7d7d;
  &:hover{
    cursor: pointer;
  }
`;

export const ButtonPrimary = styled.button.attrs({
  className: 'btn btn-primary',
  type: 'submit',
})`
  grid-area: col4;
`;

export const AvatarLink = styled(Link)`
  
`;
