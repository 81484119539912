import styled from 'styled-components';

export const Aside = styled.aside`
  text-align: center;
  position: relative;
`;

export const Image = styled.img`
  max-width: 100%;
  margin: 30px 0;
`;

export const Link = styled.a`


`;
