/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row, Spinner } from 'reactstrap';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import Breadcrumb from '../../components/Breadcrumb';
import Base from '../../components/Theme/base';
import CoursesFilterList from '../../components/Courses/CoursesFilterList';
import CoursesFilter from '../../components/Courses/CoursesFilter';
import api from '../../services/api';
import { loadCourseStats } from '../../services/endpoints';
import { StatsLength } from './styles';

const CoursesFilterPage = (props) => {
  const { t } = useTranslation('courses');
  const { slug } = props.match.params;
  const [term, setTerm] = useState(null);
  const [breadcrumb, setBreadcrumb] = useState(null);
  const [loadingItem, setLoadingItem] = useState(false);
  const itemsPerPage = 12;
  const [numCurrentItens, setNumCurrentItens] = useState(itemsPerPage);
  const [page, setPage] = useState([]);
  const [statsLength, setStatsLength] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const { promiseInProgress } = usePromiseTracker();

  const loadCourses = (pageNumber) => api.get('/lms/course_stats', {
    params: {
      page: pageNumber,
      perPage: itemsPerPage,
      active: true,
      'order[id]': 'desc',
      'match[course_status]': slug === 'em_andamento' ? slug : 'concluido',
    },
  });

  const getCourses = (pageNumber = currentPage, shouldRefresh = false) => {
    setLoadingItem(true);
    trackPromise(
      loadCourseStats({
        active: true,
        'match[course_status]': slug === 'em_andamento' ? slug : 'concluido',
      })
        .then((response) => {
          setStatsLength(response.data.length);
        })
        .catch((error) => {
          console.error(error);
        }),
    );

    trackPromise(
      loadCourses(pageNumber)
        .then((response) => {
          setNumCurrentItens(response.data.length);
          setPage((old) => (shouldRefresh ? response.data : [...old, ...response.data]));
          setCurrentPage(pageNumber + 1);
          setLoadingItem(false);
        })
        .catch((e) => {
          console.log(e);
        }),
    );
  };

  useEffect(() => {
    switch (slug) {
      case 'em_andamento':
        setTerm(t('In progress'));
        setBreadcrumb('em andamento');
        break;
      case 'concluidos':
        setTerm(t('Completed'));
        setBreadcrumb('concluídos');
        break;
      default:
        setTerm(t('Watched'));
        setBreadcrumb('assistidos');
    }
    setLoadingItem(true);
    getCourses(0, true);
    return () => {
      setPage([]);
    };
  }, []);

  window.onscroll = function () {
    if (
      numCurrentItens === itemsPerPage
      && window.innerHeight + window.scrollY >= document.body.offsetHeight - 10
      && loadingItem === false
    ) {
      getCourses();
    }
  };

  return (
    <Base
      blocks={[
        { name: 'myprofile' },
        { name: 'image' },
        { name: 'leadership' },
        { name: 'follow' },
      ]}
    >
      <Row>
        <Col xs={12} md={6}>
          <h1>{t('Courses')}</h1>
          <Breadcrumb
            root={[
              { title: 'Cursos', url: '/cursos' },
              { title: breadcrumb ? `Cursos ${breadcrumb}` : '' },
            ]}
          />
        </Col>

        <Col xs={12} md={6}>
          <CoursesFilter term={term} />
        </Col>
      </Row>

      {term !== null && (
      <StatsLength>
        {term}
:
        <span>
          {statsLength < 10 && statsLength !== 0 ? 0 : ''}
          {statsLength}
        </span>
      </StatsLength>
      )}

      {promiseInProgress === true ? (
        <div className="text-center">
          <Spinner color="dark" />
        </div>
      ) : page.length > 0 ? (
        <CoursesFilterList courses={page} breadcrumb={breadcrumb} />
      ) : (
        <>{t('common:no_records')}</>
      )}

      {numCurrentItens === itemsPerPage && (
        <div className="text-center mb-5">
          {!loadingItem && (
            <div className="text-center">
              <Spinner color="dark" />
            </div>
          )}
        </div>
      )}
    </Base>
  );
};

export default CoursesFilterPage;
